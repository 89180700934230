import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import InstallPWA from "./InstallPWA ";
import InstalliOS from "./InstalliOS";
import { QWRTX_MMA_GOAT_EVENTS, QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import events_services from "../../services/events_services";
import token_services from "../../services/token_services";
import MobileHeader from "../../components/Menu/mobile";

// Media
import BANNER from "../../assets/img/banner.png"
import BANNERMOBILE from "../../assets/img/banner_mobile.jpg"
import FIGHTERM01JPG from "../../assets/img/fighter_m01.jpg"
import FIGHTERM01PNG from "../../assets/img/fighter_m01.png"
import FIGHTERM02JPG from "../../assets/img/fighter_m02.jpg"
import FIGHTERM03JPG from "../../assets/img/fighter_m03.jpg"
import FIGHTERM04JPG from "../../assets/img/fighter_m04.jpg"
import BETHEGOATWHITE from "../../assets/img/be_the_goat_white.png"
import WHITEGOAT from "../../assets/img/white_goat.png"
import BLACKGOAT from "../../assets/img/black_goat.png"
import { isMobile } from "react-device-detect";
import fighter_services from "../../services/fighter_services";
import { countries } from "../../components/countries";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            fights: [],
            event: null,
            fighters: [],
        }
    }

    componentDidMount = async () => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("ref_k"))
            localStorage.setItem('ref_k', query.get("ref_k"))
        const profile = await token_services.getUser()
        if (profile) window.location.href = '/fighters'
        const events = await events_services.getOpenEvents()
        this.setState({ events })
        const data = await events_services.getEvent(events[0].id)
        this.setState({ event: data.event, fights: data.fights })
        const fighters = await fighter_services.getTopFighters('male')
        this.setState({ fighters })
    }

    render() {
        return (
            <React.Fragment>
                <Menu />
                <MobileHeader />
                {!isMobile &&
                    <div className="banner_goat" style={isMobile ? { backgroundImage: `url('${BANNERMOBILE}')`, marginTop: "-80px" } : { backgroundImage: `url('${BANNER}')` }}>
                        <div className="blurb">
                            <img src={BETHEGOATWHITE} className="d-block mt-100 mb-10" style={{ width: "70%" }} alt="Be The GOAT" />
                            <div className="border_banner"></div>
                            <div className="simple_text">
                                <strong style={{ display: "block", marginBottom: "30px" }}>
                                    {this.props.t('simple_game')}
                                </strong>
                                <div className="launch launch_white mb-20 mr-20 d-inline-block" onClick={() => window.location.href = "/start/1"}>
                                    <img className="sha" src={BLACKGOAT} alt="Black GOAT" /> {this.props.t('start_now')}
                                </div>
                                <span style={{ display: "block" }}>
                                    {this.props.t('how_to_start')}
                                </span>
                                <InstallPWA />
                                <InstalliOS />
                            </div>
                        </div>
                    </div>
                }
                {isMobile &&
                    <React.Fragment>
                        <div className="banner_goat" style={{ backgroundImage: `url('${BANNERMOBILE}')`, marginTop: "-80px" }}>
                            <img src={BETHEGOATWHITE} className="thegoat" alt="Banner" />
                        </div>
                        <div className="main_desc">
                            <div className="white text-left mt-30 black uppercase reducer">
                                {this.props.t('simple_game')}
                            </div>
                            {/* <div className="launch launch_white d-block mt-20" onClick={() => window.location.href = "/start/1"}>
                                    <img className="sha" src={BLACKGOAT} alt="Black GOAT" /> {this.props.t('create_your_fighters')}
                                </div> */}
                            {/* <InstallPWA />
                                <InstalliOS /> */}
                        </div>
                    </React.Fragment>
                }
                {!isMobile && <div>
                    <h1 className="huge">See you at the top</h1>
                    <h4>{this.props.t('level_up')}</h4>
                    <div className="ranking_container text-center">
                        {this.state.fighters.slice(1, 2).map((fighter, index) => fighter.user &&
                            <div className="second" key={`r_${index}`}>
                                <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                </div>
                                <div className="profile_board">
                                    <div className="nb_general nb_two centered_rank">#2</div>
                                    <h3 className="text-center mb-0">{fighter.nickname.toUpperCase()}
                                        <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div></h3>
                                    <p className="min-ft-14 mt-0 white text-center">
                                        <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`}>{fighter.user.username}</a>
                                    </p>
                                    <div className="back_separator"></div>
                                    <h3 className="text-center">
                                        <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                        <br />
                                        <span className="min-ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                        {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                    </h3>
                                    <div className="lvl_holder">
                                        <div className="lvl nb_two" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                    </div>
                                    <span className="next_lvl white">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                </div>
                            </div>
                        )}
                        {this.state.fighters.slice(0, 1).map((fighter, index) => fighter.user &&
                            <div className="first" key={`r_${index}`}>
                                <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                </div>
                                <div className="profile_board">
                                    <div className="nb_general nb_one centered_rank"><img src={BLACKGOAT} alt="GOAT" /></div>
                                    <h3 className="text-center mb-0">{fighter.nickname.toUpperCase()}
                                        <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div></h3>
                                    <p className="min-ft-14 mt-0 white text-center">
                                        <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`}>{fighter.user.username}</a>
                                    </p>
                                    <div className="back_separator"></div>
                                    <h3 className="text-center">
                                        <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                        <br />
                                        <span className="min-ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                        {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                    </h3>
                                    <div className="lvl_holder">
                                        <div className="lvl nb_one" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                    </div>
                                    <span className="next_lvl white">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                </div>
                            </div>
                        )}
                        {this.state.fighters.slice(2, 3).map((fighter, index) => fighter.user &&
                            <div className="third" key={`r_${index}`}>
                                <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                </div>
                                <div className="profile_board">
                                    <div className="nb_general nb_three centered_rank">#3</div>
                                    <h3 className="text-center mb-0">{fighter.nickname.toUpperCase()}
                                        <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div></h3>
                                    <p className="min-ft-14 mt-0 white text-center">
                                        <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`}>{fighter.user.username}</a>
                                    </p>
                                    <div className="back_separator"></div>
                                    <h3 className="text-center">
                                        <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                        <br />
                                        <span className="min-ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                        {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                    </h3>
                                    <div className="lvl_holder">
                                        <div className="lvl nb_three" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                    </div>
                                    <span className="next_lvl white">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>}
                <h1 className={!isMobile ? "huge" : "huge mt-50"}>{this.props.t('select_fighters')}</h1>
                <h4>{this.props.t('random_visuals')}</h4>
                <div className="max_holder">
                    {isMobile ?
                        <div className="minifighter_folder">
                            <div className="minifighter1" style={{ backgroundImage: `url('${FIGHTERM01JPG}')` }}></div>
                            <div className="minifighter2" style={{ backgroundImage: `url('${FIGHTERM02JPG}')` }}></div>
                            <div className="minifighter3" style={{ backgroundImage: `url('${FIGHTERM03JPG}')` }}></div>
                            <div className="minifighter4" style={{ backgroundImage: `url('${FIGHTERM04JPG}')` }}></div>
                        </div>
                        :
                        <div className="card" style={{ backgroundImage: `url('${FIGHTERM01JPG}')` }}>
                            <div className="card2" style={{ backgroundImage: `url('${FIGHTERM02JPG}')` }}></div>
                            <div className="card3" style={{ backgroundImage: `url('${FIGHTERM03JPG}')` }}></div>
                            <div className="card4" style={{ backgroundImage: `url('${FIGHTERM04JPG}')` }}></div>
                            <div className="info text-left">
                                <h5><small>LIGHTWEIGHT</small></h5>
                                <span className="label">{this.props.t('martial_art')}</span>
                                <span className="value">KICKBOXER</span>
                                <span className="label">{this.props.t('fullname')}</span>
                                <span className="value">Leroy Wallace <div className="flag us"></div></span>
                                <span className="label">{this.props.t('nickname')}</span>
                                <span className="value">The Endgame</span>
                                <h5>{this.props.t('level')} 1</h5>
                                <div className="lvl_holder">
                                    <div className="lvl back-coming"></div>
                                </div>
                                <span className="next_lvl">{this.props.t('next_level')} 25 points</span>
                            </div>
                        </div>}
                    <h2>{this.props.t('time_to_create')}</h2>
                    <div className="aura">
                        <div className="launch m-0" onClick={() => window.location.href = "/start/1"}>
                            <img className="sha" src={WHITEGOAT} alt="White GOAT" /> {this.props.t('start_now')}
                        </div>
                    </div>
                    <h1 className="huge">{this.props.t('predict_fights')}</h1>
                    <h4>{this.props.t('make_predicitions')}</h4>
                    {this.state.fights.slice(0, 1).map(fight =>
                        <div className="prono reduced-top text-center">
                            <span className={"label grey mt-30 mb-5 text-left ml-20"}><strong className="white">{this.state.event.event_name}</strong> - {fight.category} {fight.championship && "CHAMPIONSHIP"}</span>
                            <div className="left_part selected h-auto text-left">
                                <img src={FIGHTERM01PNG} alt="The dark crystal fighter" className="fighter" />
                                <h3 className="mb-0 text-center">{fight.fighter_one_ranking && <small> #{fight.fighter_one_ranking}</small>} {fight.fighter_one_firstname} {fight.fighter_one_lastname} <div className={`inline-flag ${fight.fighter_one_nationality}`}></div></h3>
                                <img
                                    src={`https://flagcdn.com/w320/${fight.fighter_one_nationality}.png`}
                                    style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                    alt={fight.fighter_one_nationality} />
                                {fight.fighter_one_avatar &&
                                    <img className="align-left" src={fight.fighter_one_avatar.includes("https") ?
                                        fight.fighter_one_avatar
                                        : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_one_avatar}`} alt="Fighter one profile" />
                                }
                                <span className="data mt-20">
                                    <div className="pill back-coming"><strong>{fight.fighter_one_wins}W</strong></div>
                                    <div className="pill back-danger">{fight.fighter_one_losses}L</div>
                                    {fight.fighter_one_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}</span>
                                <span className="data"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_one_age} {this.props.t('years_old')}</span>
                                <span className="data responsive-text-left">{fight.fighter_one_martial_arts.toUpperCase()}</span>
                                {fight.fighter_one_avatar && <div className="status back-primary">ACTIVE</div>}
                                <div className={"fighter_name back-primary"}>
                                    {parseInt(fight.fighter_one_odd * 1.4)}PTS | The Endgame <small>x1.4</small>
                                </div>
                            </div>
                            <div className={fight.fighter_one_avatar ? "vs black mt-20" : "vs no_profile black"}>vs</div>
                            <div className="right_part h-auto text-left">
                                <h3 className="mb-0 text-center">{fight.fighter_two_ranking && <small> #{fight.fighter_two_ranking}</small>} {fight.fighter_two_firstname} {fight.fighter_two_lastname} <div className={`inline-flag ${fight.fighter_two_nationality}`}></div></h3>
                                <img
                                    src={`https://flagcdn.com/w320/${fight.fighter_two_nationality}.png`}
                                    style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                    alt={fight.fighter_two_nationality} />
                                {fight.fighter_two_avatar &&
                                    <img className="align-right" src={fight.fighter_two_avatar.includes("https") ?
                                        fight.fighter_two_avatar
                                        : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_two_avatar}`} alt="Fighter two profile" />
                                }
                                <span className="data responsive-text-right mt-20">
                                    {fight.fighter_two_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                    <div className="pill back-coming"><strong>{fight.fighter_two_wins}W</strong></div>
                                    <div className="pill back-danger">{fight.fighter_two_losses}L</div>
                                </span>
                                <span className="data responsive-text-right"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_two_age} {this.props.t('years_old')}</span>
                                <span className="data responsive-text-right">{fight.fighter_two_martial_arts.toUpperCase()}</span>
                            </div>
                        </div>

                    )}
                    {
                        this.state.events.map(event => new Date(event.event_date) > new Date() &&
                            <div className="card_event card-fighter pointer d-inline-block ml-10 mr-10 mt-10 mb-0" onClick={() => window.location.href = `/preview/${event.id}`} key={event.id} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${event.poster}')`, backgroundSize: 'cover' }}>
                            </div>)
                    }
                    <h2 className="mt-50">{this.props.t('build_up_fighters')}</h2>
                    <div className="aura">
                        <div className="launch" onClick={() => window.location.href = "/start/1"}>
                            <img className="sha" src={WHITEGOAT} alt="White GOAT" /> {this.props.t('start_now')}
                        </div>
                    </div>
                </div >
                <Footer display={true} />
            </React.Fragment >
        );
    }
}

export default withTranslation()(Home);