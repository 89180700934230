import "./mobile.css"
import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import { withTranslation } from 'react-i18next';
import token_services from "../../services/token_services";
import events_services from "../../services/events_services";
import account_services from "../../services/account_services";
import InstallPWAMobile from "./InstallPWAMobile";
import InstalliOSMobile from "./InstalliOSMobile";
import { QWRTX_MMA_GOAT_PLAYERS } from "../../constants";

// Media
import WHITELOGO from "../../assets/img/white_goat.png";
import GOATCOIN from "../../assets/img/goat_coin.png";

class MobileHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            loading: true,
            profile: null,
            event: {},
            isWebview: true,
        }
    }

    isWebview = () => {
        const navigator = window.navigator
        const userAgent = navigator.userAgent
        const normalizedUserAgent = userAgent.toLowerCase()
        const standalone = navigator.standalone
        const isIos =
            /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
        const isAndroid = /android/.test(normalizedUserAgent)
        const isSafari = /safari/.test(normalizedUserAgent)
        const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)
        this.setState({ isWebview })
    }

    componentDidMount = async () => {
        this.isWebview()
        let profile = await token_services.getUser()
        if (profile) {
            profile = await account_services.getAccount()
            let events = await events_services.getAllEvents()
            let event = null
            for (let ev of events) {
                if (!event && (new Date(ev.event_date) > new Date()))
                    event = ev
            }
            this.setState({ event, profile: profile, loading: false })
        } else this.setState({ loading: false })
    }

    render() {
        return (
            isMobile && <header 
                style={{
                    top: !this.state.isWebview? "8px" : "40px",
                    width: !this.state.isWebview? "calc(100% - 16px)" : "calc(100% - 60px)",
                    left: !this.state.isWebview? "8px" : "60px",
                    borderTopRightRadius: !this.state.isWebview? "20px" : "0",
                    borderBottomRightRadius: !this.state.isWebview? "20px" : "0",
                }}>
                <img src={WHITELOGO} alt="MMA GOAT logo" className="logo_goat" onClick={() => window.location.href = this.state.profile ? `/whatsnew` : `/`} />
                {!this.state.isWebview && <InstallPWAMobile />}
                {!this.state.isWebview && <InstalliOSMobile />}
                {!this.state.loading && this.state.profile && this.state.event && <div className="actionner" onClick={() => window.location.href = this.state.profile ? `/event/${this.state.event.id}` : `/preview/${this.state.event.id}`}>{this.state.event.event_name.toUpperCase()}</div>}
                {!this.state.loading && !this.state.profile && <div className="actionner" onClick={() => window.location.href = `/login`}>{this.props.t('sign_in')}</div>}
                {!this.state.loading && this.state.profile && <div className="actionner goat_coins" onClick={() => window.location.href = "/store"}>{this.state.profile.goat_coins} <img src={GOATCOIN} alt="GOAT coins" /></div>}
                {this.state.profile &&
                    <div className={this.props.active === "profile" ? "profile_entry active" : "profile_entry"} onClick={() => window.location.href = `/profile/${this.state.profile.username}`}>
                        {this.state.profile.avatar ?
                            <div className="mobile_avatar" style={{ backgroundImage: `url(https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.profile.avatar})` }}></div>
                            :
                            <div className="mobile_avatar" style={{ backgroundImage: `url(https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.profile.username})` }}></div>
                        }
                    </div>
                }
            </header>
        );
    }
}

export default withTranslation()(MobileHeader);