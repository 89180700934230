import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import auth_services from "../../services/auth_services";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ProgressBar } from 'react-loader-spinner';
import { SITE_KEY } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import MobileHeader from "../../components/Menu/mobile";

//Media
import WHITEGOAT from "../../assets/img/white_goat.png";
import { isMobile } from "react-device-detect";

class ForgottenPswd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            token: null,
            password: null,
            success: false,
            processing: false,
            captcha: null,
            reset: false,
        }
    }

    onChange = (value) => {
        this.setState({ captcha: value })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.signIn()
        }
    }

    getReset = async () => {
        if (!this.state.email || !validator.isEmail(this.state.email))
            NotificationManager.warning(this.props.t('request_fill_all'))
        else if (!this.state.captcha)
            NotificationManager.warning(this.props.t('recaptcha_control'))
        else {
            try {
                this.setState({ processing: true })
                const resp = await auth_services.get_reset_token(
                    this.state.email,
                    this.state.captcha,
                )
                if (resp.status) {
                    this.setState({ reset: true })
                } else {
                    NotificationManager.error(this.props.t("could_not_send_reset_token"))
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
                this.setState({ processing: false })
            }
        }
    }

    resetPassword = async () => {
        if (!this.state.email || !validator.isEmail(this.state.email) ||
            !this.state.password || this.state.password.length < 4 || this.state.password.length > 25 || !this.state.token)
            NotificationManager.warning(this.props.t('request_fill_all'))
        else {
            try {
                this.setState({ processing: true })
                const resp = await auth_services.reset_password(
                    this.state.email,
                    this.state.token,
                    this.state.password,
                )
                if (resp.status) {
                    window.location.href = "/login"
                } else {
                    NotificationManager.error(this.props.t("could_not_reset_password"))
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data) : this.props.t(e.message))
                this.setState({ processing: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"login"} />
                <h1 className="starter huge">{this.props.t('reset_passord')}</h1>
                <h4>
                    {this.props.t('set_email_to_reset')}
                </h4>
                <p className="text-center"><strong>{this.props.t('no_account')}</strong></p>
                <div className="aura mb-0">
                    <div className="launch" onClick={() => window.location.href = "/start/1"}>
                        <img className="sha" src={WHITEGOAT} alt="Shadow fighter" /> {this.props.t('start_now')}
                    </div>
                </div>
                {this.state.reset ?
                    <div className="max_holder text-center">
                        <div className="editor fit-content h-auto text-left mt-20 full-border-radius simple_border_mark">
                            <span className="label">
                                {this.props.t('reset_token_received')} {this.state.email}* 
                                <br/>
                                <small>(*{this.props.t('only_if_account')})</small>
                            </span>
                            <input type="text" className="text_editor d-block" placeholder={this.props.t('reset_token')} onChange={(e) => this.setState({ token: e.target.value })} value={this.state.token}/>
                            <span className="label">
                                {this.props.t('password')}
                            </span>
                            <input type="password" className="text_editor d-block" placeholder={this.props.t('placeholder_password')} onKeyDown={this._handleKeyDown} onChange={(e) => this.setState({ password: e.target.value })} />
                            {!this.state.processing && !this.state.success && <div className="submit d-block" onClick={this.resetPassword}>
                                {this.props.t('set_new_password')}
                            </div>}
                            {this.state.processing &&
                                <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{ margin: '0 20px' }}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor={isMobile? '#FFF' : '#000'}
                                    barColor={isMobile? '#FFF' : '#000'}
                                />
                            }
                        </div>
                    </div>
                    :
                    <div className="max_holder text-center">
                        <div className="editor fit-content h-auto text-left mt-20 full-border-radius simple_border_mark">
                            <span className="label">
                                {this.props.t('email')}
                            </span>
                            <input type="email" className="text_editor d-block" placeholder={this.props.t('placeholder_email')} onChange={(e) => this.setState({ email: e.target.value })} />
                            <ReCAPTCHA
                                sitekey={SITE_KEY}
                                onChange={this.onChange}
                            />
                            {!this.state.processing && !this.state.success && <div className="submit d-block" onClick={this.getReset}>
                                {this.props.t('send_reset')}
                            </div>}
                            {this.state.processing &&
                                <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{ margin: '0 20px' }}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor='#000'
                                    barColor='#000'
                                />
                            }
                        </div>
                    </div>}
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(ForgottenPswd);