import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withTranslation } from 'react-i18next';
import CommentService from '../../services/comments_services';
import { ProgressBar } from "react-loader-spinner";
import { QWRTX_MMA_GOAT_PLAYERS } from '../../constants';

const Filter = require('bad-words');

class Entry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false,
            comment: '',
            processing: false,
        }
    }

    dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY) ?
            Math.floor((utc2 - utc1) / _MS_PER_DAY) === 1 ? 'Yesterday'
                :
                `${Math.floor((utc2 - utc1) / _MS_PER_DAY)} days ago`
            : parseInt(Math.abs(new Date().getTime(b) - new Date(a).getTime()) / 36e5)?
            `${parseInt(Math.abs(new Date().getTime(b) - new Date(a).getTime()) / 36e5)}h ago` : '20 minutes ago';
    }

    postComment = async () => {
        console.log(this.state.comment)
        const filter = new Filter({ placeHolder: '~~' });
        if (!this.state.comment || this.state.comment.length < 2)
            NotificationManager.warning(this.props.t('invalid_comment'))
        else {
            this.setState({ processing: true })
            try {
                let content = this.state.comment
                try{
                    content = filter.clean(this.state.comment).replace(/~~/g, "")
                }catch(e){
                    console.log('Could not clear comment')
                }
                await CommentService.addComment(this.props.entry.id, content)
                await this.props.loadNews()
                this.setState({ comment: null })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
            }
            this.setState({ processing: false })
        }
    }

    deactivateComment = async (id) => {
        this.setState({ processing: true })
        try {
            await CommentService.deactivateComment(id)
            await this.props.loadNews()
            NotificationManager.success("Successfully removed")
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
        }
        this.setState({ processing: false })
    }

    reportComment = async (id) => {
        this.setState({ processing: true })
        try {
            await CommentService.reportComment(id)
            NotificationManager.success("Successfully reported. Our team will review the comment.")
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
        }
        this.setState({ processing: false })
    }

    render() {
        return (
            this.props.entry.thumbnail && <div className="rank_list mb-30">
                <div className="table_fighter wider" onClick={() => window.open(this.props.entry.link)} style={{ backgroundImage: `url('${this.props.entry.thumbnail.split('?')[0]}')` }}></div>
                <div className="info_rank reevaluated">
                    <h3 className="text-left mb-5 mt-2 post-ft-14">
                        {this.props.entry.title} <small>| <a target="_blank" rel={"noreferrer"} href={this.props.entry.link} className="coming">Read more on MMA Junkie</a> </small>
                        <br />
                        <small>Posted {this.dateDiffInDays(new Date(this.props.entry.createdAt), new Date())}</small>
                    </h3>
                    {!this.state.display && <div className="profile_action d-inline-block" onClick={() => this.setState({ display: !this.state.display })}>
                        {!this.props.entry.comments || this.props.entry.comments.length === 0 ?
                            "Post comment" :
                            this.props.entry.comments.length === 1 ?
                                `1 comment`
                                :
                                `${this.props.entry.comments.length} comments`
                        }
                    </div>}
                </div>
                {this.state.display &&
                    <div className="p10">
                        {this.props.entry.comments.map(comment => !comment.deactivated &&
                            <div className="mb-5" key={comment.id}>
                                {comment.author.avatar ?
                                    <div className="table_min_user" onClick={() => window.location.href = `/profile/${comment.author.username}`} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${comment.author.avatar}')` }}></div>
                                    :
                                    <div className="table_min_user" onClick={() => window.location.href = `/profile/${comment.author.username}`} style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${comment.author.username}')` }}></div>
                                }
                                <div className="info_rank">
                                    <h3 className="mb-0 mt-0">
                                        <small>{comment.author.username}</small>
                                    </h3>
                                    <p className="min-ft-14 mt-0 mb-0 white">
                                        <small className="grey">posted Today</small> {this.props.profile && "|"} {this.props.profile && this.props.profile.id !== comment.author.id && <small className="pointer" onClick={() => this.reportComment(comment.id)}>Report</small>} {this.props.profile && this.props.profile.id === comment.author.id && <small className="pointer" onClick={() => this.deactivateComment(comment.id)}>Remove</small>}
                                    </p>
                                    <p className="text-left mb-10 mt-3 comment-ft-14">
                                        {comment.content}
                                    </p>
                                </div>
                            </div>)}
                        {this.props.profile && <React.Fragment>
                            <p className="min-ft-14 mt-0 mb-5 ml-10 white">
                                <small className="grey">Degrading or aggressive comments are not allowed</small>
                            </p>
                            <textarea placeholder="Leave your comment here" onChange={(e) => this.setState({ comment: e.target.value })} value={this.state.comment}></textarea>
                            <br/>
                            {this.state.processing ?
                                <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{ display: 'block', margin: '5px auto' }}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor='#FFF'
                                    barColor='#FFF'
                                />
                                :
                                this.props.profile && <div className="profile_action d-inline-block" onClick={this.postComment}>
                                    Post comment
                                </div>}
                        </React.Fragment>}
                    </div>
                }
                <NotificationContainer />
            </div>
        );
    }
}

export default withTranslation()(Entry);