import api from './api';

class EventsService {
  async getAllEvents() {
    return await api
      .get('/events/')
      .then(async (response) => {
        return response.data.events;
      });
  }
  async getOpenEvents() {
    return await api
      .get('/events/open')
      .then(async (response) => {
        return response.data.events;
      });
  }

  async getEvent(id) {
    return await api
      .get(`/events/details/${id}`)
      .then(async (response) => {
        return response.data;
      });
  }
}

// eslint-disable-next-line
export default new EventsService();