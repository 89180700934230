import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { useParams } from 'react-router-dom';
import MobileHeader from "../../components/Menu/mobile";
import { OPTIONS, QWRTX_MMA_GOAT_EVENTS, QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import events_services from "../../services/events_services";
import Fighters from "./fighters";
import account_services from "../../services/account_services";
import { ProgressBar } from "react-loader-spinner";
import token_services from "../../services/token_services";
import { isMobile } from "react-device-detect";
import public_services from "../../services/public_services";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// Media
import GOATCOIN from "../../assets/img/goat_coin.png"

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class Event extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            fights: [],
            cancelled: [],
            event: null,
            event_id: null,
            display: "fights",
            users: [],
        }
    }

    componentDidMount = async () => {
        await this.loadEvents()
    }

    loadEvents = async () => {
        const { id } = this.props.match.params
        const user = await token_services.getUser()
        if (!user) window.location.href = `/preview/${id}`
        this.setState({ event: null })
        const data = await events_services.getEvent(id)
        const fighters = await account_services.getEventFighters(id)
        let fights = []
        for (let fight of data.fights) {
            for (let fighter of fighters) {
                if (fighter.fight && fighter.fight.id === fight.id) {
                    fight.prediction = fighter
                }
            }
            fights.push(fight)
        }
        this.setState({ event: data.event, event_id: id, fights, fighters, cancelled: data.cancelled })
        if (new Date(data.event.event_date) < new Date()) {
            const users = await public_services.getEventRankedUsers(id)
            this.setState({ users })
        }
    }

    handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active="events" />
                {!this.state.event ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <img className={isMobile ? "event_visual mt-50" : "event_visual"} src={`https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${this.state.event.banner}`} alt="shadow" />
                        <h4>
                            {this.state.event.place}
                            <br />
                            <span className="capitalize">{new Date(this.state.event.event_date).toLocaleDateString(undefined, OPTIONS)}</span>, {new Date(this.state.event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </h4>
                        {new Date(this.state.event.event_date) < new Date() && this.state.display === "fights" &&
                            <div className="max_holder">
                                <h4 className="mt-50 mb-0">{this.props.t('browse_best_players')} {this.state.event.event_name}</h4>
                                <div className="edit" onClick={() => this.setState({ display: "ranking" })}>
                                    {this.props.t('event_ranking')}
                                </div>
                            </div>
                        }
                        {this.state.display === "ranking" &&
                            <div className="max_holder">
                                <h4 className="mt-50 mb-0">{this.props.t('browse_fight_card')}  {this.state.event.event_name}</h4>
                                <div className="edit" onClick={() => this.setState({ display: "fights" })}>
                                    {this.props.t('event_fights')}
                                </div>
                            </div>
                        }
                        {this.state.display === "fights" && this.state.fights.map(fight =>
                            fight.prediction ?
                                <div className="prono_holder" key={fight.id}>
                                    <div className="prono reduced-top text-center max-bottom">
                                        <span className={"label grey mt-30 mb-5 text-left ml-20"}>{fight.category} {fight.championship && "CHAMPIONSHIP"} - {fight.nb_rounds} rounds</span>
                                        <div className={fight.prediction.prediction.contestant === "one" ?
                                            fight.prediction.prediction.active_status === "AWAITING" ? "left_part selected h-auto text-left" : fight.prediction.prediction.active_status === "WIN" ? "left_part selected_win h-auto text-left" : fight.prediction.prediction.active_status === "LOSS" ? "left_part selected_loss h-auto text-left" : "left_part selected_draw h-auto text-left"
                                            : "left_part h-auto text-left"}>
                                            {fight.prediction.prediction.contestant === "one" && <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fight.prediction.visual.section}/${fight.prediction.fighter}/${fight.prediction.visual.visual}.png`} alt={fight.prediction.nickname} className="fighter" />}
                                            <h3 className="mb-0 text-center">{fight.fighter_one_ranking && <small> #{fight.fighter_one_ranking}</small>} {fight.fighter_one_firstname} {fight.fighter_one_lastname}</h3>
                                            <img
                                                src={`https://flagcdn.com/w320/${fight.fighter_one_nationality}.png`}
                                                style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                                alt={fight.fighter_one_nationality} />
                                            {fight.fighter_one_avatar &&
                                                <img className="align-left" src={fight.fighter_one_avatar.includes("https") ?
                                                    fight.fighter_one_avatar
                                                    : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_one_avatar}`} alt="Fighter profile" />
                                            }
                                            <span className="data mt-20">
                                                <div className="pill back-coming"><strong>{fight.fighter_one_wins}W</strong></div>
                                                <div className="pill back-danger">{fight.fighter_one_losses}L</div>
                                                {fight.fighter_one_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                                {fight.winner === 'one' && <span className="winner_tag">W. <small>by {fight.victory}</small></span>} {fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}</span>
                                            <span className="data"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_one_age} {this.props.t('years_old')}</span>
                                            <span className="data responsive-text-left">{fight.fighter_one_martial_arts.toUpperCase()}</span>
                                            {fight.prediction.prediction.contestant === "one" && fight.fighter_one_avatar && fight.prediction.prediction.active_status === "AWAITING" && <div className="status back-primary">ACTIVE</div>}
                                            {fight.prediction.prediction.contestant === "one" && fight.fighter_one_avatar && fight.prediction.prediction.active_status === "WIN" && <div className="status back-coming">WIN</div>}
                                            {fight.prediction.prediction.contestant === "one" && fight.fighter_one_avatar && fight.prediction.prediction.active_status === "DRAW" && <div className="status back-black white">DRAW</div>}
                                            {fight.prediction.prediction.contestant === "one" && fight.fighter_one_avatar && fight.prediction.prediction.active_status === "LOSS" && <div className="status back-danger">LOSS</div>}
                                            {fight.prediction.prediction.contestant === "one" &&
                                                <div className={fight.prediction.prediction.active_status === "AWAITING" ? "fighter_name back-primary" : fight.prediction.prediction.active_status === "WIN" ? "fighter_name back-coming" : fight.prediction.prediction.active_status === "LOSS" ? "fighter_name back-danger" : "fighter_name"}><span>{fight.prediction.prediction.points}<small>PTS</small></span> | {fight.prediction.nickname.toUpperCase()}
                                                    {(fight.prediction.visual.weightclass.toLowerCase() === fight.category.toLowerCase())
                                                        && (fight.prediction.nationality === fight.fighter_one_nationality)
                                                        && (fight.prediction.martial_art.toLowerCase() === fight.fighter_one_martial_arts.toLowerCase()) ?
                                                        <small className={"ml-10"}>{fight.prediction.prediction.booster ? fight.prediction.prediction.booster === 1 ? "x3" : `x${2 + fight.prediction.prediction.booster}` : "x2"}</small>
                                                        :
                                                        <small className={"ml-10"}>x{parseFloat(1 +
                                                            ((fight.prediction.visual.weightclass.toLowerCase() === fight.category.toLowerCase() ? 4 : 0) +
                                                                (fight.prediction.nationality === fight.fighter_one_nationality ? 2 : 0) +
                                                                (fight.prediction.martial_art.toLowerCase() === fight.fighter_one_martial_arts.toLowerCase() ? 4 : 0)) / 10
                                                            + (fight.prediction.prediction.booster ? fight.prediction.prediction.booster : 0)).toFixed(1)
                                                        }</small>
                                                    }
                                                </div>}
                                            <div className="left_coins">+{fight.fighter_one_odd} <img src={GOATCOIN} alt="GOAT coin" /></div>
                                            <div className="left_pts disabled">
                                                <big>{fight.fighter_one_odd}</big> <small>PTS</small>
                                                <br />
                                                <span className="ft-11">ACTIVE PICK</span>
                                            </div>
                                            <div className="click text-left pl-0"></div>
                                        </div>
                                        <div className={fight.prediction.prediction.contestant === "one" && isMobile ? fight.fighter_one_avatar ? "vs mt-30" : "vs no_profile mt-30" : fight.fighter_one_avatar ? "vs" : "vs no_profile"}>vs</div>
                                        <div className={fight.prediction.prediction.contestant === "two" ?
                                            fight.prediction.prediction.active_status === "AWAITING" ? "right_part selected h-auto text-left" : fight.prediction.prediction.active_status === "WIN" ? "right_part selected_win h-auto text-left" : fight.prediction.prediction.active_status === "LOSS" ? "right_part selected_loss h-auto text-left" : "right_part selected_draw h-auto text-left"
                                            : "right_part h-auto text-left"}>
                                            {fight.prediction.prediction.contestant === "two" && <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fight.prediction.visual.section}/${fight.prediction.fighter}/${fight.prediction.visual.visual}.png`} alt={fight.prediction.nickname} className={isMobile ? "fighter" : "right_fighter"} />}
                                            <h3 className="mb-0 text-center">{fight.fighter_two_ranking && <small> #{fight.fighter_two_ranking}</small>} {fight.fighter_two_firstname} {fight.fighter_two_lastname}</h3>
                                            <img
                                                src={`https://flagcdn.com/w320/${fight.fighter_two_nationality}.png`}
                                                style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                                alt={fight.fighter_two_nationality} />
                                            {fight.fighter_two_avatar &&
                                                <img className="align-right" src={fight.fighter_two_avatar.includes("https") ?
                                                    fight.fighter_two_avatar
                                                    : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_two_avatar}`} alt="Fighter profile" />
                                            }
                                            <span className="data responsive-text-right mt-20">
                                                {fight.fighter_two_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                                <div className="pill back-coming"><strong>{fight.fighter_two_wins}W</strong></div>
                                                <div className="pill back-danger">{fight.fighter_two_losses}L</div>
                                                {fight.winner === 'two' && <span className="winner_tag">W. <small>by {fight.victory}</small></span>} {fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}
                                            </span>
                                            <span className="data responsive-text-right"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_two_age} {this.props.t('years_old')}</span>
                                            <span className="data responsive-text-right">{fight.fighter_two_martial_arts.toUpperCase()}</span>
                                            {fight.prediction.prediction.contestant === "two" && fight.fighter_two_avatar && fight.prediction.prediction.active_status === "AWAITING" && <div className="status back-primary">ACTIVE</div>}
                                            {fight.prediction.prediction.contestant === "two" && fight.fighter_two_avatar && fight.prediction.prediction.active_status === "WIN" && <div className="status back-coming">WIN</div>}
                                            {fight.prediction.prediction.contestant === "two" && fight.fighter_two_avatar && fight.prediction.prediction.active_status === "DRAW" && <div className="status back-black white">DRAW</div>}
                                            {fight.prediction.prediction.contestant === "two" && fight.fighter_two_avatar && fight.prediction.prediction.active_status === "LOSS" && <div className="status back-danger">LOSS</div>}
                                            {fight.prediction.prediction.contestant === "two" &&
                                                <div className={fight.prediction.prediction.active_status === "AWAITING" ? "fighter_name back-primary" : fight.prediction.prediction.active_status === "WIN" ? "fighter_name back-coming" : fight.prediction.prediction.active_status === "LOSS" ? "fighter_name back-danger" : "fighter_name"}><span>{fight.prediction.prediction.points}<small>PTS</small></span> | {fight.prediction.nickname.toUpperCase()}
                                                    {(fight.prediction.visual.weightclass.toLowerCase() === fight.category.toLowerCase())
                                                        && (fight.prediction.nationality === fight.fighter_two_nationality)
                                                        && (fight.prediction.martial_art.toLowerCase() === fight.fighter_two_martial_arts.toLowerCase()) ?
                                                        <small className={"ml-10"}>{fight.prediction.prediction.booster ? fight.prediction.prediction.booster === 1 ? "x3" : `x${2 + fight.prediction.prediction.booster}` : "x2"}</small>
                                                        :
                                                        <small className={"ml-10"}>x{
                                                            parseFloat(1 + ((fight.prediction.visual.weightclass.toLowerCase() === fight.category.toLowerCase() ? 4 : 0) +
                                                                (fight.prediction.nationality === fight.fighter_two_nationality ? 2 : 0) +
                                                                (fight.prediction.martial_art.toLowerCase() === fight.fighter_two_martial_arts.toLowerCase() ? 4 : 0)) / 10
                                                                + (fight.prediction.prediction.booster ? fight.prediction.prediction.booster : 0)).toFixed(1)
                                                        }</small>
                                                    }
                                                </div>}
                                            <div className={isMobile ? "left_coins" : "right_coins"}>+{fight.fighter_two_odd} <img src={GOATCOIN} alt="GOAT coin" /></div>
                                            <div className="right_pts disabled">
                                                <big>{fight.fighter_two_odd}</big> <small>PTS</small>
                                                <br />
                                                <span className="ft-11">ACTIVE PICK</span>
                                            </div>
                                            <div className="click text-left pl-0"></div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="prono_holder" key={fight.id}>
                                    <div className="prono h-auto reduced-top text-center">
                                        <span className={"label grey mt-30 mb-5 text-left ml-20"}>{fight.category} {fight.championship && "CHAMPIONSHIP"} - {fight.nb_rounds} rounds</span>
                                        <div className="left_part h-auto text-left">
                                            <h3 className="mb-0 text-center">{fight.fighter_one_ranking && <small> #{fight.fighter_one_ranking}</small>} {fight.fighter_one_firstname} {fight.fighter_one_lastname}</h3>
                                            <img
                                                src={`https://flagcdn.com/w320/${fight.fighter_one_nationality}.png`}
                                                style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                                alt={fight.fighter_one_nationality} />
                                            {fight.fighter_one_avatar &&
                                                <img className="align-left" src={fight.fighter_one_avatar.includes("https") ?
                                                    fight.fighter_one_avatar
                                                    : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_one_avatar}`} alt="Fighter profile" />
                                            }
                                            <span className="data mt-20">
                                                <div className="pill back-coming"><strong>{fight.fighter_one_wins}W</strong></div>
                                                <div className="pill back-danger">{fight.fighter_one_losses}L</div>
                                                {fight.fighter_one_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                                {fight.winner === 'one' && <span className="winner_tag">W. <small>by {fight.victory}</small></span>} {fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}</span>
                                            <span className="data"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_one_age} {this.props.t('years_old')}</span>
                                            <span className="data responsive-text-left">{fight.fighter_one_martial_arts.toUpperCase()}</span>
                                            <div className="left_coins">+{fight.fighter_one_odd} <img src={GOATCOIN} alt="GOAT coin" /></div>
                                            {fight.winner ?
                                                <div className="left_pts disabled">
                                                    <big>{fight.fighter_one_odd}</big> <small>PTS</small>
                                                    <br />
                                                    <span className="ft-11">PICK & GET</span>
                                                </div>
                                                :
                                                <div className={this.state.selected === `${fight.id}_one` ? "left_pts action action_selected" : "left_pts action"} onClick={() => (new Date(this.state.event.event_date) > new Date()) && this.setState({ selected: this.state.selected === `${fight.id}_one` ? null : `${fight.id}_one` }, () => {
                                                    this.handleClickScroll(fight.id);
                                                })}>
                                                    <big>{fight.fighter_one_odd}</big> <small>PTS</small>
                                                    <br />
                                                    <span className="ft-11">PICK & GET</span></div>
                                            }
                                            {(new Date(this.state.event.event_date) > new Date()) ?
                                                <div className="click text-left pl-0">{this.props.t('click_predict')} <FaArrowRight /></div>
                                                :
                                                <div className="click text-left pl-0"></div>
                                            }
                                        </div>
                                        <div className={fight.fighter_one_avatar ? "vs" : "vs no_profile"}>vs</div>
                                        <div className={this.state.selected === `${fight.id}_two` ? "right_part h-auto for_validation" : "right_part h-auto"}>
                                            <h3 className="mb-0 text-center">{fight.fighter_two_ranking && <small> #{fight.fighter_two_ranking}</small>} {fight.fighter_two_firstname} {fight.fighter_two_lastname}</h3>
                                            <img
                                                src={`https://flagcdn.com/w320/${fight.fighter_two_nationality}.png`}
                                                style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                                alt={fight.fighter_two_nationality} />
                                            {fight.fighter_two_avatar &&
                                                <img className="align-right" src={fight.fighter_two_avatar.includes("https") ?
                                                    fight.fighter_two_avatar
                                                    : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_two_avatar}`} alt="Fighter profile" />
                                            }
                                            <span className="data responsive-text-right mt-20">
                                                {fight.fighter_two_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                                <div className="pill back-coming"><strong>{fight.fighter_two_wins}W</strong></div>
                                                <div className="pill back-danger">{fight.fighter_two_losses}L</div>
                                                {fight.winner === 'two' && <span className="winner_tag">W. <small>by {fight.victory}</small></span>} {fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}
                                            </span>
                                            <span className="data responsive-text-right"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_two_age} {this.props.t('years_old')}</span>
                                            <span className="data responsive-text-right">{fight.fighter_two_martial_arts.toUpperCase()}</span>
                                            <div className={isMobile ? "left_coins" : "right_coins"}>+{fight.fighter_two_odd} <img src={GOATCOIN} alt="GOAT coin" /></div>
                                            {fight.winner ?
                                                <div className="right_pts disabled">
                                                    <big>{fight.fighter_two_odd}</big> <small>PTS</small>
                                                    <br />
                                                    <span className="ft-11">PICK & GET</span>
                                                </div>
                                                :
                                                <div className={this.state.selected === `${fight.id}_two` ? "right_pts action action_selected" : "right_pts action"} onClick={() => (new Date(this.state.event.event_date) > new Date()) && this.setState({ selected: this.state.selected === `${fight.id}_two` ? null : `${fight.id}_two` }, () => {
                                                    this.handleClickScroll(fight.id);
                                                })}>
                                                    <big>{fight.fighter_two_odd}</big> <small>PTS</small>
                                                    <br />
                                                    <span className="ft-11" id={fight.id}>PICK & GET</span></div>
                                            }
                                            {!fight.winner && (new Date(this.state.event.event_date) > new Date()) ?
                                                <React.Fragment>
                                                    {!isMobile ?
                                                        <div className="click_right pr-0"><FaArrowLeft /> {this.props.t('click_predict')}</div>
                                                        :
                                                        <div className="click text-left pl-0">{this.props.t('click_predict')} <FaArrowRight /></div>
                                                    }
                                                </React.Fragment>
                                                :
                                                <div className="click text-left pl-0"></div>
                                            }
                                        </div>
                                        {!fight.winner && (new Date(this.state.event.event_date) < new Date()) && <div className="click">{this.props.t('event_in_progress')} <strong>{this.props.t('predictions_closed')}</strong></div>}
                                    </div>
                                    {fight.fight_status === "ACTIVE" && this.state.selected === `${fight.id}_one` && (new Date(this.state.event.event_date) > new Date()) && <Fighters fighters={this.state.fighters}
                                        fight_id={fight.id}
                                        selected={"one"}
                                        firstname={fight.fighter_one_firstname}
                                        lastname={fight.fighter_one_lastname}
                                        odds={parseInt(fight.fighter_one_odd)}
                                        weightclass={fight.category}
                                        nationality={fight.fighter_one_nationality}
                                        fighter_type={fight.fighters}
                                        martial_art={fight.fighter_one_martial_arts}
                                        rounds={fight.nb_rounds}
                                        loadEvents={this.loadEvents} />}
                                    {fight.fight_status === "ACTIVE" && this.state.selected === `${fight.id}_two` && (new Date(this.state.event.event_date) > new Date()) && <Fighters fighters={this.state.fighters}
                                        fight_id={fight.id}
                                        selected={"two"}
                                        firstname={fight.fighter_two_firstname}
                                        lastname={fight.fighter_two_lastname}
                                        odds={parseInt(fight.fighter_two_odd)}
                                        weightclass={fight.category}
                                        nationality={fight.fighter_two_nationality}
                                        fighter_type={fight.fighters}
                                        martial_art={fight.fighter_two_martial_arts}
                                        rounds={fight.nb_rounds}
                                        loadEvents={this.loadEvents} />}
                                </div>
                        )}
                        {this.state.cancelled.map(fight =>
                            <div className="prono h-auto reduced-top text-center" key={fight.id}>
                                <span className={"label grey mt-30 mb-5 text-left ml-20"}>{fight.category} {fight.championship && "CHAMPIONSHIP"} - {fight.nb_rounds} rounds</span>
                                <div className="left_part h-auto back-dark white text-left">
                                    <h3 className="mb-0 text-center">{fight.fighter_one_ranking && <small> #{fight.fighter_one_ranking}</small>} {fight.fighter_one_firstname} {fight.fighter_one_lastname}</h3>
                                    <img
                                        src={`https://flagcdn.com/w320/${fight.fighter_one_nationality}.png`}
                                        style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                        alt={fight.fighter_one_nationality} />
                                    {fight.fighter_one_avatar &&
                                        <img className="align-left" src={fight.fighter_one_avatar.includes("https") ?
                                            fight.fighter_one_avatar
                                            : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_one_avatar}`} alt="Fighter profile" />
                                    }
                                    <span className="data mt-20">
                                        <div className="pill back-coming"><strong>{fight.fighter_one_wins}W</strong></div>
                                        <div className="pill back-danger">{fight.fighter_one_losses}L</div>
                                        {fight.fighter_one_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}</span>
                                    <span className="data"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_one_age} {this.props.t('years_old')}</span>
                                    <span className="data responsive-text-left">{fight.fighter_one_martial_arts.toUpperCase()}</span>
                                    <span className="data responsive-text-left"><strong className="danger">{this.props.t('cancelled')}</strong></span>
                                </div>
                                <div className={fight.fighter_one_avatar ? "vs" : "vs no_profile"}>vs</div>
                                <div className="right_part h-auto back-dark white responsive-text-right">
                                    <h3 className="mb-0 text-center">{fight.fighter_two_ranking && <small> #{fight.fighter_two_ranking}</small>} {fight.fighter_two_firstname} {fight.fighter_two_lastname}</h3>
                                    <img
                                        src={`https://flagcdn.com/w320/${fight.fighter_two_nationality}.png`}
                                        style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                        alt={fight.fighter_two_nationality} />
                                    {fight.fighter_two_avatar &&
                                        <img className="align-left" src={fight.fighter_two_avatar.includes("https") ?
                                            fight.fighter_two_avatar
                                            : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_two_avatar}`} alt="Fighter profile" />
                                    }
                                    <span className="data mt-20">
                                        <div className="pill back-coming"><strong>{fight.fighter_two_wins}W</strong></div>
                                        <div className="pill back-danger">{fight.fighter_two_losses}L</div>
                                        {fight.fighter_two_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}</span>
                                    <span className="data responsive-text-right"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_two_age} {this.props.t('years_old')}</span>
                                    <span className="data responsive-text-right">{fight.fighter_two_martial_arts.toUpperCase()}</span>
                                    <span className="data responsive-text-right"><strong className="danger">{this.props.t('cancelled')}</strong></span>
                                </div>
                            </div>)}
                        {this.state.display === "ranking" &&
                            <div className="max_holder">
                                <div className="participants">
                                    {this.state.users.map((manager, index) =>
                                        <div className="profile pointer simple_border_mark text-left" key={manager.id} onClick={() => window.location.href = `/profile/${manager.username}`}>
                                            <div className={index === 0 ? "nb_general nb_one resized" : index === 1 ? "nb_general nb_two resized" : index === 2 ? "nb_general nb_three resized" : "nb_general resized"}>
                                                #{index + 1}
                                                <span className="minimized"><strong>{manager.points} </strong>points</span>
                                            </div>
                                            {manager.avatar ?
                                                <div className="avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${manager.avatar}')` }}></div>
                                                :
                                                <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${manager.username}')` }}></div>
                                            }
                                            <div className="info_profile">
                                                <big>{manager.username}</big>
                                                <br />
                                                <span className="grey ft-14">{this.props.t('managing')} {manager.fighters} {this.props.t('fighters')}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </React.Fragment>}
                <Footer />
            </React.Fragment >
        );
    }
}

export default withRouter(withTranslation()(Event));