import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import MobileHeader from "../../components/Menu/mobile";

// Media
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import { countries } from "../../components/countries";
import account_services from "../../services/account_services";
import { ProgressBar } from "react-loader-spinner";
import { isMobile } from "react-device-detect";

class StoreConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fighter: null,
            processing: false,
            distribution: {}
        }
    }

    componentDidMount = async () => {
        if (window.localStorage.getItem('mma_goat_purchase')) {
            const fighter = JSON.parse(window.localStorage.getItem('mma_goat_purchase'))
            const distribution = await account_services.getPendingDistribution(fighter.distribution)
            this.setState({ fighter, distribution })
        } else
            window.location.href = "/fighters"
    }

    confirmFighter = async () => {
        try {
            this.setState({ processing: true })
            const fighter = JSON.parse(window.localStorage.getItem('mma_goat_purchase'))
            const status = await account_services.createNewFighter({
                distribution: fighter.distribution,
                martial_art: fighter.martial_art,
                firstname: fighter.firstname,
                lastname: fighter.lastname,
                nickname: fighter.nickname,
                nationality: fighter.nationality,
            })
            if (status) {
                NotificationManager.success(this.props.t('fighter_successfully_created'))
                window.localStorage.removeItem('mma_goat_purchase')
                window.location.href = "/fighters"
            } else {
                NotificationManager.error(this.props.t('error_create_fighter'))
            }
            this.setState({ processing: false })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
            this.setState({ processing: false })
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"store"} />
                <h1 className="starter huge">{this.props.t('confirm_your_fighter')}</h1>
                <h4>{this.props.t('confirm_fighter_profile')}</h4>
                <div className="max_holder">
                    {this.state.fighter &&
                        <div className="card card-confirm responsive-m-right">
                            <div className="info text-left">
                                <h5>
                                    <small>{this.state.distribution.visual.weightclass.toUpperCase()}</small>
                                </h5>
                                <span className="label">{this.props.t('martial_art')}</span>
                                <span className="value">{this.state.fighter.martial_art.toUpperCase()}</span>
                                <span className="label">{this.props.t('fullname')}</span>
                                <span className="value">
                                    {this.state.fighter.firstname} {this.state.fighter.lastname}
                                    <div className={`flag ${this.state.fighter.nationality}`} title={countries[this.state.fighter.nationality.toUpperCase()]}></div>
                                </span>
                                <span className="label">{this.props.t('nickname')}</span>
                                <span className="value">{this.state.fighter.nickname.toLowerCase()}</span>
                                <h5>{this.props.t('level')} 1</h5>
                                <div className="lvl_holder">
                                    <div className="lvl back-coming"></div>
                                </div>
                                <span className="next_lvl">{this.props.t('next_level')} 25 points</span>
                                <hr />
                                <span className="next_lvl text-center">{this.props.t('edit_fighter_profile')}</span>
                                <div className="edit" onClick={() => window.location.href = `/store/create/${this.state.distribution.id}`}>
                                    {this.props.t('edit')}
                                </div>
                            </div>
                            <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${this.state.distribution.visual.section}/${this.state.distribution.visual.fighter}/${this.state.distribution.visual.visual}.png`} alt={this.state.fighter.nickname.toLowerCase()} className={isMobile ? "visual" : "visual scale_max"} />
                        </div>}
                </div>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '0 auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="confirm" onClick={this.confirmFighter}>
                        {this.props.t('confirm_fighter')}
                    </div>}
                <NotificationContainer />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(StoreConfirm);