import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import public_services from "../../services/public_services";

// Media
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import { ProgressBar } from "react-loader-spinner";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/Menu/mobile";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profiles: [],
            fighters: [],
            switch: "users",
            search: null,
            processing: false,
        }
    }

    onSearch = async (search) => {
        if (search) {
            this.setState({ processing: true })
            if (this.state.switch === "users") {
                const profiles = await public_services.searchProfile(search)
                this.setState({ profiles })
            } else if (this.state.switch === "fighters") {
                const fighters = await public_services.searchFighters(search)
                this.setState({ fighters })
            }
            this.setState({ search, processing: false })
        }
    }

    switchToUsers = async () => {
        this.setState({ switch: "users", processing: true })
        const profiles = await public_services.searchProfile(this.state.search)
        this.setState({ profiles, processing: false })
    }

    switchToFighters = async () => {
        this.setState({ switch: "fighters", processing: true })
        const fighters = await public_services.searchFighters(this.state.search)
        this.setState({ fighters, processing: false })
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"search"} />
                {!isMobile && <h1 className="starter huge">{this.props.t('search')}</h1>}
                <div className="search mt-20">
                    <span className="label ml-20">{this.props.t('search_users_fighters')}</span>
                    <input type="search" className="search_editor" placeholder={this.props.t('search...')} onChange={(e) => this.onSearch(e.target.value)} />
                    <div className="tabs">
                        <div className={this.state.switch === "users" ? "tab_active" : "tab"} onClick={this.switchToUsers}>{this.props.t('users')}</div>
                        <div className={this.state.switch === "fighters" ? "tab_active" : "tab"} onClick={this.switchToFighters}>{this.props.t('fighters')}</div>
                    </div>
                    {this.state.processing &&
                        <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{ margin: '10px auto', display: 'block' }}
                            wrapperClass="progress-bar-wrapper"
                            borderColor='#FFF'
                            barColor='#FFF'
                        />}
                    {!this.state.processing && this.state.switch === "users" && <React.Fragment>
                        {this.state.profiles.map(profile =>
                            <div className="profile" key={profile.id} onClick={() => window.location.href = `/profile/${profile.username}`}>
                                {profile.avatar ?
                                    <div className="avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${profile.avatar}')` }}></div>
                                    :
                                    <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${profile.username}')` }}></div>
                                }
                                <div className="username">
                                    {profile.username}
                                    <br />
                                    <span className="fighters">{profile.fighters} {this.props.t('fighters').toLowerCase()}</span>
                                </div>
                            </div>
                        )}
                        {this.state.profiles.length === 0 &&
                            <p><small><strong>{this.props.t('no_users')}</strong></small></p>
                        }
                    </React.Fragment>}
                    {!this.state.processing && this.state.switch === "fighters" && <React.Fragment>
                        {this.state.fighters.map(fighter =>
                            <div className="profile" key={fighter.id}>
                                <div className="fighter_visual" onClick={() => window.location.href = fighter.user? `/profile/${fighter.user.username}` : null} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}></div>
                                <div className="username">
                                    <strong>{fighter.nickname.toUpperCase()}</strong>
                                    <br />
                                    <span className="fighters">{fighter.firstname} {fighter.lastname}</span>
                                    {fighter.user &&
                                        <div className="username" onClick={() => window.location.href = `/profile/${fighter.user.username}`}>
                                            <small>{this.props.t('managed_by')}</small>
                                            <br />
                                            <strong>{fighter.user.username}</strong>
                                        </div>}
                                </div>
                            </div>
                        )}
                        {this.state.fighters.length === 0 &&
                            <p><small><strong>{this.props.t('no_fighters...')}</strong></small></p>
                        }
                    </React.Fragment>}
                </div>

                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Search);