import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import league_services from "../../services/league_services";
import { FRONT_ADDRESS, QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import MobileHeader from "../../components/Menu/mobile";

//Media
import { ProgressBar } from "react-loader-spinner";
import token_services from "../../services/token_services";

class Leagues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            leagues: [],
            processing: true,
            copied: null,
            opens: [],
            display: "opened",
        }
    }

    componentDidMount = async () => {
        const { innerWidth: width } = window
        this.setState({ width })
        const profile = await token_services.getUser()
        if (profile) this.setState({ profile: profile.account })
        else window.location.href = "/"
        let opens = await league_services.getOpenLeagues()
        let leagues = await league_services.created()
        let joined = await league_services.joined()
        leagues.push(...joined)
        this.setState({ leagues, processing: false, opens })
    }

    copyCode = async (code) => {
        navigator.clipboard.writeText(`${FRONT_ADDRESS}/league/join/${code}`)
        this.setState({ copied: code })
        setTimeout(() => {
            this.setState({ copied: null })
        }, 3000);
    }

    leaveLeague = async (id) => {
        this.setState({ processing: true })
        await league_services.leaveLeague(id)
        window.location.reload()
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"leagues"} />
                <h1 className="huge starter">{this.props.t('leagues')}</h1>
                <h4>{this.props.t('manage_private_leagues')}</h4>
                <div className="ranking_container">
                    <div className="tabs">
                        <div className={this.state.display === "opened" ? "tab_active" : "tab"} onClick={() => this.setState({ display: "opened" })}>
                            Open Leagues
                        </div>
                        <div className={this.state.display === "private" ? "tab_active" : "tab"} onClick={() => this.setState({ display: "private" })}>
                            My leagues
                        </div>
                    </div>
                </div>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    /> : this.state.display === "private" ?
                        <React.Fragment>
                            {this.state.leagues.length === 0 ?
                                <div className="d-block">
                                    <span className="next_lvl text-center">{this.props.t('no_joined_league')}</span>
                                </div>
                                :
                                this.state.leagues.map(league => <div className="league" key={league.id}>
                                    <div className="teaser" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/rings/svg?seed=${league.league_name}` }}></div>
                                    <h2 className="mt-0"><strong className="audiowide clickable">{league.league_name.toUpperCase()}</strong>
                                        <br />
                                        {this.state.profile.id === league.creator && <small>{this.props.t('created_by')} {this.state.profile.username}</small>}
                                        {this.state.profile.id !== league.creator && <small>{this.props.t('created_by')} {league.creator_profile.username}</small>}
                                    </h2>
                                    {this.state.width > 940 ?
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('active_since')}</span>
                                                    </td>
                                                    <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('participants')}</span>
                                                    </td>
                                                    <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('fighters')}</span>
                                                    </td>
                                                    {this.state.profile.id !== league.creator && <td>
                                                        <span className="label mt-0 text-left mr-20">Exit</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "code" && <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('code')}</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "invitation" && <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('invitation')}</span>
                                                    </td>}
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="value mt-0 mr-20">{new Date(league.createdAt).toLocaleDateString()}</span>
                                                    </td>
                                                    <td>
                                                        <span className="profile_action mr-20" onClick={() => window.location.href = `/league/participants/${league.id}`}>{league.participants.length} / <small>{league.league_limit > 0 ? league.league_limit : this.props.t('unlimited')}</small></span>
                                                    </td>
                                                    <td>
                                                        <span className="profile_action mr-20" onClick={() => window.location.href = `/league/fighters/${league.id}`}>{league.fighters.length} {this.props.t('fighters')}</span>
                                                    </td>
                                                    {this.state.profile.id !== league.creator && <td>
                                                        <span className="profile_action" onClick={() => this.leaveLeague(league.id)}>Quit</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "code" && <td>
                                                        <span className="profile_action" onClick={() => this.copyCode(league.code)}>{this.state.copied === league.code ? this.props.t('copied') : this.props.t('copy_link_code')}</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "invitation" && <td>
                                                        <span className="profile_action" onClick={() => window.location.href = `/league/invitations/${league.id}`}>{this.props.t('send_invitation')}</span>
                                                    </td>}
                                                </tr>
                                            </tbody>
                                        </table>
                                        :
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('active_since')}</span>
                                                    </td>
                                                    <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('participants')}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="value mt-0 mr-20">{new Date(league.createdAt).toLocaleDateString()}</span>
                                                    </td>
                                                    <td>
                                                        <span className="profile_action ml-0" onClick={() => window.location.href = `/league/participants/${league.id}`}>{league.participants.length} / <small>{league.league_limit > 0 ? league.league_limit : "unlimited"}</small></span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('fighters')}</span>
                                                    </td>
                                                    {this.state.profile.id !== league.creator && <td>
                                                        <span className="label mt-0 text-left mr-20">Exit</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "code" && <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('code')}</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "invitation" && <td>
                                                        <span className="label mt-0 text-left mr-20">{this.props.t('invitation')}</span>
                                                    </td>}
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="profile_action ml-0" onClick={() => window.location.href = `/league/fighters/${league.id}`}>{league.fighters.length} {this.props.t('fighters')}</span>
                                                    </td>
                                                    {this.state.profile.id !== league.creator && <td>
                                                        <span className="profile_action" onClick={() => this.leaveLeague(league.id)}>Quit</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "code" && <td>
                                                        <span className="profile_action" onClick={() => this.copyCode(league.code)}>{this.state.copied === league.code ? this.props.t('copied') : this.props.t('copy_link_code')}</span>
                                                    </td>}
                                                    {this.state.profile.id === league.creator && league.league_type === "invitation" && <td>
                                                        <span className="profile_action" onClick={() => window.location.href = `/league/invitations/${league.id}`}>{this.props.t('send_invitation')}</span>
                                                    </td>}
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                </div>)}
                            < div className="edit mt-40" onClick={() => window.location.href = '/create/league'}>{this.props.t('create_private_league')}</div>
                        </React.Fragment>
                        :
                        this.state.opens.map(league => this.state.profile.id !== league.creator && <div className="league" key={league.id}>
                            <div className="teaser" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/rings/svg?seed=${league.league_name}` }}></div>
                            <h2 className="mt-0"><strong className="audiowide clickable">{league.league_name.toUpperCase()}</strong>
                                <br />
                                {this.state.profile.id === league.creator && <small>{this.props.t('created_by')} {this.state.profile.username}</small>}
                                {this.state.profile.id !== league.creator && <small>{this.props.t('created_by')} {league.creator_profile.username}</small>}
                            </h2>
                            {this.state.width > 940 ?
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('active_since')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('participants')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('fighters')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">Join league</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="value mt-0 mr-20">{new Date(league.createdAt).toLocaleDateString()}</span>
                                            </td>
                                            <td>
                                                <span className="value mt-0 mr-20">{league.participants.length}</span>
                                            </td>
                                            <td>
                                                <span className="profile_action mr-20" onClick={() => window.location.href = `/league/fighters/${league.id}`}>{league.fighters.length} {this.props.t('fighters')}</span>
                                            </td>
                                            <td>
                                                <span className="profile_action" onClick={() => window.location.href = `/league/join/${league.code}`}>Join now</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('active_since')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('participants')}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="value mt-0 mr-20">{new Date(league.createdAt).toLocaleDateString()}</span>
                                            </td>
                                            <td>
                                                <span className="value mt-0 mr-20">{league.participants.length}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="label mt-10 text-left mr-20">{this.props.t('fighters')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-10 text-left mr-20">Join league</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="profile_action ml-0" onClick={() => window.location.href = `/league/fighters/${league.id}`}>{league.fighters.length} {this.props.t('fighters')}</span>
                                            </td>
                                            <td>
                                                <span className="profile_action ml-0" onClick={() => window.location.href = `/league/join/${league.code}`}>Join now</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>)
                }
                <Footer />
            </React.Fragment >
        );
    }
}

export default withTranslation()(Leagues);