import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { useParams } from 'react-router-dom';
import MobileHeader from "../../components/Menu/mobile";
import public_services from "../../services/public_services";
import token_services from "../../services/token_services";
import { QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import { ProgressBar } from "react-loader-spinner";
import { countries } from "../../components/countries";
import account_services from "../../services/account_services";
import { isMobile } from "react-device-detect";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class PublicProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            processing: true,
            predictions: [],
            profile: null,
            switch: "fighters",
            fighters: [],
            wins: 0,
            losses: 0,
            logged: false,
            followers: [],
            isFollower: false,
            isFollowed: false
        }
    }

    componentDidMount = async () => {
        const { username } = this.props.match.params
        const profile = await token_services.getUser()
        if (profile) this.setState({ logged: true })
        const data = await public_services.getProfile(username)
        this.setState({ profile: data.profile, fighters: data.fighters, wins: data.wins, losses: data.losses, followers: data.followers })
        if (this.state.logged) {
            const follow = await account_services.checkFollower(data.profile.id)
            this.setState({ isFollower: follow.follower, isFollowed: follow.followed })
        }
        const predictions = await public_services.getPredictions(data.profile.id)
        this.setState({ processing: false, predictions })
    }

    followUser = async () => {
        this.setState({ processing: true })
        await account_services.follow(this.state.profile.id)
        window.location.reload()
    }

    unfollowUser = async () => {
        this.setState({ processing: true })
        await account_services.unfollow(this.state.profile.id)
        window.location.reload()
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu />
                {this.state.processing &&
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto 10px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />}
                {!this.state.processing &&
                    <div className="big_profile">
                        {this.state.profile.avatar ?
                            <div className="avatar center_mobile" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.profile.avatar}')` }}></div>
                            :
                            <div className="avatar center_mobile" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.profile.username}')` }}></div>
                        }
                        <div className="d-inline-block">
                            <h2 className="mt-10 mb-0 center_mobile">{this.state.profile.ranking && <span className="rank_tag">#{this.state.profile.ranking}</span>} <span>{this.state.profile.username}</span></h2>
                            <div className="d-inline-block ml-10 mr-10">
                                <span className="label mt-10 text-center">{this.props.t('managing')}</span>
                                <span className="profile_action" onClick={() => this.setState({ switch: "fighters" })}>{this.state.fighters.length} {this.props.t('fighters')}</span>
                            </div>
                            <div className="d-inline-block ml-10 mr-10">
                                <span className="label mt-10 text-center">{this.props.t('score_card')}</span>
                                <span className="profile_action" onClick={() => this.setState({ switch: "predictions" })}>{this.state.wins || 0}W - {this.state.losses || 0}L</span>
                            </div>
                            <div className="d-inline-block ml-10 mr-10">
                                <span className="label mt-10 text-center">{this.props.t('followers')}</span>
                                <span className="profile_action" onClick={() => this.setState({ switch: "followers" })}>{this.state.followers.length} {this.state.followers.length > 1 ? this.props.t('followers') : this.props.t('follower')}</span>
                            </div>
                        </div>
                        <div className="d-inline-block text-center mt-30 web_f-right">
                            {this.state.isFollower ?
                                <React.Fragment>
                                    <h5 className="mt-0">{this.props.t('followed')}</h5>
                                    <span className="logout" onClick={() => this.unfollowUser()}>{this.props.t('unfollow')}</span>
                                </React.Fragment>
                                :
                                <div className="edit mt-10" onClick={() => this.state.logged ? this.followUser() : window.location.href = "/login"}>{this.props.t('follow')}</div>
                            }
                        </div>
                    </div>}

                {!this.state.processing && this.state.switch === "predictions" &&
                    <React.Fragment>
                        <h1 className="huge">{this.props.t('last_predicitons')}</h1>
                        <h4>{this.props.t('browse_passed_predictions')}</h4>
                        {!this.state.processing && this.state.switch === "predictions" && this.state.predictions.length === 0 &&
                            <div className="d-block">
                                <span className="next_lvl text-center">{this.props.t('no_prediction_from')} <strong>{this.state.profile.username}</strong> {this.props.t('yet')}</span>
                            </div>}
                        {this.state.predictions.map(prediction => prediction.fight &&
                            <div className="prono_holder" key={prediction.id}>
                                <div className="prono reduced-top text-center max-bottom">
                                    <span className={"label grey mt-30 mb-5 text-left ml-20"}><strong className="white">{prediction.event.event_name}</strong> - {prediction.fight.category} {prediction.fight.championship && "CHAMPIONSHIP"}</span>
                                    <div className={prediction.contestant === "one" ?
                                        prediction.active_status === "AWAITING" ? "left_part selected h-auto text-left" : prediction.active_status === "WIN" ? "left_part selected_win h-auto text-left" : prediction.active_status === "LOSS" ? "left_part selected_loss h-auto text-left" : "left_part selected_draw h-auto text-left"
                                        : "left_part h-auto text-left"}>
                                        {prediction.contestant === "one" && <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${prediction.fighter.visual.section}/${prediction.fighter.fighter}/${prediction.fighter.visual.visual}.png`} alt={prediction.fighter.nickname} className="fighter" />}
                                        <h3 className="mb-0 text-center">{prediction.fight.fighter_one_ranking && <small> #{prediction.fight.fighter_one_ranking}</small>} {prediction.fight.fighter_one_firstname} {prediction.fight.fighter_one_lastname} <div className={`inline-flag ${prediction.fight.fighter_one_nationality}`}></div></h3>
                                        <img
                                            src={`https://flagcdn.com/w320/${prediction.fight.fighter_one_nationality}.png`}
                                            style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                            alt={prediction.fight.fighter_one_nationality} />
                                        {prediction.fight.fighter_one_avatar &&
                                            <img className="align-left" src={prediction.fight.fighter_one_avatar.includes("https") ?
                                                prediction.fight.fighter_one_avatar
                                                : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${prediction.fight.fighter_one_avatar}`} alt="Fighter profile" />
                                        }
                                        <span className="data mt-20">
                                            <div className="pill back-coming"><strong>{prediction.fight.fighter_one_wins}W</strong></div>
                                            <div className="pill back-danger">{prediction.fight.fighter_one_losses}L</div>
                                            {prediction.fight.fighter_one_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                            {prediction.fight.winner === 'one' && <span className="winner_tag">W. <small>by {prediction.fight.victory}</small></span>} {prediction.fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}</span>
                                        <span className="data"><span className="capitalize">{this.props.t(prediction.fight.fighters)}</span>, {prediction.fight.fighter_one_age} {this.props.t('years_old')}</span>
                                        <span className="data responsive-text-left">{prediction.fight.fighter_one_martial_arts.toUpperCase()}</span>
                                        {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "AWAITING" && <div className="status back-primary">ACTIVE</div>}
                                        {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "WIN" && <div className="status back-coming">WIN</div>}
                                        {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "DRAW" && <div className="status back-black white">DRAW</div>}
                                        {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "LOSS" && <div className="status back-danger">LOSS</div>}
                                        {prediction.contestant === "one" &&
                                            <div className={prediction.active_status === "AWAITING" ? "fighter_name back-primary" : prediction.active_status === "WIN" ? "fighter_name back-coming" : prediction.active_status === "LOSS" ? "fighter_name back-danger" : "fighter_name"}><span>{prediction.points}<small>PTS</small></span> | {prediction.fighter.nickname.toUpperCase()}
                                                {(prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase())
                                                    && (prediction.fighter.nationality === prediction.fight.fighter_one_nationality)
                                                    && (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_one_martial_arts.toLowerCase()) ?
                                                    <small className={"ml-10"}>{prediction.booster ? prediction.booster === 1 ? "x3" : `x${2 + prediction.booster}` : "x2"}</small>
                                                    :
                                                    <small className={"ml-10"}>x{parseFloat(1 +
                                                        ((prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase() ? 4 : 0) +
                                                            (prediction.fighter.nationality === prediction.fight.fighter_one_nationality ? 2 : 0) +
                                                            (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_one_martial_arts.toLowerCase() ? 4 : 0)) / 10
                                                        + (prediction.booster ? prediction.booster : 0)).toFixed(1)
                                                    }</small>
                                                }
                                            </div>}
                                    </div>
                                    <div className={prediction.contestant === "one" && isMobile ? prediction.fight.fighter_one_avatar ? "vs mt-20" : "vs no_profile mt-20" : prediction.fight.fighter_one_avatar ? "vs" : "vs no_profile"}>vs</div>
                                    <div className={prediction.contestant === "two" ?
                                        prediction.active_status === "AWAITING" ? "right_part selected h-auto text-left" : prediction.active_status === "WIN" ? "right_part selected_win h-auto text-left" : prediction.active_status === "LOSS" ? "right_part selected_loss h-auto text-left" : "right_part selected_draw h-auto text-left"
                                        : "right_part h-auto text-left"}>
                                        {prediction.contestant === "two" && <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${prediction.fighter.visual.section}/${prediction.fighter.fighter}/${prediction.fighter.visual.visual}.png`} alt={prediction.fighter.nickname} className={isMobile ? "fighter" : "right_fighter"} />}
                                        <h3 className="mb-0 text-center">{prediction.fight.fighter_two_ranking && <small> #{prediction.fight.fighter_two_ranking}</small>} {prediction.fight.fighter_two_firstname} {prediction.fight.fighter_two_lastname} <div className={`inline-flag ${prediction.fight.fighter_two_nationality}`}></div></h3>
                                        <img
                                            src={`https://flagcdn.com/w320/${prediction.fight.fighter_two_nationality}.png`}
                                            style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                            alt={prediction.fight.fighter_two_nationality} />
                                        {prediction.fight.fighter_two_avatar &&
                                            <img className="align-right" src={prediction.fight.fighter_two_avatar.includes("https") ?
                                                prediction.fight.fighter_two_avatar
                                                : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${prediction.fight.fighter_two_avatar}`} alt="Fighter profile" />
                                        }
                                        <span className="data responsive-text-right mt-20">
                                            {prediction.fight.fighter_two_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                            <div className="pill back-coming"><strong>{prediction.fight.fighter_two_wins}W</strong></div>
                                            <div className="pill back-danger">{prediction.fight.fighter_two_losses}L</div>
                                            {prediction.fight.winner === 'two' && <span className="winner_tag">W. <small>by {prediction.fight.victory}</small></span>} {prediction.fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}
                                        </span>
                                        <span className="data responsive-text-right"><span className="capitalize">{this.props.t(prediction.fight.fighters)}</span>, {prediction.fight.fighter_two_age} {this.props.t('years_old')}</span>
                                        <span className="data responsive-text-right">{prediction.fight.fighter_two_martial_arts.toUpperCase()}</span>
                                        {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "AWAITING" && <div className="status back-primary">ACTIVE</div>}
                                        {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "WIN" && <div className="status back-coming">WIN</div>}
                                        {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "DRAW" && <div className="status back-black white">DRAW</div>}
                                        {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "LOSS" && <div className="status back-danger">LOSS</div>}
                                        {prediction.contestant === "two" &&
                                            <div className={prediction.active_status === "AWAITING" ? "fighter_name back-primary" : prediction.active_status === "WIN" ? "fighter_name back-coming" : prediction.active_status === "LOSS" ? "fighter_name back-danger" : "fighter_name"}><span>{prediction.points}<small>PTS</small></span> | {prediction.fighter.nickname.toUpperCase()}
                                                {(prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase())
                                                    && (prediction.fighter.nationality === prediction.fight.fighter_two_nationality)
                                                    && (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_two_martial_arts.toLowerCase()) ?
                                                    <small className={"ml-10"}>{prediction.booster ? prediction.booster === 1 ? "x3" : `x${2 + prediction.booster}` : "x2"}</small>
                                                    :
                                                    <small className={"ml-10"}>x{parseFloat(1 +
                                                        ((prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase() ? 4 : 0) +
                                                            (prediction.fighter.nationality === prediction.fight.fighter_two_nationality ? 2 : 0) +
                                                            (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_two_martial_arts.toLowerCase() ? 4 : 0)) / 10
                                                        + (prediction.booster ? prediction.booster : 0)).toFixed(1)
                                                    }</small>}
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>}
                {!this.state.processing && this.state.switch === "fighters" &&
                    <React.Fragment>
                        <h1 className="huge">{this.props.t('fighters').toUpperCase()}</h1>
                        <h4>{this.props.t('browse')} <strong>{this.state.profile.username}</strong> {this.props.t('owned_fighters').toLowerCase()}</h4>
                        {!this.state.processing && this.state.switch === "fighters" && this.state.fighters.length === 0 &&
                            <div className="d-block">
                                <span className="next_lvl text-center">{this.props.t('no_fighter_from')} <strong>{this.state.profile.username}</strong> :/</span>
                            </div>}
                        <div className="max_holder">
                            {this.state.fighters.map(fighter => fighter &&
                                <div key={fighter.id} className="card card-fighter center_top">
                                    <div className="info text-left mobile-h-auto">
                                        <h5><small>{fighter.visual.weightclass.toUpperCase()}</small></h5>
                                        <span className="label">{this.props.t('martial_art')}</span>
                                        <span className="value">{fighter.martial_art.toUpperCase()}</span>
                                        <span className="label">{this.props.t('fullname')}</span>
                                        <span className="value">
                                            {fighter.firstname} {fighter.lastname}
                                            <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div>
                                        </span>
                                        <span className="label">{this.props.t('nickname')}</span>
                                        <span className="value">{fighter.nickname.toLowerCase()}</span>
                                        <h5>
                                            {this.props.t('level')} {fighter.fighter_level} <span className="ml-10 ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                            {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                        </h5>
                                        <div className="lvl_holder">
                                            <div className="lvl back-coming" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                        </div>
                                        <span className="next_lvl">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                        {fighter.visual.quantity === 10 ?
                                            <div className="rarity_tag back-premium">PREMIUM FIGHTER</div>
                                            : fighter.visual.quantity === 100 ?
                                                <div className="rarity_tag back-coming">RARE FIGHTER</div>
                                                : fighter.visual.quantity === 1 ?
                                                    <div className="rarity_tag back-danger">UNIQUE FIGHTER</div>
                                                    :
                                                    fighter.visual.quantity === 1000 ?
                                                        <div className="rarity_tag back-common">COMMON FIGHTER</div>
                                                        :
                                                        <div className="rarity_tag">STARTER FIGHTER</div>}
                                    </div>
                                    <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.png`} alt={fighter.nickname.toLowerCase()} className={isMobile ? "visual" : "visual scale_max"} />
                                </div>)}
                        </div>
                    </React.Fragment>
                }
                {!this.state.processing && this.state.switch === "followers" &&
                    <React.Fragment>
                        <h1 className="huge">{this.props.t('followers').toUpperCase()}</h1>
                        {this.state.followers.length === 0 ?
                            <h4>{this.props.t('no_follower')}</h4>
                            :
                            <h4>{this.props.t('browse_followers')}</h4>
                        }
                        <div className="follower">
                            {this.state.followers.map(follower =>
                                <div className="profile pointer" key={follower.follower.id} onClick={() => window.location.href = `/profile/${follower.follower.username}`}>
                                    {follower.follower.avatar ?
                                        <div className="avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${follower.follower.avatar}')` }}></div>
                                        :
                                        <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${follower.follower.username}')` }}></div>
                                    }
                                    <div className="info_profile">
                                        <big>{follower.follower.username}</big>
                                    </div>
                                    <div className="view">
                                        {this.props.t('view')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>}
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(PublicProfile));