import api from './api';

class PublicService {
  async getRankedUsers() {
    return await api
      .get(`/public/ranked/users`)
      .then(async (response) => {
        return response.data.users;
      });
  }
  async getEventRankedUsers(id) {
    return await api
      .get(`/public/ranked/event/${id}`)
      .then(async (response) => {
        return response.data.users;
      });
  }

  async getProfile(username) {
    return await api
      .get(`/public/profile/${username}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getPredictions(id) {
    return await api
      .get(`/public/predictions/${id}`)
      .then(async (response) => {
        return response.data.predictions;
      });
  }

  async searchProfile(username) {
    return await api
      .get(`/public/search/users/${username}`)
      .then(async (response) => {
        return response.data.profiles;
      });
  }

  async searchFighters(nickname) {
    return await api
      .get(`/public/search/fighters/${nickname}`)
      .then(async (response) => {
        return response.data.fighters;
      });
  }

  async leagueCode(code) {
    return await api
      .get(`/public/league/${code}`)
      .then(async (response) => {
        return response.data.league;
      });
  }

  async leagueInvitationCode(code) {
    return await api
      .get(`/public/league/invitation/${code}`)
      .then(async (response) => {
        return response.data.league;
      });
  }

  async leagueParticipants(id) {
    return await api
      .get(`/public/league/participants/${id}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async leagueFighters(id) {
    return await api
      .get(`/public/league/fighters/${id}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getPublicFighter(id) {
    return await api
      .get(`/public/fighter/${id}`)
      .then(async (response) => {
        return response.data.fighter;
      });
  }

  async getBoosters() {
    return await api
      .get(`/public/boosters`)
      .then(async (response) => {
        return response.data.boosters;
      });
  }

  async getNews() {
    return await api
      .get(`/public/news`)
      .then(async (response) => {
        return response.data.news;
      });
  }
}

// eslint-disable-next-line
export default new PublicService();