import api from './api';

class LeagueService {
    async createNewLeague(league) {
      return await api
        .post('/league/new', league)
        .then(async (response) => {
          return response.data.status;
        });
    }

    async created() {
      return await api
        .get('/league/created')
        .then(async (response) => {
          return response.data.leagues;
        });
    }

    async joined() {
      return await api
        .get('/league/joined')
        .then(async (response) => {
          return response.data.leagues;
        });
    }

    async participation(league) {
      return await api
        .get(`/league/participant/${league}`)
        .then(async (response) => {
          return response.data.participant;
        });
    }

    async joinLeague(league) {
      return await api
        .get(`/league/join/${league}`)
        .then(async (response) => {
          return response.data.status;
        });
    }

    async leaveLeague(league) {
      return await api
        .get(`/league/unsign/${league}`)
        .then(async (response) => {
          return response.data.status;
        });
    }

    async joinInvitationLeague(code) {
      return await api
        .get(`/league/invit/join/${code}`)
        .then(async (response) => {
          return response.data.status;
        });
    }

    async sendInvitation(league, email) {
      return await api
        .post(`/league/invit/${league}`, {email})
        .then(async (response) => {
          return response.data.status;
        });
    }

    async getInvitations(league) {
      return await api
        .get(`/league/invits/${league}`)
        .then(async (response) => {
          return response.data;
        });
    }

    async getOpenLeagues() {
      return await api
        .get(`/league/opens`)
        .then(async (response) => {
          return response.data.leagues;
        });
    }
}

// eslint-disable-next-line
export default new LeagueService();