import "./menu.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

// Media
import LOGO from "../../assets/img/mma_goat_logo_white.png"
import token_services from "../../services/token_services";
import { NotificationContainer, NotificationManager } from "react-notifications";
import admin_services from "../../services/admin_services";

class MenuAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            profile: null,
        }
    }

    componentDidMount = async () => {
        const profile = await token_services.getUser()
        if (profile && profile.account && profile.account.admin) this.setState({ profile: profile.account })
        else window.location.href = "/admin/auth"
    }

    updateRanking = async () => {
        NotificationManager.info("Processing users ranking... Please wait")
        await admin_services.rankUsers()
        NotificationManager.success("Sucessfully ranked all users")
        NotificationManager.info("Processing fighters ranking... Please wait")
        await admin_services.rankFighters()
        NotificationManager.success("Sucessfully ranked all fighters")
    }

    render() {
        return (
            <div className="menu_holder">
                <div className="menu">
                    <img src={LOGO} alt="MMA legends logo" className="logo" onClick={() => window.location.href = "/"} />
                    <div className={this.props.active === "events" ? "entry active" : "entry"} onClick={() => window.location.href = "/admin/events"}>
                        Events
                    </div>
                    <div className={this.props.active === "subscribers" ? "entry active" : "entry"} onClick={() => window.location.href = "/admin/subscribers"}>
                        Subscribers
                    </div>
                    <div className={this.props.active === "users" ? "entry active" : "entry"} onClick={() => window.location.href = "/admin/users"}>
                        Users
                    </div>
                    <div className={this.props.active === "visuals" ? "entry active" : "entry"} onClick={() => window.location.href = "/admin/visuals"}>
                        Visuals
                    </div>
                    <div className="entry" onClick={this.updateRanking}>
                        Rank
                    </div>
                    {this.state.profile &&
                        <div className="profile hide_mobile" onClick={() => window.location.href = "/admin/profile"}>
                            <div className="info_profile">
                                <strong>{this.state.profile.username}</strong>
                                <br />
                                <small>ADMIN</small>
                            </div>
                            <div className="avatar" style={{ backgroundImage: `url(https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.profile.username})` }}></div>
                        </div>
                    }
                    <div className="mobile" onClick={() => this.setState({ toggle: !this.state.toggle })}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    {this.state.toggle && <div className="pop_mobile">
                        <div className={this.props.active === "predictions" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/admin/events"}>
                            Events
                        </div>
                        <div className={this.props.active === "subscribers" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/admin/subscribers"}>
                            Subscribers
                        </div>
                        <div className={this.props.active === "users" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/admin/users"}>
                            Users
                        </div>
                        <div className={this.props.active === "visuals" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/admin/visuals"}>
                            Visuals
                        </div>
                        <div className="pop_entry" onClick={this.updateRanking}>
                            Rank
                        </div>
                        {this.state.profile &&
                            <div className="profile d-block" onClick={() => window.location.href = "/admin/profile"}>
                                <div className="info_profile">
                                    <strong>{this.state.profile.username}</strong>
                                    <br />
                                    <small>ADMIN</small>
                                </div>
                                <div className="avatar" style={{ backgroundImage: `url(https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.username})` }}></div>
                            </div>
                        }
                    </div>}
                </div>
                <NotificationContainer />
            </div>
        );
    }
}

export default withTranslation()(MenuAdmin);