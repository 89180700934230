import { API_URI } from '../constants.js';
import axios from 'axios';
import TokenService from './token_services';

const instance = axios.create({
  baseURL: API_URI,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token = await TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig && originalConfig.url !== '/login' && originalConfig.url !== '/register' && err.response) {
      // JWT Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        TokenService.removeUser();
      }else
        console.warn(err.response)
    }
    return Promise.reject(err);
  }
);

export default instance;