import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MenuAdmin from '../../components/Menu/admin';
import Footer from "../../components/Footer";

// Media
import FIGHTERM01PNG from "../../assets/img/fighter_m08.png"
import token_services from "../../services/token_services";
import { QWRTX_MMA_GOAT_PLAYERS } from "../../constants";

class AdminProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            loading: true,
            profile: {},
        }
    }

    componentDidMount = async () => {
        const profile = await token_services.getUser()
        if (profile) this.setState({ profile: profile.account })
        this.setState({ loading: false })
    }

    render() {
        return (
            <React.Fragment>
                <MenuAdmin/>
                <div className="big_profile">
                    {this.state.profile.avatar ?
                        <div className="avatar" style={{ backgroundImage: `url(https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.profile.avatar})` }}></div>
                        :
                        <div className="avatar" style={{ backgroundImage: `url(https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.profile.username})` }}></div>
                    }
                    <div className="d-inline-block">
                        <span className="label mt-0">Active since 2023</span>
                        <h2 className="mt-0">{this.state.profile.username}</h2>
                    </div>
                    <div className="d-inline-block text-center mt-30 web_f-right">
                        <span className="logout" onClick={() => token_services.removeUser()}>Logout</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation()(AdminProfile);