class TokenService {
  async getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.refresh_token;
  }

  async getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.access_token;
  }

  async updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.access_token = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  async setRefreshToken(token) {
    let user = JSON.parse(localStorage.getItem('user'));
    user.refresh_token = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  async getUser() {
    if (localStorage.getItem('user')) return JSON.parse(localStorage.getItem('user'));
    else return null;
  }

  async setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  async removeUser() {
    localStorage.removeItem('user');
    window.location.href = '/login';
  }
}

// eslint-disable-next-line
export default new TokenService();