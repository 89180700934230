import api from './api';
import TokenService from './token_services';

class AccountService {
  async getAccount() {
    return await api
      .get(`/account/`)
      .then(async (response) => {
        return response.data.account;
      });
  }
  async getProfile() {
    return await api
      .get(`/account/profile`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getEventFighters(event_id) {
    return await api
      .get(`/account/fighters/${event_id}`)
      .then(async (response) => {
        return response.data.fighters;
      });
  }

  async createNewFighter(fighter) {
    return await api
      .post('/account/create/fighter', fighter)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async count() {
    return await api
      .get('/account/count')
      .then(async (response) => {
        return response.data;
      });
  }

  async updateEmail(email) {
    return await api
      .post('/account/update/email', {email})
      .then(async (response) => {
        if (response.data.access_token) {
          await TokenService.setUser(response.data);
        }
        return response.data.status;
      });
  }

  async updateUsername(username) {
    return await api
      .post('/account/update/username', {username})
      .then(async (response) => {
        if (response.data.access_token) {
          await TokenService.setUser(response.data);
        }
        return response.data.status;
      });
  }

  async updateAvatar(avatar) {
    return await api
      .post('/account/update/avatar', {avatar})
      .then(async (response) => {
        if (response.data.access_token) {
          await TokenService.setUser(response.data);
        }
        return response.data.status;
      });
  }

  async updatePassword(email, old_password, new_password) {
    return await api
      .post('/account/update/password', {email, old_password, new_password})
      .then(async (response) => {
        return response.data.status;
      });
  }

  async follow(id) {
    return await api
      .get(`/account/follow/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async unfollow(id) {
    return await api
      .get(`/account/unfollow/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async checkFollower(id) {
    return await api
      .get(`/account/check/follower/${id}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getInvitations() {
    return await api
      .get(`/account/invits`)
      .then(async (response) => {
        return response.data;
      });
  }

  async inviteEmail(email) {
    return await api
      .post('/account/invite', { email })
      .then(async (response) => {
        return response.data;
      });
  }

  async getPendingDistributions() {
    return await api
      .get(`/account/distributions/pending`)
      .then(async (response) => {
        return response.data.distributions;
      });
  }

  async getPendingDistribution(id) {
    return await api
      .get(`/account/distributions/pending/${id}`)
      .then(async (response) => {
        return response.data.distribution;
      });
  }

  async getFighterDetails(id) {
    return await api
      .get(`/account/fighter/${id}`)
      .then(async (response) => {
        return response.data.fighter;
      });
  }

  async getWhatsnew() {
    return await api
      .get(`/account/news`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getReferrals() {
    return await api
      .get(`/account/referrals`)
      .then(async (response) => {
        return response.data;
      });
  }

  async unlockFighter(visual_id) {
    return await api
      .post('/account/unlock/fighter', { visual_id })
      .then(async (response) => {
        return response.data;
      });
  }

  async purchaseFighter(visual_id) {
    return await api
      .post('/account/purchase/fighter', { visual_id })
      .then(async (response) => {
        return response.data;
      });
  }

  async rmAccount() {
    return await api
      .get('/account/delete')
      .then(async (response) => {
        return response.data.status;
      });
  }
}

// eslint-disable-next-line
export default new AccountService();