import "./footer.css"
import React, { Component } from 'react';
import i18next from "i18next";
import { withTranslation } from 'react-i18next';
import { FaInstagram } from "react-icons/fa";
import validator from 'validator';
import token_services from "../../services/token_services";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "../../constants";
import auth_services from "../../services/auth_services";
import { ProgressBar } from "react-loader-spinner";
import { NotificationContainer, NotificationManager } from "react-notifications";

// Media
import LOGOWHITE from "../../assets/img/mma_goat_logo_white.png"
import { isMobile } from "react-device-detect";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            captcha: null,
            checked: false,
            lg: i18next.language,
            isWebview: true,
        }
    }

    componentDidMount = async () => {
        this.isWebview()
        const profile = await token_services.getUser()
        if (profile) this.setState({ profile: profile.account })
    }

    onChange = (value) => {
        this.setState({ captcha: value })
    }

    subscribe = async () => {
        if (!this.state.email || !validator.isEmail(this.state.email))
            NotificationManager.warning('Incorrect format for email')
        else if (!this.state.checked)
            NotificationManager.warning('Please agree to receive upcoming news and participate in Beta program draft from MMA GOAT')
        else if (!this.state.captcha)
            NotificationManager.warning('Please validate the reCaptcha before submission')
        else {
            try {
                this.setState({ processing: true })
                const resp = await auth_services.subscribe(
                    this.state.email,
                    this.state.captcha,
                )
                if (resp.status) {
                    this.setState({ processing: false, subscribed: true })
                } else {
                    NotificationManager.error('Could not subscribe')
                }
                this.setState({ processing: false })
            } catch (e) {
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data.data) : e.message)
                this.setState({ processing: false })
            }
        }
    }

    isWebview = () => {
        const navigator = window.navigator
        const userAgent = navigator.userAgent
        const normalizedUserAgent = userAgent.toLowerCase()
        const standalone = navigator.standalone
        const isIos =
            /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
        const isAndroid = /android/.test(normalizedUserAgent)
        const isSafari = /safari/.test(normalizedUserAgent)
        const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)
        this.setState({ isWebview })
    }

    // Change UI language
    switchLanguage = (lg) => {
        i18next.changeLanguage(lg)
        this.setState({ lg })
        localStorage.setItem("i18nextLng", lg)
    }

    render() {
        return (
            (isMobile && !this.props.display) || this.state.isWebview?
                <div style={{ height: "100px" }}></div>
                :
                <footer>
                    <img src={LOGOWHITE} className="logo" alt="MMA GOAT logo" />
                    <div className="rights">
                        {this.props.t('all_rights_reserved')} 2023.
                    </div>
                    <div className="terms_holder">
                        <span className="terms" onClick={() => window.location.href = "/terms"}>{this.props.t('cgu')}</span>
                        <span className="terms" onClick={() => window.location.href = "/legal"}>{this.props.t('legal')}</span>
                        {this.state.lg === "en" ?
                            <span className="terms" onClick={() => this.switchLanguage('fr')}>FR</span>
                            :
                            <span className="terms" onClick={() => this.switchLanguage('en')}>EN</span>
                        }
                    </div>
                    <div className="list_holder">
                        <span className="entry" onClick={() => window.location.href = "/events"}>{this.props.t('events')}</span>
                        <span className="entry" onClick={() => window.location.href = "/fighters"}>{this.props.t('fighters')}</span>
                        <span className="entry" onClick={() => window.location.href = "/rankings"}>{this.props.t('rankings')}</span>
                        <span className="entry" onClick={() => window.location.href = "/store"}>{this.props.t('store')}</span>
                        <span className="entry" onClick={() => window.open("https://whitepaper.mma-goat.com/")}>Whitepaper</span>
                        {this.state.profile ?
                            <span className="entry" onClick={() => window.location.href = "/profile"}>{this.props.t('profile')}</span>
                            :
                            <span className="entry" onClick={() => window.location.href = "/login"}>{this.props.t('sign_in')}</span>
                        }
                    </div>
                    <div className="newsletter">
                        {!this.state.subscribed ?
                            <React.Fragment>
                                <div className="title">
                                    {this.props.t('subscribe_newsletter')}
                                </div>
                                <input type="email" className="email back-white" placeholder={this.props.t('placeholder_email')} onChange={(e) => this.setState({ email: e.target.value })} />
                                <div className="tagline">
                                    <input type="checkbox" onChange={() => this.setState({ checked: !this.state.checked })} checked={this.state.checked} /> {this.props.t('agree_upcoming_news')} <strong>MMA GOAT</strong>
                                </div>
                                <div className="captcha" style={{ transform: "scale(0.75)", transformOrigin: "0 0", margin: "8px 20px 0 8px" }}>
                                    <ReCAPTCHA
                                        theme={isMobile ? "dark" : "light"}
                                        sitekey={SITE_KEY}
                                        onChange={this.onChange}
                                    />
                                </div>
                                {!this.state.processing && !this.state.success && <div className="subscribe mt-0" onClick={this.subscribe}>
                                    Subscribe
                                </div>}
                                {this.state.processing &&
                                    <ProgressBar
                                        height="80"
                                        width="80"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{ margin: '-25px 20px' }}
                                        wrapperClass="progress-bar-wrapper"
                                        borderColor='#000'
                                        barColor='#000'
                                    />
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <h2>{this.props.t('subscribed')}</h2>
                                <span className="label">
                                    {this.props.t('email_sent')} <strong>{this.props.t('thank_you_note')}</strong>
                                </span>
                            </React.Fragment>
                        }
                    </div>
                    <div className="social_holder">
                        <span className="social" onClick={() => window.open("https://www.instagram.com/mmagoatgame/")}><FaInstagram /></span>
                    </div>
                    <NotificationContainer />
                </footer>
        );
    }
}

export default withTranslation()(Footer);