import "../../components/flags.css";
// eslint-disable-next-line
import Entry from "./entry";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_EVENTS } from "../../constants";
import events_services from "../../services/events_services";
import { ProgressBar } from "react-loader-spinner";
import account_services from "../../services/account_services";
import public_services from "../../services/public_services";
import MobileHeader from "../../components/Menu/mobile";
import DISCORD from "../../assets/img/discord_logo.png";

class Wall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            processing: true,
            fighters: [],
            news: [],
            bestPlayers: [],
            lastEvent: null,
            maleFighter: null,
            femaleFighter: null,
        }
    }

    componentDidMount = async () => {
        document.title = "What's new on MMA-GOAT"
        this.loadNews()
        let events = await events_services.getAllEvents()
        let data = await account_services.getWhatsnew()
        this.setState({ events, processing: false, bestPlayers: data.best_players, lastEvent: data.event })
    }

    loadNews = async () => {
        const news = await public_services.getNews()
        console.log(news)
        this.setState({ news, processing: false })
    }

    dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY) ?
            Math.floor((utc2 - utc1) / _MS_PER_DAY) === 1 ? 'Yesterday'
                :
                `${Math.floor((utc2 - utc1) / _MS_PER_DAY)} days ago`
            : 'Today';
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu />
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="max_holder">
                        <div className="discord" onClick={() => window.open('https://discord.gg/ub8yxDfjjE')}>
                            <img src={DISCORD} className="discord_logo" alt="Discord"/>
                            <div className="discord_join">
                                Join our discord
                                <span>Let's meet and discuss MMA fights</span>
                            </div>
                        </div>
                        <h1 className="huge starter">{this.props.t('upcoming_events')}</h1>
                        <h4 className="mb-0">{this.props.t('dont_miss_events')}</h4>
                        {this.state.events.map(event => new Date(event.event_date) > new Date() &&
                            <div className="card_event card-fighter pointer d-inline-block ml-10 mr-10 mt-10 mb-20" onClick={() => window.location.href = `/event/${event.id}`} key={event.id} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${event.poster}')`, backgroundSize: 'cover' }}>
                            </div>)}
                        {/* <h1 className="huge">MMA News</h1>
                        <div className="max_holder full text-left">
                            {this.state.news.length === 0 &&
                                <p><strong>{this.props.t('no_news')}</strong></p>
                            }
                            <div className="ranking_container">
                                {this.state.news.map((entry, index) => <Entry entry={entry} key={`t_${index}`} profile={this.state.profile} loadNews={this.loadNews} />)}
                            </div>
                        </div> */}
                    </div>
                }
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Wall);