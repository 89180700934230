import "../../components/flags.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import predictions_services from "../../services/predictions_services"
import { withTranslation } from 'react-i18next'
import { OPTIONS, QWRTX_MMA_GOAT_FIGHTERS } from "../../constants"
import { ProgressBar } from "react-loader-spinner"
import { countries } from "../../components/countries"
import { FaChartBar, FaChevronDown, FaUserAlt } from "react-icons/fa"
import { isMobile } from "react-device-detect"

// Media
import GOATCOIN from "../../assets/img/goat_coin.png"

class FighterItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switch: isMobile ? "Profile" : "Predictions",
            export: false,
            display: false,
        }
        this.fighterRef = React.createRef();
    }

    cancelPrediction = async (id) => {
        this.setState({ processing: true })
        try {
            await predictions_services.cancelPrediction(id)
            window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"action": "cancel_prediction"}))// SEND message in canal 
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
        }
        this.setState({ processing: false })
        this.props.loadProfile()
    }

    withdraw = async () => {
        window.location.href = `/withdraw/${this.props.fighter.id}`
    }

    componentDidMount = () => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("unlock") && query.get("unlock") === "withdraw")
            this.setState({ display: true })
    }

    render() {
        return (
            <div>
                {this.state.export && <div className="loader">
                    <h1 className="huge starter">DOWNLOADING FIGHTER</h1>
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '8vh auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                </div>}
                {!this.state.export &&
                    <div className="card card-fighter center_top">
                        {this.state.switch === "Profile" ?
                            <div className="info text-left">
                                <div className="actionbox">
                                    {isMobile && <div className={this.state.switch === "Profile" ? "export " : "export back-black"} onClick={() => this.setState({ switch: "Predictions" })}>
                                        <FaUserAlt />
                                    </div>}
                                    {isMobile && <div className={this.state.switch === "Predictions" ? "export " : "export back-black"} style={{ left: "110px" }} onClick={() => this.setState({ switch: "Profile" })}>
                                        <FaChartBar />
                                    </div>}
                                    {this.state.display && <div className="export" title="Withdraw fighter" onClick={this.withdraw}>
                                        <FaChevronDown />
                                    </div>}
                                </div>
                                <h5>{this.props.t('active_predictions')}</h5>
                                {this.props.fighter.predictions.length > 0 ?
                                    this.props.fighter.predictions.map(prediction =>
                                        <React.Fragment key={prediction.id}>
                                            <hr />
                                            <p className="mb-0 text-left"><strong>{prediction.event.event_name}</strong></p>
                                            <span className="vs_fighters text-left">
                                                <small>
                                                    <span className="capitalize">{new Date(prediction.event.event_date).toLocaleDateString(undefined, OPTIONS)}</span>, {new Date(prediction.event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                </small>
                                            </span>
                                            {prediction.contestant === 'one' ?
                                                <span className="vs_fighters text-left">
                                                    <strong className="primary">{prediction.fight.fighter_one_firstname} {prediction.fight.fighter_one_lastname}</strong> vs. {prediction.fight.fighter_two_firstname} {prediction.fight.fighter_two_lastname} {this.props.t('by')} <strong className="primary">{prediction.victory}</strong> {prediction.booster && <small>[Booster <strong className="primary">{prediction.booster * 100}%</strong>]</small>}
                                                </span> : <span className="vs_fighters text-left">
                                                    {prediction.fight.fighter_one_firstname} {prediction.fight.fighter_one_lastname} vs. <strong className="primary">{prediction.fight.fighter_two_firstname} {prediction.fight.fighter_two_lastname}</strong> {this.props.t('by')} <strong className="primary">{prediction.victory}</strong> {prediction.booster && <small>[Booster <strong className="primary">{prediction.booster * 100}%</strong>]</small>}
                                                </span>}
                                            <div className="awaiting_score">
                                                <strong className="ml-10">{prediction.points}</strong> <small>+</small> <small>20 PTS</small> <span className="ft-11">{this.props.t('awaiting')}</span>
                                            </div>
                                            <div className="notice_board">
                                                {prediction.contestant === 'one' ?
                                                    <small className="min_notice text-left">{this.props.t('unlock_if')} <strong>{prediction.fight.fighter_one_firstname.substring(0, 1)}. {prediction.fight.fighter_one_lastname} {this.props.t('wins')}</strong></small>
                                                    :
                                                    <small className="min_notice text-left">{this.props.t('unlock_if')} <strong>{prediction.fight.fighter_two_firstname.substring(0, 1)}. {prediction.fight.fighter_two_lastname} {this.props.t('wins')}</strong></small>
                                                }
                                                {prediction.contestant === 'one' ?
                                                    <small className="min_notice text-left">{this.props.t('extra_unlock_if')} <strong>{prediction.fight.fighter_one_firstname.substring(0, 1)}. {prediction.fight.fighter_one_lastname} {this.props.t('wins_by')} {prediction.victory}</strong></small>
                                                    :
                                                    <small className="min_notice text-left">{this.props.t('extra_unlock_if')} <strong>{prediction.fight.fighter_two_firstname.substring(0, 1)}. {prediction.fight.fighter_two_lastname} {this.props.t('wins_by')} {prediction.victory}</strong></small>
                                                }
                                                <small className="min_notice text-left">{this.props.t('pick_right')} <strong>{this.props.t('same_amount')}</strong> <img src={GOATCOIN} alt="GOAT coin" width={"10px"} /> {this.props.t('fighters_and_boosters')}</small>
                                            </div>
                                            {this.state.processing ?
                                                <ProgressBar
                                                    height="80"
                                                    width="80"
                                                    ariaLabel="progress-bar-loading"
                                                    wrapperStyle={{ margin: '10px 0' }}
                                                    wrapperClass="progress-bar-wrapper"
                                                    borderColor='#000'
                                                    barColor='#000'
                                                />
                                                :
                                                (new Date(prediction.event.event_date) > new Date()) && <div className="cancel" onClick={() => this.cancelPrediction(prediction.id)}>{this.props.t('Cancel')}</div>
                                            }
                                        </React.Fragment>)
                                    :
                                    <React.Fragment>
                                        <span className="next_lvl text-center">{this.props.t('no_prediction')}</span>
                                        <div className="edit" onClick={() => window.location.href = "/events"}>
                                            {this.props.t('predict')}
                                        </div>
                                    </React.Fragment>}
                            </div>
                            :
                            <div className="info text-left">
                                <div className="actionbox">
                                    {isMobile && <div className={this.state.switch === "Profile" ? "export " : "export back-black"} onClick={() => this.setState({ switch: "Predictions" })}>
                                        <FaUserAlt />
                                    </div>}
                                    {isMobile && <div className={this.state.switch === "Predictions" ? "export " : "export back-black"} style={{ left: "110px" }} onClick={() => this.setState({ switch: "Profile" })}>
                                        <FaChartBar />
                                    </div>}
                                    {this.state.display && <div className="export" title="Withdraw fighter" onClick={this.withdraw}>
                                        <FaChevronDown />
                                    </div>}
                                </div>
                                <h5><small>{this.props.fighter.visual.weightclass.toUpperCase()}</small></h5>
                                <span className="label">{this.props.t('martial_art')}</span>
                                <span className="value">{this.props.fighter.martial_art.toUpperCase()}</span>
                                <span className="label">{this.props.t('fullname')}</span>
                                <span className="value">
                                    {this.props.fighter.firstname} {this.props.fighter.lastname}
                                    <div className={`flag ${this.props.fighter.nationality}`} title={countries[this.props.fighter.nationality.toUpperCase()]}></div>
                                </span>
                                <span className="label">{this.props.t('nickname')}</span>
                                <span className="value">{this.props.fighter.nickname.toLowerCase()}</span>
                                <h5>
                                    {this.props.t('level')} {this.props.fighter.fighter_level} <span className="ml-10 ft-14">{this.props.fighter.wins || 0}W - {this.props.fighter.losses || 0}L</span>
                                    {this.props.fighter.wins && (this.props.fighter.losses === 0) && this.props.fighter.wins > 0 ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                </h5>
                                <div className="lvl_holder">
                                    {this.props.fighter.predictions.length > 0 ?
                                        <div className="lvl back-primary" style={{ width: `${100 * (this.props.fighter.fighter_level * 10 + 120 - this.props.fighter.next_level) / (this.props.fighter.fighter_level * 10 + 120)}%` }}></div>
                                        :
                                        <div className="lvl back-coming" style={{ width: `${100 * (this.props.fighter.fighter_level * 10 + 120 - this.props.fighter.next_level) / (this.props.fighter.fighter_level * 10 + 120)}%` }}></div>}
                                </div>
                                <span className="next_lvl">{this.props.t('next_level')}  {this.props.fighter.next_level} points</span>
                                {this.props.fighter.visual.quantity === 10 ?
                                    <div className="rarity_tag back-premium">PREMIUM FIGHTER</div>
                                    : this.props.fighter.visual.quantity === 100 ?
                                        <div className="rarity_tag back-coming">RARE FIGHTER</div>
                                        : this.props.fighter.visual.quantity === 1 ?
                                            <div className="rarity_tag back-danger">UNIQUE FIGHTER</div>
                                            :
                                            this.props.fighter.visual.quantity === 1000 ?
                                                <div className="rarity_tag back-common">COMMON FIGHTER</div>
                                                :
                                                <div className="rarity_tag">STARTER FIGHTER</div>}
                            </div>}
                        <div className="info info_right text-center zone-prono">
                            <div className="reset_line">
                                <h5>{this.props.t('active_predictions')}</h5>
                                {this.props.fighter.predictions.length > 0 ?
                                    this.props.fighter.predictions.map(prediction =>
                                        <React.Fragment key={prediction.id}>
                                            <hr />
                                            <p className="mb-0 text-left">
                                                <strong>{prediction.event.event_name}</strong>
                                            </p>
                                            <span className="vs_fighters text-left">
                                                <small>
                                                    <span className="capitalize">{new Date(prediction.event.event_date).toLocaleDateString(undefined, OPTIONS)}</span>, {new Date(prediction.event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                </small>
                                            </span>
                                            {prediction.contestant === 'one' ?
                                                <span className="vs_fighters text-left">
                                                    <strong className="primary">{prediction.fight.fighter_one_firstname} {prediction.fight.fighter_one_lastname}</strong> vs. {prediction.fight.fighter_two_firstname} {prediction.fight.fighter_two_lastname} {this.props.t('by')} <strong className="primary">{prediction.victory}</strong> {prediction.booster && <small>[Booster <strong className="primary">{prediction.booster * 100}%</strong>]</small>}
                                                </span> : <span className="vs_fighters text-left">
                                                    {prediction.fight.fighter_one_firstname} {prediction.fight.fighter_one_lastname} vs. <strong className="primary">{prediction.fight.fighter_two_firstname} {prediction.fight.fighter_two_lastname}</strong> {this.props.t('by')} <strong className="primary">{prediction.victory}</strong> {prediction.booster && <small>[Booster <strong className="primary">{prediction.booster * 100}%</strong>]</small>}
                                                </span>}
                                            <div className="awaiting_score">
                                                <strong className="ml-10">{prediction.points}</strong> <small>+</small> <small>20 PTS</small> <span className="ft-11">{this.props.t('awaiting')}</span>
                                            </div>
                                            <div className="notice_board">
                                                {prediction.contestant === 'one' ?
                                                    <small className="min_notice text-left">{this.props.t('unlock_if')} <strong>{prediction.fight.fighter_one_firstname.substring(0, 1)}. {prediction.fight.fighter_one_lastname} {this.props.t('wins')}</strong></small>
                                                    :
                                                    <small className="min_notice text-left">{this.props.t('unlock_if')} <strong>{prediction.fight.fighter_two_firstname.substring(0, 1)}. {prediction.fight.fighter_two_lastname} {this.props.t('wins')}</strong></small>
                                                }
                                                {prediction.contestant === 'one' ?
                                                    <small className="min_notice text-left">{this.props.t('extra_unlock_if')} <strong>{prediction.fight.fighter_one_firstname.substring(0, 1)}. {prediction.fight.fighter_one_lastname} {this.props.t('wins_by')} {prediction.victory}</strong></small>
                                                    :
                                                    <small className="min_notice text-left">{this.props.t('extra_unlock_if')} <strong>{prediction.fight.fighter_two_firstname.substring(0, 1)}. {prediction.fight.fighter_two_lastname} {this.props.t('wins_by')} {prediction.victory}</strong></small>
                                                }
                                                <small className="min_notice text-left">{this.props.t('pick_right')} <strong>{this.props.t('same_amount')}</strong> <img src={GOATCOIN} alt="GOAT coin" width={"10px"} /> {this.props.t('fighters_and_boosters')}</small>
                                            </div>
                                            {(new Date(prediction.event.event_date) > new Date()) && <div className="cancel" onClick={() => this.cancelPrediction(prediction.id)}>{this.props.t('cancel')}</div>}
                                        </React.Fragment>)
                                    :
                                    <React.Fragment>
                                        <span className="next_lvl text-center">{this.props.t('no_prediction')}</span>
                                        <div className="edit" onClick={() => window.location.href = "/events"}>
                                            {this.props.t('predict')}
                                        </div>
                                    </React.Fragment>}
                            </div>
                        </div>
                        <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${this.props.fighter.visual.section}/${this.props.fighter.fighter}/${this.props.fighter.visual.visual}.png`} alt={this.props.fighter.nickname.toLowerCase()} className={isMobile ? "visual" : "visual scale_max"} />
                    </div>
                }
                <NotificationContainer />
            </div>
        );
    }
}

export default withTranslation()(FighterItem);