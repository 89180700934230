import "../../components/flags.css"
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import MenuAdmin from '../../components/Menu/admin';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Buffer } from 'buffer';
import admin_services from '../../services/admin_services';

// Media
import { ProgressBar } from "react-loader-spinner";
import { QWRTX_MMA_GOAT_EVENTS, OPTIONS } from "../../constants";

class AdminEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            organisation: null,
            event_name: null,
            place: null,
            event_date: null,
            official_link: null,
            banner_data: null,
            banner_name: null,
            banner_extension: null,
            poster_data: null,
            poster_name: null,
            poster_extension: null,
            display: false,
            processing: false,
            notifying: false,
            selectedEvent: null,
        }
        this.hiddenBannerInput = React.createRef();
        this.hiddenPosterInput = React.createRef();
        this.hiddenUpdateBannerInput = React.createRef();
        this.hiddenUpdatePosterInput = React.createRef();
    }

    handleBannerUpload = () => { this.hiddenBannerInput.current.click() }
    handlePosterUpload = () => { this.hiddenPosterInput.current.click() }

    handleUpdateBannerUpload = () => { this.hiddenUpdateBannerInput.current.click() }
    handleUpdatePosterUpload = () => { this.hiddenUpdatePosterInput.current.click() }

    onBannerHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
            this.setState({ processing: false })
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
            this.setState({ processing: false })
        } else {
            this.setState({ banner_extension: extension, banner_name: event.target.files[0].name })
            reader.onloadend = () => this.processBanner(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    onPosterHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
            this.setState({ processing: false })
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
            this.setState({ processing: false })
        } else {
            this.setState({ poster_extension: extension, poster_name: event.target.files[0].name })
            reader.onloadend = () => this.processPoster(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    onUpdatePosterHandler = async (event) => {
        const reader = new FileReader()
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
        } else {
            this.setState({ poster_extension: extension, poster_name: event.target.files[0].name })
            reader.onloadend = () => this.processUpdatePoster(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    onUpdateBannerHandler = async (event) => {
        const reader = new FileReader()
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
        } else {
            this.setState({ banner_extension: extension, banner_name: event.target.files[0].name })
            reader.onloadend = () => this.processUpdateBanner(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processBanner = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await this.setState({ processing: false, banner_data: base64data })
    }

    processPoster = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await this.setState({ processing: false, poster_data: base64data })
    }

    processUpdateBanner = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await admin_services.updateEventBanner(this.state.selectedEvent, {file_data: base64data, file_extension: this.state.banner_extension, file_name: this.state.banner_name})
        window.location.reload()
    }

    processUpdatePoster = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await admin_services.updateEventPoster(this.state.selectedEvent, {file_data: base64data, file_extension: this.state.poster_extension, file_name: this.state.poster_name})
        window.location.reload()
    }

    referenceNewEvent = async () => {
        try {
            this.setState({ processing: true })
            const data = {
                organisation: this.state.organisation,
                event_name: this.state.event_name,
                place: this.state.place,
                event_date: new Date(this.state.event_date).toISOString(),
                official_link: this.state.official_link,
                poster: { file_data: this.state.poster_data, file_extension: this.state.poster_extension, file_name: this.state.poster_name },
                banner: { file_data: this.state.banner_data, file_extension: this.state.banner_extension, file_name: this.state.banner_name },
            }
            const resp = await admin_services.addEvent(data)
            if (resp.status) {
                NotificationManager.success('Event successfully referenced')
                window.location.reload()
            } else {
                NotificationManager.error('Could not create account')
            }
            this.setState({ processing: false })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
            this.setState({ processing: false })
        }
    }

    componentDidMount = async () => {
        const events = await admin_services.getAllEvents()
        this.setState({ events })
    }

    activate = async (id) => {
        await admin_services.activateEvent(id)
        const events = await admin_services.getAllEvents()
        this.setState({ events })
    }

    deactivate = async (id) => {
        await admin_services.deactivateEvent(id)
        const events = await admin_services.getAllEvents()
        this.setState({ events })
    }

    notify = async (id) => {
        this.setState({ notifying: true })
        await admin_services.notifyEvent(id)
        NotificationManager.success('Sending notifications...')
        this.setState({ notifying: false })
    }

    render() {
        return (
            <React.Fragment>
                <MenuAdmin active={"events"} />
                <h1 className="starter huge">EVENTS</h1>
                <h4>
                    Reference & browse events
                </h4>
                <input className="d-none" type="file" ref={this.hiddenUpdatePosterInput}
                    accept="image/x-png,image/jpeg"
                    onChange={(e) => this.onUpdatePosterHandler(e)} />
                <input className="d-none" type="file" ref={this.hiddenUpdateBannerInput}
                    accept="image/x-png,image/jpeg"
                    onChange={(e) => this.onUpdateBannerHandler(e)} />
                {!this.state.display && <div className="edit" onClick={() => this.setState({ display: true })}>Reference new event</div>}
                {this.state.display &&
                    <div className="max_holder text-center">
                        <div className="editor fit-content h-auto text-left mt-20 full-border-radius simple_border_mark">
                            <h5 className="text-center">Reference new event</h5>
                            <br />
                            <strong>Fill all required information to reference an event</strong>
                            <span className="label">
                                Poster
                            </span>
                            <input className="d-none" type="file" ref={this.hiddenPosterInput}
                                accept="image/x-png,image/jpeg"
                                onChange={(e) => this.onPosterHandler(e)} />
                            <div className="avatar" onClick={this.handlePosterUpload}
                                style={{
                                    height: "150px",
                                    backgroundImage: this.state.poster_data ? `url('data:image/${this.state.poster_extension};base64,${this.state.poster_data}')` :
                                        `url('https://api.dicebear.com/7.x/shapes/jpg?seed=${this.state.event_name}p')`
                                }}></div>
                            <span className="label">
                                Banner
                            </span>
                            <input className="d-none" type="file" ref={this.hiddenBannerInput}
                                accept="image/x-png,image/jpeg"
                                onChange={(e) => this.onBannerHandler(e)} />
                            <div className="avatar" onClick={this.handleBannerUpload}
                                style={{
                                    width: "180px",
                                    backgroundImage: this.state.banner_data ? `url('data:image/${this.state.banner_extension};base64,${this.state.banner_data}')` :
                                        `url('https://api.dicebear.com/7.x/shapes/jpg?seed=${this.state.event_name}b')`
                                }}></div>
                            <span className="label">
                                Organisation
                            </span>
                            <select className="text_editor d-block" onChange={(e) => this.setState({ organisation: e.target.value })}>
                                <option selected disabled>Select an organisation</option>
                                <option value="UFC">UFC</option>
                                <option value="BELLATOR">BELLATOR</option>
                                <option value="PFL">PFL</option>
                            </select>
                            <span className="label">
                                Event name & number
                            </span>
                            <input type="text" className="text_editor d-block" placeholder={"Set event name e.g. UFC300"} onChange={(e) => this.setState({ event_name: e.target.value })} />
                            <span className="label">
                                Place of the event
                            </span>
                            <input type="text" className="text_editor d-block" placeholder={"Set place of the event"} onChange={(e) => this.setState({ place: e.target.value })} />
                            <span className="label">
                                Official link
                            </span>
                            <input type="text" className="text_editor d-block" placeholder={"Set URL of the official event"} onChange={(e) => this.setState({ official_link: e.target.value })} />
                            <span className="label">
                                Time of the event (set at your current local time)
                            </span>
                            <input type="datetime-local" className="text_editor d-block" placeholder="Set start date and time" onChange={(e) => this.setState({ event_date: e.target.value })} value={this.state.event_date} />
                            {!this.state.processing && <div className="submit d-block" onClick={this.referenceNewEvent}>
                                Reference new event
                            </div>}
                            <p className="text-center forgotten" onClick={() => this.setState({ display: false })}>Cancel</p>
                            {this.state.processing &&
                                <ProgressBar
                                    height="80"
                                    width="80"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{ margin: '0 20px' }}
                                    wrapperClass="progress-bar-wrapper"
                                    borderColor='#000'
                                    barColor='#000'
                                />
                            }
                        </div>
                    </div>}
                <div className="max_holder minimizer">
                    {this.state.events.map(event =>
                        <div className="card card-fighter" key={event.id} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${event.poster}')`, backgroundSize: 'cover' }}>
                            <div className="info text-left h-auto">
                                {new Date(event.event_date) > new Date() ?
                                    <div className="event_status back-coming">OPEN</div>
                                    :
                                    <div className="event_status back-closed">CLOSED</div>
                                }
                                <h5>{event.event_name.toUpperCase()}</h5>
                                <span className="label mt-5">
                                    <small>{new Date(event.event_date).toLocaleDateString(undefined, OPTIONS)}, {new Date(event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
                                </span>
                                <hr />
                                <span className="label">
                                    Activation
                                </span>
                                {!event.active ?
                                    <div className="edit back-coming black" onClick={() => this.activate(event.id)}>
                                        Activate
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div className="edit back-danger black d-inline-block" onClick={() => this.deactivate(event.id)}>
                                            Deactivate
                                        </div>
                                        {this.state.notifying ?
                                            <span className="ft-11 ml-10">Loading...</span>
                                            :
                                            new Date(event.event_date) > new Date() && <div className="edit back-coming black d-inline-block ml-10" onClick={() => this.notify(event.id)}>
                                                Notify
                                            </div>}
                                    </React.Fragment>
                                }
                                <hr />
                                <span className="label text-center">Browse and reference fights</span>
                                <div className="edit" onClick={() => window.location.href = `/admin/event/${event.id}`}>
                                    View fights
                                </div>
                                <hr />
                                <div className="text-center">
                                    <div className="edit d-inline-block" onClick={() => {this.setState({selectedEvent: event.id}); this.handleUpdatePosterUpload()}}>
                                        Poster
                                    </div>
                                    <div className="edit d-inline-block ml-10" onClick={() => {this.setState({selectedEvent: event.id}); this.handleUpdateBannerUpload()}}>
                                        Banner
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default AdminEvents;