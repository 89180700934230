import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import SelectManFighter from "./pages/Start/SelectManFighter.js";
import SelectWomanFighter from "./pages/Start/SelectWomanFighter.js";
import EditManFighter from "./pages/Start/EditManFighter.js";
import EditWomanFighter from "./pages/Start/EditWomanFighter.js";
import ConfirmFighters from './pages/Start/ConfirmFighters';
import Fighters from './pages/Fighters';
import NewFighter from './pages/NewFighter';
import WithdrawFighter from './pages/Fighters/withdraw';
import Events from './pages/Events';
import Event from './pages/Events/event';
import Register from './pages/Register';
import Rankings from './pages/Rankings';
import Profile from './pages/Profile';
import InviteFriend from './pages/Invite'
import ProfileUpdate from './pages/Profile/update';
import Login from './pages/Login';
import ForgottenPswd from './pages/Login/forgotten'
import AdminAuth from './pages/admin/auth';
import AdminEvents from './pages/admin/events';
import AdminEvent from './pages/admin/event';
import AdminProfile from './pages/admin/profile';
import AdminSubscribers from './pages/admin/subscribers';
import AdminUsers from './pages/admin/users';
import AdminPublicProfile from './pages/admin/user';
import AdminVisuals from './pages/admin/visuals';
import Preview from './pages/Events/preview';
import Store from './pages/Store';
import StoreCreate from "./pages/Store/create"
import StoreConfirm from "./pages/Store/confirm"
import Search from './pages/search';
import Leagues from './pages/Leagues';
import CreateLeague from './pages/Leagues/create';
import JoinLeague from './pages/Leagues/join';
import LeagueParticipants from './pages/Leagues/participants';
import LeagueFighters from './pages/Leagues/fighters';
import LeagueInvitations from './pages/Leagues/invits';
import InvitationLeague from './pages/Leagues/invitation';
import Terms from './pages/Terms';
import Legal from './pages/Terms/legal';
import Metamask from './pages/Fighters/metamask';
import Wall from './pages/Wall';
import News from './pages/News/index.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <div className="page">
        <BrowserRouter>
          <Routes>
            <Route path="/Home" element={<Home />} />
            <Route path="/start/1" element={<SelectManFighter />} />
            <Route path="/start/2" element={<EditManFighter />} />
            <Route path="/start/3" element={<SelectWomanFighter />} />
            <Route path="/start/4" element={<EditWomanFighter />} />
            <Route path="/start/5" element={<ConfirmFighters />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotten" element={<ForgottenPswd />} />
            <Route path="/fighters" element={<Fighters />} />
            <Route path="/connect" element={<Metamask />} />
            <Route path="/withdraw/:id" element={<WithdrawFighter {...this.props} />} />
            <Route path="/leagues" element={<Leagues />} />
            <Route path="/league/join/:code" element={<JoinLeague {...this.props} />} />
            <Route path="/league/invitation/:code" element={<InvitationLeague {...this.props} />} />
            <Route path="/league/participants/:id" element={<LeagueParticipants {...this.props} />} />
            <Route path="/league/fighters/:id" element={<LeagueFighters {...this.props} />} />
            <Route path="/league/invitations/:id" element={<LeagueInvitations {...this.props} />} />
            <Route path="/create/league" element={<CreateLeague />} />
            <Route path="/rankings" element={<Rankings />} />
            <Route path="/profile/update" element={<ProfileUpdate />} />
            <Route path="/profile/:username" element={<Profile {...this.props} />} />
            <Route path="/invite" element={<InviteFriend />} />
            <Route path="/events" element={<Events />} />
            <Route path="/event/:id" element={<Event {...this.props} />} />
            <Route path="/preview/:id" element={<Preview {...this.props} />} />
            <Route path="/search" element={<Search />} />
            <Route path="/store" element={<Store />} />
            <Route path="/store/create/:id" element={<StoreCreate {...this.props} />} />
            <Route path="/store/confirm" element={<StoreConfirm />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/whatsnew" element={<Wall />} />
            <Route path="/newfighter" element={<NewFighter />} />
            <Route path="/news" element={<News />} />
            {/* Admin */}
            <Route path="/admin/auth" element={<AdminAuth />} />
            <Route path="/admin/events" element={<AdminEvents />} />
            <Route path="/admin/event/:id" element={<AdminEvent {...this.props} />} />
            <Route path="/admin/profile" element={<AdminProfile />} />
            <Route path="/admin/subscribers" element={<AdminSubscribers />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/visuals" element={<AdminVisuals />} />
            <Route path="/admin/profile/:username" element={<AdminPublicProfile {...this.props} />} />
            {/* Default */}
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default withTranslation()(App)