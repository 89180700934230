import api from './api';

class ChainService {
  async withdrawFighter(id, eth_address) {
    return await api
      .post(`/chain/withdraw/${id}`, {eth_address})
      .then(async (response) => {
        return response.data;
      });
  }

  async listFighters(address) {
    return await api
      .get(`/chain/list/${address}`)
      .then(async (response) => {
        return response.data.fighters;
      });
  }

  async importFighter(address, token_id, signature) {
    return await api
      .post(`/chain/import`, {address, token_id, signature})
      .then(async (response) => {
        return response.data;
      });
  }
}

// eslint-disable-next-line
export default new ChainService();