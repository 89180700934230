import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import token_services from "../../services/token_services";
import UserProfile from "./user";
import PublicProfile from "./public";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switch: null,
        }
    }

    componentDidMount = async () => {
        const { username } = this.props.match.params
        console.log(username)
        const profile = await token_services.getUser()
        if (profile && profile.account.username === username) this.setState({ switch: "profile" })
        else this.setState({ switch: "public" })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.switch === "profile" && <UserProfile />}
                {this.state.switch === "public" && <PublicProfile />}
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(Profile));