import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import auth_services from "../../services/auth_services";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ProgressBar } from 'react-loader-spinner';
import { SITE_KEY } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import MobileHeader from "../../components/Menu/mobile";

//Media
import WHITEGOAT from "../../assets/img/white_goat.png";
import { isIOS, isMobile } from "react-device-detect";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            success: false,
            processing: false,
            captcha: null,
        }
    }

    onChange = (value) => {
        this.setState({ captcha: value })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.signIn()
        }
    }

    signIn = async () => {
        if (!this.state.password || this.state.password.length < 4 || this.state.password.length > 25 ||
            !this.state.password || !validator.isEmail(this.state.email))
            NotificationManager.warning(this.props.t('request_fill_all'))
        else if (!isIOS && !this.state.captcha)
            NotificationManager.warning(this.props.t('recaptcha_control'))
        else {
            try {
                this.setState({ processing: true })
                const resp = await auth_services.signin(
                    this.state.email,
                    this.state.password,
                    this.state.captcha,
                )
                if (resp.status) {
                    window.location.href = "/fighters"
                } else {
                    NotificationManager.error(this.props.t("could_not_sign_in"))
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data) : this.props.t(e.message))
                this.setState({ processing: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"login"} />
                <h1 className="starter huge">{this.props.t('access_account')}</h1>
                <h4>
                    {this.props.t('set_credentials')}
                </h4>
                <div className="max_holder text-center">
                    <div className="editor fit-content h-auto text-left mt-20 full-border-radius">
                        <span className="label">
                            {this.props.t('email')}
                        </span>
                        <input type="email" className="text_editor d-block" placeholder={"Set your email"} onChange={(e) => this.setState({ email: e.target.value })} />
                        <span className="label">
                            {this.props.t('password')}
                        </span>
                        <input type="password" className="text_editor d-block" placeholder={"Set your password"} onKeyDown={this._handleKeyDown} onChange={(e) => this.setState({ password: e.target.value })} />
                        <p className="text-center forgotten"><a href="/forgotten">{this.props.t('forgotten_password')}</a></p>
                        {!isIOS && <ReCAPTCHA
                            theme={"dark"}
                            sitekey={SITE_KEY}
                            onChange={this.onChange}
                        />}
                        {!this.state.processing && !this.state.success && <div className="edit d-block" onClick={this.signIn}>
                            {this.props.t('sign_in')}
                        </div>}
                        {this.state.processing &&
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{ margin: '0 20px' }}
                                wrapperClass="progress-bar-wrapper"
                                borderColor='#FFF'
                                barColor='#FFF'
                            />
                        }
                    </div>
                    <p className="text-center"><strong>{this.props.t('no_account')}</strong></p>
                    <div className="aura mb-0">
                        <div className="launch" onClick={() => window.location.href = "/start/1"}>
                            <img className="sha" src={WHITEGOAT} alt="Shadow fighter" /> {this.props.t('start_now')}
                        </div>
                    </div>
                </div>
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Login);