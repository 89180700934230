import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import MenuAdmin from '../../components/Menu/admin';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import admin_services from '../../services/admin_services';
import { ProgressBar } from "react-loader-spinner";
import { OPTIONS } from '../../constants';
import { FaCheckCircle, FaHourglass } from "react-icons/fa";

class AdminSubscribers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribers: [],
            processing: true,
            notifying: false,
        }
    }

    componentDidMount = async () => {
        const subscribers = await admin_services.getAllSubscribers()
        this.setState({ processing: false, subscribers })
    }

    remind = async () => {
        this.setState({ notifying: true })
        await admin_services.remindAllSubscribers()
        NotificationManager.success('Sending reminders...')
        this.setState({ notifying: false })
    }

    render() {
        return (
            <React.Fragment>
                <MenuAdmin active={"subscribers"} />
                <h1 className="huge starter">SUBSCRIBERS [{this.state.subscribers.length}]</h1>
                <h4>Browse all subscribers</h4>
                {/* {!this.state.notifying &&
                    <div className="edit back-coming black d-block" onClick={this.remind}>
                        Notify
                    </div>} */}
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="follower" style={{ marginBottom: "50px" }}>
                        {this.state.subscribers.map(subscriber =>
                            <div className="profile pointer" key={subscriber.id}>
                                <div className="info_profile">
                                    <big>{subscriber.validated ? <FaCheckCircle /> : <FaHourglass />} {subscriber.email}</big>
                                    <br />
                                    <small><span className="capitalize">{new Date(subscriber.createdAt).toLocaleDateString(undefined, OPTIONS)}</span>, {new Date(subscriber.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
                                </div>
                            </div>
                        )}
                    </div>}
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default AdminSubscribers;