import "../../components/flags.css"
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import MenuAdmin from '../../components/Menu/admin';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import admin_services from '../../services/admin_services';
import { ProgressBar } from "react-loader-spinner";
import { useParams } from 'react-router-dom';
import { Buffer } from 'buffer';

// Media
import { OPTIONS, QWRTX_MMA_GOAT_EVENTS, QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import { countries } from "../../components/countries";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class AdminEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fights: [],
            cancelled: [],
            event: null,
            display: false,
            processing: false,
            event_id: null,
            fighters: "male",
            main_event: false,
            co_main_event: false,
            championship: false,
            fighter_one_id: null,
            fighter_one_firstname: null,
            fighter_one_lastname: null,
            fighter_one_age: null,
            fighter_one_martial_arts: null,
            fighter_one_nationality: null,
            fighter_one_wins: 0,
            fighter_one_losses: 0,
            fighter_one_draws: 0,
            fighter_one_no_contests: 0,
            fighter_one_ranking: null,
            fighter_one_odd: null,
            fighter_two_id: null,
            fighter_two_firstname: null,
            fighter_two_lastname: null,
            fighter_two_age: null,
            fighter_two_martial_arts: null,
            fighter_two_nationality: null,
            fighter_two_wins: 0,
            fighter_two_losses: 0,
            fighter_two_draws: 0,
            fighter_two_no_contests: 0,
            fighter_two_ranking: null,
            fighter_two_odd: null,
            category: null,
            nb_rounds: 3,
            fighter_one_avatar_name: null,
            fighter_one_avatar_extension: null,
            fighter_one_avatar_data: null,
            fighter_two_avatar_name: null,
            fighter_two_avatar_extension: null,
            fighter_two_avatar_data: null,
            fighters_one: [],
            fighters_two: [],
        }
        this.hiddenFighterOneInput = React.createRef();
        this.hiddenFighterTwoInput = React.createRef();
    }

    handleFighterOneUpload = () => { this.hiddenFighterOneInput.current.click() }
    handleFighterTwoUpload = () => { this.hiddenFighterTwoInput.current.click() }

    onFighterOneHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
            this.setState({ processing: false })
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
            this.setState({ processing: false })
        } else {
            this.setState({ fighter_one_avatar_extension: extension, fighter_one_avatar_name: event.target.files[0].name })
            reader.onloadend = () => this.processFighterOne(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    onFighterTwoHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
            this.setState({ processing: false })
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
            this.setState({ processing: false })
        } else {
            this.setState({ fighter_two_avatar_extension: extension, fighter_two_avatar_name: event.target.files[0].name })
            reader.onloadend = () => this.processFighterTwo(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processFighterOne = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await this.setState({ processing: false, fighter_one_avatar_data: base64data })
    }

    processFighterTwo = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await this.setState({ processing: false, fighter_two_avatar_data: base64data })
    }

    onSearchOne = async (search) => {
        if (search) {
            const fighters_one = await admin_services.searchFighters(search)
            this.setState({ fighters_one })
        }
    }

    onSearchTwo = async (search) => {
        if (search) {
            const fighters_two = await admin_services.searchFighters(search)
            this.setState({ fighters_two })
        }
    }

    onSelectFighterOne = async (fighter) => {
        this.setState({
            fighter_one_firstname: fighter.firstname,
            fighter_one_lastname: fighter.lastname,
            fighter_one_age: fighter.age,
            fighter_one_martial_arts: fighter.martial_art,
            fighter_one_nationality: fighter.nationality,
            fighter_one_wins: fighter.wins,
            fighter_one_losses: fighter.losses,
            fighter_one_draws: fighter.draws,
            fighter_one_no_contests: fighter.no_contests,
            fighter_one_ranking: fighter.ranking,
            fighter_one_avatar: fighter.avatar,
            fighter_one_id: fighter.id,
        })
    }

    onSelectFighterTwo = async (fighter) => {
        this.setState({
            fighter_two_firstname: fighter.firstname,
            fighter_two_lastname: fighter.lastname,
            fighter_two_age: fighter.age,
            fighter_two_martial_arts: fighter.martial_art,
            fighter_two_nationality: fighter.nationality,
            fighter_two_wins: fighter.wins,
            fighter_two_losses: fighter.losses,
            fighter_two_draws: fighter.draws,
            fighter_two_no_contests: fighter.no_contests,
            fighter_two_ranking: fighter.ranking,
            fighter_two_avatar: fighter.avatar,
            fighter_two_id: fighter.id,
        })
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const data = await admin_services.getEvent(id)
        this.setState({ event: data.event, event_id: id, fights: data.fights, cancelled: data.cancelled })
    }

    referenceNewFight = async () => {
        try {
            this.setState({ processing: true })
            const data = {
                event_id: this.state.event_id,
                fighters: this.state.fighters,
                main_event: this.state.main_event,
                co_main_event: this.state.co_main_event,
                championship: this.state.championship,
                fighter_one_id: this.state.fighter_one_id,
                fighter_one_firstname: this.state.fighter_one_firstname,
                fighter_one_lastname: this.state.fighter_one_lastname,
                fighter_one_age: this.state.fighter_one_age,
                fighter_one_martial_arts: this.state.fighter_one_martial_arts,
                fighter_one_nationality: this.state.fighter_one_nationality,
                fighter_one_wins: this.state.fighter_one_wins,
                fighter_one_losses: this.state.fighter_one_losses,
                fighter_one_draws: this.state.fighter_one_draws,
                fighter_one_no_contests: this.state.fighter_one_no_contests,
                fighter_one_ranking: this.state.fighter_one_ranking,
                fighter_one_odd: this.state.fighter_one_odd,
                fighter_two_id: this.state.fighter_two_id,
                fighter_two_firstname: this.state.fighter_two_firstname,
                fighter_two_lastname: this.state.fighter_two_lastname,
                fighter_two_age: this.state.fighter_two_age,
                fighter_two_martial_arts: this.state.fighter_two_martial_arts,
                fighter_two_nationality: this.state.fighter_two_nationality,
                fighter_two_wins: this.state.fighter_two_wins,
                fighter_two_losses: this.state.fighter_two_losses,
                fighter_two_draws: this.state.fighter_two_draws,
                fighter_two_no_contests: this.state.fighter_two_no_contests,
                fighter_two_ranking: this.state.fighter_two_ranking,
                fighter_two_odd: this.state.fighter_two_odd,
                category: this.state.category,
                nb_rounds: this.state.nb_rounds,
                organisation: this.state.event.organisation,
                fighter_one_avatar: this.state.fighter_one_avatar_data ?
                    { file_data: this.state.fighter_one_avatar_data, file_extension: this.state.fighter_one_avatar_extension, file_name: this.state.fighter_one_avatar_name } : null,
                fighter_two_avatar: this.state.fighter_two_avatar_data ?
                    { file_data: this.state.fighter_two_avatar_data, file_extension: this.state.fighter_two_avatar_extension, file_name: this.state.fighter_two_avatar_name } : null,
            }
            const resp = await admin_services.addFight(data)
            if (resp.status) {
                NotificationManager.success('Fight successfully referenced')
                this.setState({
                    display: false,
                    fighters: "male",
                    main_event: false,
                    co_main_event: false,
                    championship: false,
                    fighter_one_id: null,
                    fighter_one_firstname: null,
                    fighter_one_lastname: null,
                    fighter_one_age: null,
                    fighter_one_martial_arts: null,
                    fighter_one_nationality: null,
                    fighter_one_wins: 0,
                    fighter_one_losses: 0,
                    fighter_one_draws: 0,
                    fighter_one_no_contests: 0,
                    fighter_one_ranking: null,
                    fighter_one_odd: null,
                    fighter_two_id: null,
                    fighter_two_firstname: null,
                    fighter_two_lastname: null,
                    fighter_two_age: null,
                    fighter_two_martial_arts: null,
                    fighter_two_nationality: null,
                    fighter_two_wins: 0,
                    fighter_two_losses: 0,
                    fighter_two_draws: 0,
                    fighter_two_no_contests: 0,
                    fighter_two_ranking: null,
                    fighter_two_odd: null,
                    category: null,
                    nb_rounds: 3,
                    fighters_one: [],
                    fighters_two: [],
                    fighter_one_avatar: null,
                    fighter_two_avatar: null,
                    fighter_one_avatar_name: null,
                    fighter_one_avatar_extension: null,
                    fighter_one_avatar_data: null,
                    fighter_two_avatar_name: null,
                    fighter_two_avatar_extension: null,
                    fighter_two_avatar_data: null,
                })
                const data = await admin_services.getEvent(this.state.event_id)
                this.setState({ event: data.event, fights: data.fights, cancelled: data.cancelled })
            } else {
                NotificationManager.error('Could not create account')
            }
            this.setState({ processing: false })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
            this.setState({ processing: false })
        }
    }

    deleteFight = async (fight_id) => {
        if (window.confirm("Delete the fight?")) {
            this.setState({ event: null })
            await admin_services.deleteFight(fight_id)
            const { id } = this.props.match.params
            const data = await admin_services.getEvent(id)
            this.setState({ event: data.event, event_id: id, fights: data.fights, cancelled: data.cancelled })
        }
    }

    reloatEvent = async () => {
        const { id } = this.props.match.params
        const data = await admin_services.getEvent(id)
        this.setState({ event: data.event, event_id: id, fights: data.fights, cancelled: data.cancelled })
    }

    cancelFight = async (fight_id) => {
        if (window.confirm("Cancel the fight?")) {
            this.setState({ event: null })
            await admin_services.cancelFight(fight_id)
            this.reloatEvent()
        }
    }

    reactivateFight = async (fight_id) => {
        if (window.confirm("Reactivate the fight?")) {
            this.setState({ event: null })
            await admin_services.activateFight(fight_id)
            this.reloatEvent()
        }
    }

    setFightWinner = async (fight_id, winner, victory) => {
        if (window.confirm("Do you confirm?")) {
            this.setState({ event: null })
            await admin_services.setWinner(fight_id, winner, victory)
            this.reloatEvent()
        }
    }

    render() {
        return (
            <React.Fragment>
                <MenuAdmin active={"events"} />
                {!this.state.event ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <img className="event_visual" src={`https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${this.state.event.banner}?${new Date().toISOString}`} />
                        <h4>
                            {this.state.event.place}
                            <br />
                            <span className="capitalize">{new Date(this.state.event.event_date).toLocaleDateString(undefined, OPTIONS)}</span>, {new Date(this.state.event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </h4>
                        {!this.state.display && <div className="edit mb-50" onClick={() => this.setState({ display: true })}>Reference new fight</div>}
                        {this.state.display &&
                            <div className="prono_holder mb-50 fit-content text-center simple_border_mark">
                                <h5 className="text-center">Reference new fight</h5>
                                <strong>Fill all required information to reference a fight</strong>
                                <span className="label">
                                    Female / male
                                </span>
                                <select className="text_editor" onChange={(e) => this.setState({ fighters: e.target.value })}>
                                    <option value="male" selected>Male</option>
                                    <option value="female">Female</option>
                                </select>
                                <span className="label">
                                    Weightclass
                                </span>
                                <select className="text_editor" onChange={(e) => this.setState({ category: e.target.value })}>
                                    <option selected disabled>Select weightclass</option>
                                    {this.state.fighters === "female" && <option value="STRAWWEIGHT">STRAWWEIGHT</option>}
                                    <option value="FLYWEIGHT">FLYWEIGHT</option>
                                    <option value="BANTAMWEIGHT">BANTAMWEIGHT</option>
                                    <option value="FEATHERWEIGHT">FEATHERWEIGHT</option>
                                    <option value="LIGHTWEIGHT">LIGHTWEIGHT</option>
                                    {this.state.fighters === "male" && <option value="WELTERWEIGHT">WELTERWEIGHT</option>}
                                    {this.state.fighters === "male" && <option value="MIDDLEWEIGHT">MIDDLEWEIGHT</option>}
                                    {this.state.fighters === "male" && <option value="LIGHTHEAVYWEIGHT">LIGHTHEAVYWEIGHT</option>}
                                    {this.state.fighters === "male" && <option value="HEAVYWEIGHT">HEAVYWEIGHT</option>}
                                </select>
                                <span className="label">
                                    Number of rounds
                                </span>
                                <input type="number" className="text_editor" placeholder={"Fight number of rounds"} onChange={(e) => this.setState({ nb_rounds: e.target.value })} defaultValue={3} />
                                <span className="label">
                                    Fight type
                                </span>
                                <p>
                                    <small><input type="checkbox" onClick={() => this.setState({ main_event: !this.state.main_event })} /> Main event</small>
                                    <small className="ml-10"><input type="checkbox" onClick={() => this.setState({ co_main_event: !this.state.co_main_event })} /> Co main event</small>
                                    <small className="ml-10"><input type="checkbox" onClick={() => this.setState({ championship: !this.state.championship })} /> Championship</small>
                                </p>
                                <div className="editor fit-content h-auto d-inline-block full-border-radius">
                                    <h5>Contestant details</h5>
                                    <span className="label">
                                        Search and apply fighter
                                    </span>
                                    <input type="search" className="text_editor d-block" placeholder="Search fighter one" onChange={(e) => this.onSearchOne(e.target.value)} />
                                    {this.state.fighters_one.map(fighter =>
                                        <h4 className="pointer mb-0" onClick={() => this.onSelectFighterOne(fighter)} key={fighter.id}>{fighter.firstname} {fighter.lastname} {fighter.nationality}</h4>
                                    )}
                                    <span className="label">
                                        Avatar
                                    </span>
                                    <input className="d-none" type="file" ref={this.hiddenFighterOneInput}
                                        accept="image/x-png,image/jpeg"
                                        onChange={(e) => this.onFighterOneHandler(e)} />
                                    <img onClick={this.handleFighterOneUpload}
                                        height={"200px"}
                                        src={this.state.fighter_one_avatar_data ?
                                            `data:image/${this.state.fighter_one_avatar_extension};base64,${this.state.fighter_one_avatar_data}`
                                            : this.state.fighter_one_avatar ?
                                                this.state.fighter_one_avatar.includes("https") ?
                                                    this.state.fighter_one_avatar
                                                    :
                                                    `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${this.state.fighter_one_avatar}` :
                                                `https://api.dicebear.com/7.x/shapes/jpg?seed=${this.state.fighter_one_avatar_data}b`} />
                                    <span className="label">
                                        Martial Arts
                                    </span>
                                    <select className="text_editor d-block" onChange={(e) => this.setState({ fighter_one_martial_arts: e.target.value })}>
                                        <option selected disabled>{this.state.fighter_one_martial_arts ? this.state.fighter_one_martial_arts : "Select main martial art"}</option>
                                        <option value="Judo">Judo</option>
                                        <option value="Karate">Karate</option>
                                        <option value="Boxer">Boxer</option>
                                        <option value="Kickboxer">Kickboxer</option>
                                        <option value="Tae kwon do">Tae kwon do</option>
                                        <option value="Jiu jitsu">Jiu jitsu</option>
                                        <option value="Muay Thai">Muay Thai</option>
                                        <option value="Wrestler">Wrestler</option>
                                    </select>
                                    <span className="label">
                                        Firstname
                                    </span>
                                    <input type="text" className="text_editor d-block" placeholder={"Contestant's firstname"} onChange={(e) => this.setState({ fighter_one_firstname: e.target.value })} value={this.state.fighter_one_firstname} />
                                    <span className="label">
                                        Lastname
                                    </span>
                                    <input type="text" className="text_editor d-block" placeholder={"Contestant's lastname"} onChange={(e) => this.setState({ fighter_one_lastname: e.target.value })} value={this.state.fighter_one_lastname} />
                                    <span className="label">
                                        Age
                                    </span>
                                    <input type="number" className="text_editor d-block" placeholder={"Contestant's age"} onChange={(e) => this.setState({ fighter_one_age: e.target.value })} value={this.state.fighter_one_age} />
                                    <span className="label">
                                        Nationality
                                    </span>
                                    <select className="text_editor" onChange={(e) => this.setState({ fighter_one_nationality: e.target.value })}>
                                        {this.state.fighter_one_nationality ?
                                            <option value={this.state.fighter_one_nationality} selected>{countries[this.state.fighter_one_nationality.toUpperCase()]}</option>
                                            :
                                            <option selected disabled>Select a nationality</option>}
                                        {Object.keys(countries).map(key =>
                                            <option key={key} value={key.toLocaleLowerCase()}>{countries[key]}</option>
                                        )}
                                    </select>
                                    {this.state.fighter_one_nationality && <div className={`middle-flag ${this.state.fighter_one_nationality}`}></div>}
                                    <span className="label">
                                        Record (Wins, losses...)
                                    </span>
                                    <input type="number" className="text_editor" style={{ width: "80px" }} placeholder={"wins"} onChange={(e) => this.setState({ fighter_one_wins: e.target.value })} value={this.state.fighter_one_wins} />
                                    <input type="number" className="text_editor ml-10" style={{ width: "80px" }} placeholder={"losses"} onChange={(e) => this.setState({ fighter_one_losses: e.target.value })} value={this.state.fighter_one_losses} />
                                    <br />
                                    <input type="number" className="text_editor" style={{ width: "80px" }} placeholder={"draws"} onChange={(e) => this.setState({ fighter_one_draws: e.target.value })} value={this.state.fighter_one_draws} />
                                    <input type="number" className="text_editor ml-10" style={{ width: "80px" }} placeholder={"NC"} onChange={(e) => this.setState({ fighter_one_no_contests: e.target.value })} value={this.state.fighter_one_no_contests} />
                                    <span className="label">
                                        Ranking <small>(set "c" for champion)</small>
                                    </span>
                                    <input type="text" className="text_editor d-block" placeholder={"Contestant's ranking"} onChange={(e) => this.setState({ fighter_one_ranking: e.target.value })} value={this.state.fighter_one_ranking} />
                                    <span className="label">
                                        Winning odds
                                    </span>
                                    <input type="number" className="text_editor d-block" placeholder={"Contestant's odds"} onChange={(e) => this.setState({ fighter_one_odd: e.target.value })} />
                                </div>

                                <div className="editor fit-content h-auto d-inline-block full-border-radius ml-20">
                                    <h5>Opponent details</h5>
                                    <span className="label">
                                        Search and apply fighter
                                    </span>
                                    <input type="search" className="text_editor d-block" placeholder="Search fighter two" onChange={(e) => this.onSearchTwo(e.target.value)} />
                                    {this.state.fighters_two.map(fighter =>
                                        <h4 className="pointer mb-0" onClick={() => this.onSelectFighterTwo(fighter)} key={fighter.id}>{fighter.firstname} {fighter.lastname} {fighter.nationality}</h4>
                                    )}
                                    <span className="label">
                                        Avatar
                                    </span>
                                    <input className="d-none" type="file" ref={this.hiddenFighterTwoInput}
                                        accept="image/x-png,image/jpeg"
                                        onChange={(e) => this.onFighterTwoHandler(e)} />
                                    <img onClick={this.handleFighterTwoUpload}
                                        height={"200px"}
                                        src={this.state.fighter_two_avatar_data ?
                                            `data:image/${this.state.fighter_two_avatar_extension};base64,${this.state.fighter_two_avatar_data}`
                                            : this.state.fighter_two_avatar ?
                                                this.state.fighter_two_avatar.includes("https") ?
                                                    this.state.fighter_two_avatar
                                                    :
                                                    `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${this.state.fighter_two_avatar}` :
                                                `https://api.dicebear.com/7.x/shapes/jpg?seed=${this.state.fighter_two_avatar_data}b`} />
                                    <span className="label">
                                        Martial Arts
                                    </span>
                                    <select className="text_editor d-block" onChange={(e) => this.setState({ fighter_two_martial_arts: e.target.value })}>
                                        <option selected disabled>{this.state.fighter_two_martial_arts ? this.state.fighter_two_martial_arts : "Select main martial art"}</option>
                                        <option value="Judo">Judo</option>
                                        <option value="Karate">Karate</option>
                                        <option value="Boxer">Boxer</option>
                                        <option value="Kickboxer">Kickboxer</option>
                                        <option value="Tae kwon do">Tae kwon do</option>
                                        <option value="Jiu jitsu">Jiu jitsu</option>
                                        <option value="Muay Thai">Muay Thai</option>
                                        <option value="Wrestler">Wrestler</option>
                                    </select>
                                    <span className="label">
                                        Firstname
                                    </span>
                                    <input type="text" className="text_editor d-block" placeholder={"Opponent's firstname"} onChange={(e) => this.setState({ fighter_two_firstname: e.target.value })} value={this.state.fighter_two_firstname} />
                                    <span className="label">
                                        Lastname
                                    </span>
                                    <input type="text" className="text_editor d-block" placeholder={"Opponent's lastname"} onChange={(e) => this.setState({ fighter_two_lastname: e.target.value })} value={this.state.fighter_two_lastname} />
                                    <span className="label">
                                        Age
                                    </span>
                                    <input type="number" className="text_editor d-block" placeholder={"Opponent's age"} onChange={(e) => this.setState({ fighter_two_age: e.target.value })} value={this.state.fighter_two_age} />
                                    <span className="label">
                                        Nationality
                                    </span>
                                    <select className="text_editor" onChange={(e) => this.setState({ fighter_two_nationality: e.target.value })}>
                                        {this.state.fighter_two_nationality ?
                                            <option value={this.state.fighter_two_nationality} selected>{countries[this.state.fighter_two_nationality.toUpperCase()]}</option>
                                            :
                                            <option selected disabled>Select a nationality</option>}
                                        {Object.keys(countries).map(key =>
                                            <option key={key} value={key.toLocaleLowerCase()}>{countries[key]}</option>
                                        )}
                                    </select>
                                    {this.state.fighter_two_nationality && <div className={`middle-flag ${this.state.fighter_two_nationality}`}></div>}
                                    <span className="label">
                                        Record (Wins, losses...)
                                    </span>
                                    <input type="number" className="text_editor" style={{ width: "80px" }} placeholder={"wins"} onChange={(e) => this.setState({ fighter_two_wins: e.target.value })} value={this.state.fighter_two_wins} />
                                    <input type="number" className="text_editor ml-10" style={{ width: "80px" }} placeholder={"losses"} onChange={(e) => this.setState({ fighter_two_losses: e.target.value })} value={this.state.fighter_two_losses} />
                                    <br />
                                    <input type="number" className="text_editor" style={{ width: "80px" }} placeholder={"draws"} onChange={(e) => this.setState({ fighter_two_draws: e.target.value })} value={this.state.fighter_two_draws} />
                                    <input type="number" className="text_editor ml-10" style={{ width: "80px" }} placeholder={"NC"} onChange={(e) => this.setState({ fighter_two_no_contests: e.target.value })} value={this.state.fighter_two_no_contests} />
                                    <span className="label">
                                        Ranking <small>(set "c" for champion)</small>
                                    </span>
                                    <input type="text" className="text_editor d-block" placeholder={"Opponent's ranking"} onChange={(e) => this.setState({ fighter_two_ranking: e.target.value })} value={this.state.fighter_two_ranking} />
                                    <span className="label">
                                        Winning odds
                                    </span>
                                    <input type="number" className="text_editor d-block" placeholder={"Opponent's odds"} onChange={(e) => this.setState({ fighter_two_odd: e.target.value })} />
                                </div>

                                <br />
                                {!this.state.processing && <div className="submit d-block" onClick={this.referenceNewFight}>
                                    Reference fight
                                </div>}
                                <p className="text-center forgotten" onClick={() => this.setState({ display: false })}>Cancel</p>
                                {this.state.processing &&
                                    <ProgressBar
                                        height="80"
                                        width="80"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{ margin: '0 20px' }}
                                        wrapperClass="progress-bar-wrapper"
                                        borderColor='#000'
                                        barColor='#000'
                                    />
                                }
                            </div>}

                        <div className="prono_holder mb-50">
                            {this.state.fights.map(fight =>
                                <div className="prono h-auto reduced-top" key={fight.id}>
                                    <div className="left_part h-auto">
                                        <span className="label">{fight.fight_status === "CLOSED" && <strong className="black">CLOSED</strong>} {fight.main_event ? "Main event - " : fight.co_main_event && "Co main event - "}{fight.category} {fight.championship && "CHAMPIONSHIP"}</span>
                                        <span className="entry">{fight.fighter_one_ranking && <small>#{fight.fighter_one_ranking}</small>} {fight.fighter_one_firstname} {fight.fighter_one_lastname} <div className={`inline-flag ${fight.fighter_one_nationality}`}></div></span>
                                        <img
                                            src={`https://flagcdn.com/w320/${fight.fighter_one_nationality}.png`}
                                            style={{width: '90%', height: "210px", margin: '10px auto', display: 'block'}}
                                            alt={fight.fighter_one_nationality}/>
                                        {fight.fighter_one_avatar &&
                                            <img className="align-right" src={
                                                fight.fighter_one_avatar.includes("https") ?
                                                    fight.fighter_one_avatar
                                                    : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_one_avatar}`} />
                                        }
                                        <span className="data"><strong>{fight.fighter_one_wins}W</strong> | {fight.fighter_one_losses}L | {fight.fighter_one_draws}D, {fight.fighter_one_no_contests}NC | {fight.counter_one}P</span>
                                        <span className="data"><span className="capitalize">{fight.fighters}</span>, {fight.fighter_one_age} years old</span>
                                        <span className="data responsive-text-left">{fight.fighter_one_martial_arts.toUpperCase()}</span>
                                        <span className="data responsive-text-left">Odds: {fight.fighter_one_odd}</span>
                                        {fight.fight_status === "ACTIVE" && <div className="win_selecter">
                                            <div className="win_type left_b" onClick={() => this.setFightWinner(fight.id, "one", "KO/TKO")}>
                                                KO / TKO
                                            </div>
                                            <div className="win_type" onClick={() => this.setFightWinner(fight.id, "one", "submission")}>
                                                Submission
                                            </div>
                                            <div className="win_type right_b" onClick={() => this.setFightWinner(fight.id, "one", "decision")}>
                                                Decision
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="right_part h-auto">
                                        <span className="label responsive-text-right">{fight.nb_rounds} rounds</span>
                                        <span className="entry responsive-text-right">{fight.fighter_two_ranking && <small>#{fight.fighter_two_ranking}</small>} {fight.fighter_two_firstname} {fight.fighter_two_lastname} <div className={`inline-flag ${fight.fighter_two_nationality}`}></div></span>
                                        <img
                                            src={`https://flagcdn.com/w320/${fight.fighter_two_nationality}.png`}
                                            style={{width: '90%', height: "210px", margin: '10px auto', display: 'block'}}
                                            alt={fight.fighter_two_nationality}/>
                                        {fight.fighter_two_avatar &&
                                            <img className="align-right" src={
                                                fight.fighter_two_avatar.includes("https") ?
                                                    fight.fighter_two_avatar
                                                    : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_two_avatar}`} />
                                        }
                                        <span className="data responsive-text-right"><strong>{fight.fighter_two_wins}W</strong> - {fight.fighter_two_losses}L - {fight.fighter_two_draws}D, {fight.fighter_two_no_contests}NC | {fight.counter_two}P</span>
                                        <span className="data responsive-text-right"><span className="capitalize">{fight.fighters}</span>, {fight.fighter_two_age} years old</span>
                                        <span className="data responsive-text-right">{fight.fighter_two_martial_arts.toUpperCase()}</span>
                                        <span className="data responsive-text-right">Odds: {fight.fighter_two_odd}</span>
                                        {fight.fight_status === "ACTIVE" && <div className="win_selecter">
                                            <div className="win_type left_b" onClick={() => this.setFightWinner(fight.id, "two", "KO/TKO")}>
                                                KO / TKO
                                            </div>
                                            <div className="win_type" onClick={() => this.setFightWinner(fight.id, "two", "submission")}>
                                                Submission
                                            </div>
                                            <div className="win_type right_b" onClick={() => this.setFightWinner(fight.id, "two", "decision")}>
                                                Decision
                                            </div>
                                        </div>}
                                    </div>
                                    {fight.fight_status === "ACTIVE" && <div className="submit" onClick={() => this.setFightWinner(fight.id, "-", "decision")}>
                                        Draw
                                    </div>}
                                    <div className="submit ml-10" onClick={() => this.cancelFight(fight.id)}>
                                        Cancel fight
                                    </div>
                                    <div className="submit back-danger ml-10" onClick={() => this.deleteFight(fight.id)}>
                                        Delete fight
                                    </div>
                                </div>)}
                            {this.state.cancelled.map(fight =>
                                <div className="prono h-auto reduced-top" key={fight.id}>
                                    <div className="left_part h-auto">
                                        <span className="label"><strong className="danger">CANCELLED</strong> {fight.main_event ? "Main event - " : fight.co_main_event && "Co main event - "}{fight.category} {fight.championship && "CHAMPIONSHIP"}</span>
                                        <span className="entry">{fight.fighter_one_ranking && <small>#{fight.fighter_one_ranking}</small>} {fight.fighter_one_firstname} {fight.fighter_one_lastname} <div className={`inline-flag ${fight.fighter_one_nationality}`}></div></span>
                                        <span className="data"><strong>{fight.fighter_one_wins}W</strong> | {fight.fighter_one_losses}L | {fight.fighter_one_draws}D, {fight.fighter_one_no_contests}NC | {fight.counter_one}P</span>
                                        <span className="data"><span className="capitalize">{fight.fighters}</span>, {fight.fighter_one_age} years old</span>
                                        <span className="data responsive-text-left">{fight.fighter_one_martial_arts.toUpperCase()}</span>
                                        <span className="data responsive-text-left">Odds: {fight.fighter_one_odd}</span>
                                    </div>
                                    <div className="right_part h-auto">
                                        <span className="label responsive-text-right">{fight.nb_rounds} rounds</span>
                                        <span className="entry responsive-text-right">{fight.fighter_two_ranking && <small>#{fight.fighter_two_ranking}</small>} {fight.fighter_two_firstname} {fight.fighter_two_lastname} <div className={`inline-flag ${fight.fighter_two_nationality}`}></div></span>
                                        <span className="data responsive-text-right"><strong>{fight.fighter_two_wins}W</strong> - {fight.fighter_two_losses}L - {fight.fighter_two_draws}D, {fight.fighter_two_no_contests}NC | {fight.counter_two}P</span>
                                        <span className="data responsive-text-right"><span className="capitalize">{fight.fighters}</span>, {fight.fighter_two_age} years old</span>
                                        <span className="data responsive-text-right">{fight.fighter_two_martial_arts.toUpperCase()}</span>
                                        <span className="data responsive-text-right">Odds: {fight.fighter_two_odd}</span>
                                    </div>
                                    <div className="submit back-coming black" onClick={() => this.reactivateFight(fight.id)}>
                                        Reactivate fight
                                    </div>
                                </div>)}
                        </div>
                    </React.Fragment>}
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withRouter(AdminEvent);