import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import { useParams } from 'react-router-dom';
import MobileHeader from "../../components/Menu/mobile";

//Media
import { ProgressBar } from "react-loader-spinner";
import public_services from "../../services/public_services";
import { NotificationContainer, NotificationManager } from "react-notifications";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class LeagueParticipants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            league: {},
            participants: [],
            processing: true,
            copied: null,
        }
    }

    componentDidMount = async () => {
        const { innerWidth: width } = window
        this.setState({ width })
        const { id } = this.props.match.params
        let data = await public_services.leagueParticipants(id)
        if (data) {
            this.setState({ league: data.league, participants: data.participants, processing: false })
        }
        else
            NotificationManager.error("Unreferenced league")
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"leagues"} />
                <h1 className="huge starter">{this.props.t('top_participants')}</h1>
                <h4>{this.props.t('browser_league_participants')}</h4>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <h1 className="audiowide mt-10">{this.state.league.league_name}</h1>
                        <div className="participants">
                            <div className="ranking_container">
                                {this.state.participants.map((manager, index) => <div className="rank_list" key={`u_${index}`}>
                                    {index === 0 ?
                                        <div className="nb_general resized nb_one mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div> :
                                        index === 1 ?
                                            <div className="nb_general resized nb_two mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div>
                                            :
                                            index === 2 ? <div className="nb_general resized nb_three mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div>
                                                : <div className="nb_general resized mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div>}
                                    {manager.profile.avatar ?
                                        <div className="table_user" onClick={() => window.location.href=`/profile/${manager.profile.username}`} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${manager.profile.avatar}')` }}></div>
                                        :
                                        <div className="table_user" onClick={() => window.location.href=`/profile/${manager.profile.username}`} style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${manager.profile.username}')` }}></div>
                                    }
                                    <div className="info_rank">
                                        <h3 className="mb-0 mt-10">
                                            {manager.profile.username}
                                        </h3>
                                        <p className="min-ft-14 mt-0 mb-0 white">
                                            <small className="grey">{this.props.t('managing')} {manager.fighters.length} {this.props.t('fighters')}</small>
                                        </p>
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </React.Fragment>
                }
                <NotificationContainer />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(LeagueParticipants));