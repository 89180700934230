import "../../components/flags.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import FighterItem from './item';
import account_services from "../../services/account_services";
import Footer from "../../components/Footer";
import { ProgressBar } from "react-loader-spinner";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/Menu/mobile";

// Media
import WHITEGOAT from "../../assets/img/white_goat.png"
import BACKCOMMON from "../../assets/img/back_common.png"
import BACKPREMIUM from "../../assets/img/back_premium.png"
import BACKRARE from "../../assets/img/back_rare.png"
import BACKUNIQUE from "../../assets/img/back_unique.png"

class Fighters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processing: true,
            fighters: [],
            distributions: [],
            profile: null,
            display: false,
        }
    }

    componentDidMount = async () => {
        const query = new URLSearchParams(window.location.search);
        if (query.get("unlock") && query.get("unlock") === "withdraw")
            this.setState({ display: true })
        await this.loadProfile()
    }

    loadProfile = async () => {
        this.setState({ fighters: [], processing: true })
        const data = await account_services.getProfile()
        const distributions = await account_services.getPendingDistributions()
        this.setState({ fighters: data.fighters, distributions, processing: false })
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"fighters"} />
                {this.state.display && <React.Fragment>
                    <div className="import_tag">
                        {this.props.t('press_to_connect')}
                        <div className="import" onClick={() => window.location.href = "/connect"}>{this.props.t('import_fighter')}</div>
                    </div>
                </React.Fragment>}
                {!isMobile && <h1 className="starter huge">{this.props.t('manage_fighters')}</h1>}
                {!isMobile && <h4>{this.props.t('browse_and_manage')}</h4>}
                <div className="max_holder">
                    {this.state.processing &&
                        <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{ display: 'block', margin: '20px auto' }}
                            wrapperClass="progress-bar-wrapper"
                            borderColor='#000'
                            barColor='#000'
                        />
                    }
                    {this.state.distributions.map(distribution =>
                        <div key={distribution.id} className={isMobile? "card card-fighter center_top" : "card card-fighter"}>
                            <div className="info text-left">
                                <div className="reset_line">
                                    <h5>{this.props.t('your_new_fighter')}</h5>
                                    <span className="next_lvl text-left">{this.props.t('press_start_creation')}</span>
                                    <span className="label text-left">{this.props.t('weight_class')}</span>
                                    <span className="value text-left">{distribution.visual.weightclass.toUpperCase()}</span>
                                    <span className="label text-left">{this.props.t('rarity')}</span>
                                    <span className="value text-left">{distribution.rarity}</span>
                                    <span className="label text-left">{this.props.t('edition')}</span>
                                    <span className="value text-left">#{distribution.visual.counter_select} | {distribution.visual.quantity ? distribution.visual.quantity : "Unlimited"}</span>
                                    <div className="edit" onClick={() => window.location.href = `/store/create/${distribution.id}`}>
                                        {this.props.t('start_creation')}
                                    </div>
                                </div>
                            </div>
                            <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${distribution.visual.section}/${distribution.visual.fighter}/${distribution.visual.visual}.png`} alt="New fighter" className="visual" />
                        </div>
                    )}
                    {this.state.fighters.map(fighter =>
                        <FighterItem fighter={fighter} key={fighter.id} loadProfile={this.loadProfile} />
                    )}
                    {isMobile && <React.Fragment>
                        <h2 className="mt-20">{this.props.t('get_new_fighters')}</h2>
                        <div className="aura mb-0">
                            <div className="launch" onClick={() => window.location.href = "/store"}>
                                <img className="sha" src={WHITEGOAT} alt="Shadow fighter" /> {this.props.t('store')}
                            </div>
                        </div>
                    </React.Fragment>}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Fighters);