import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import MobileHeader from "../../components/Menu/mobile";
import Footer from "../../components/Footer";
import fighter_services from "../../services/fighter_services";
import token_services from "../../services/token_services";
import account_services from "../../services/account_services";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";

// Media
import BLACKGOAT from "../../assets/img/black_goat.png"
import { NotificationManager, NotificationContainer } from "react-notifications";
import { isMobile } from "react-device-detect";
import { FaCheck, FaClipboard, FaCopy, FaShareAlt } from "react-icons/fa";

class NewFighter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mfighters: [],
            wfighters: [],
            referrals: [],
            selectedFighter: null,
            width: 0,
            height: 0,
            profile: {},
            copied: false,
            limit: 5,
            unlock: false,
        }
    }

    componentDidMount = async () => {
        const profile = await token_services.getUser()
        this.setState({ profile: profile.account })
        const referrals = await account_services.getReferrals()
        this.setState({ referrals: referrals.referrals, limit: referrals.limit })
        const mfighters = await fighter_services.getFighters('male')
        const wfighters = await fighter_services.getFighters('female')
        this.setState({ mfighters, wfighters })
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({ width, height })
    }

    copyLink = async () => {
        navigator.clipboard.writeText(`https://mma-goat.com?ref_k=${this.state.profile.username}`)
        this.setState({ copied: true })
        setTimeout(() => {
            this.setState({ copied: false })
        }, 3000);
    }

    selectFighter = async (visual_id) => {
        try {
            const data = await account_services.unlockFighter(visual_id)
            window.location.href = `/store/create/${data.distribution.id}`
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `Missing ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
            this.setState({ processing: false })
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"start"} />
                <h1 className="starter huge">{this.props.t('invit_to_new_fighter')}</h1>
                {!this.state.unlock ?
                    <div className="max_holder">
                        <h4>
                            {this.props.t('get')} {this.state.limit} {this.props.t('invit_up_to_5_friends')} {this.props.t('to_select_and_build_a_new_unique_figter')}
                        </h4>
                        <div className="profile_action ft-14 right_padding d-inline-block" onClick={this.copyLink}>
                            {`https://mma-goat.com?ref_k=${this.state.profile.username}`} {!this.state.copied ?<FaCopy className="mt-2"/> : <FaCheck className="mt-2"/>}
                        </div>
                        <div className="profile_action ft-14 right_padding d-inline-block padding-left-none ml-20"  onClick={() => navigator.share({
                                text: this.props.t('invit_text'),
                                url: `https://mma-goat.com?ref_k=${this.state.profile.username}`,
                                title: `${this.state.profile.username} ${this.props.t('invit_title')}`,
                            })}>
                               <FaShareAlt/>
                        </div>
                        <div className="minifighter_folder mt-30">
                            {this.state.mfighters.slice(0, 1).map(fighter => <div className="minifighter1" key={fighter.fighter_uuid} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.section}/${fighter.fighter}/${fighter.visual}.jpg')` }}>
                            </div>)}
                            {this.state.wfighters.slice(0, 1).map(fighter => <div className="minifighter2" key={fighter.fighter_uuid} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.section}/${fighter.fighter}/${fighter.visual}.jpg')` }}></div>)}
                            {this.state.mfighters.slice(1, 2).map(fighter => <div className="minifighter3" key={fighter.fighter_uuid} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.section}/${fighter.fighter}/${fighter.visual}.jpg')` }}></div>)}
                            {this.state.wfighters.slice(1, 2).map(fighter => <div className="minifighter4" key={fighter.fighter_uuid} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.section}/${fighter.fighter}/${fighter.visual}.jpg')` }}></div>)}
                        </div>
                        <div className="editor fit-content h-auto text-left full-border-radius mt-20">
                            <span className="label mt-20">{this.props.t('onboarded_friends')}</span>
                            <h5 className="mt-5">{this.state.referrals.length} <small>| {this.state.limit}</small></h5>
                            <div className="lvl_holder back-closed">
                                <div className="lvl back-coming" style={{ width: `${100 / this.state.limit * this.state.referrals.length}%` }}></div>
                            </div>
                            {(this.state.limit - this.state.referrals.length) > 0 && <span className="next_lvl">{this.props.t('new_fighter_unlocks')} {this.state.limit - this.state.referrals.length} {this.props.t('joined_friends')}</span>}
                            {this.state.limit <= this.state.referrals.length && <div className="launch launch_white d-block mt-20 mb-20 border_mark" onClick={() => this.setState({ unlock: true })}>
                                <img className="sha" src={BLACKGOAT} alt="Black GOAT" /> {this.props.t('unlock_fighter')}
                            </div>}
                        </div>
                    </div>
                    :
                    <div className="max_holder">
                        <h4>
                            {this.props.t('select_new_fighter')}
                            <br />
                            {this.props.t('tip_weight_class')} <strong>{this.props.t('boost_point')}</strong> {this.props.t('tip_predictive')}
                        </h4>
                        {this.state.mfighters.slice(0, 2).map((fighter, index) =>
                            <React.Fragment key={fighter.id}>
                                <div className="card card-select full-border" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/male/${fighter.visual}.jpg')` }} onClick={() => this.selectFighter(fighter.id)}>
                                    <div className="weightclass">
                                        {fighter.weightclass.toUpperCase()}
                                    </div>
                                </div>
                                {index === 1 && this.state.width < 1660 && this.state.width > 940 && <br />}
                            </React.Fragment>
                        )}
                        {this.state.wfighters.slice(0, 2).map((fighter, index) =>
                            <React.Fragment key={fighter.id}>
                                <div className="card card-select full-border" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/female/${fighter.visual}.jpg')` }} onClick={() => this.selectFighter(fighter.id)}>
                                    <div className="weightclass">
                                        {fighter.weightclass.toUpperCase()}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>}
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(NewFighter);