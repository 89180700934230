import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { OPTIONS, QWRTX_MMA_GOAT_EVENTS } from "../../constants";
import events_services from "../../services/events_services";
import token_services from "../../services/token_services";
import account_services from "../../services/account_services";
import { ProgressBar } from "react-loader-spinner";
import MobileHeader from "../../components/Menu/mobile";

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            logged: false,
            processing: true,
            fighters: [],
        }
    }

    componentDidMount = async () => {
        let events = await events_services.getAllEvents()
        const user = await token_services.getUser()
        if (user) {
            this.setState({ logged: true })
            const data = await account_services.getProfile()
            this.setState({ fighters: data.fighters })
            for (let event of events) {
                let predictions = 0
                let available = data.fighters.length
                for (let fighter of data.fighters) {
                    for (let prediction of fighter.predictions) {
                        if (prediction.event.id === event.id)
                            predictions += 1
                    }
                }
                event.available = data.fighters.length - predictions
                event.predictions = predictions
            }
        }
        this.setState({ events, processing: false })
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active="events" />
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="max_holder minimizer starter">
                        {this.state.events.map(event => new Date(event.event_date) > new Date() &&
                            <div className="card card_event card-fighter" key={event.id} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${event.poster}')`, backgroundSize: 'cover' }}>

                                <div className="info text-left h-auto">
                                    {new Date(event.event_date) > new Date() ?
                                        <div className="event_status back-coming">{this.props.t('open')}</div>
                                        :
                                        <div className="event_status back-closed">{this.props.t('closed')}</div>
                                    }
                                    <h5>{event.event_name.toUpperCase()}</h5>
                                    {new Date(event.event_date) > new Date() ?
                                        <span className="label mt-0">
                                            <small className="capitalize">{new Date(event.event_date).toLocaleDateString(undefined, OPTIONS)}, {new Date(event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
                                        </span>
                                        :
                                        <span className="label mt-0">
                                            <small className="capitalize">{new Date(event.event_date).toLocaleDateString(undefined, OPTIONS)}, {new Date(event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
                                        </span>
                                    }
                                    <hr />
                                    {this.state.logged && <React.Fragment>
                                        <span className="label fontawesome mt-0 text-center">
                                            <small><strong>{event.predictions}</strong> {this.props.t(event.predictions > 1 ? 'my_active_predictions' : 'one_active_prediction')} | <strong className="ml-5">{event.available}</strong> {this.props.t(event.available > 1 ? 'my_free_fighters' : 'one_free_fighter')}</small>
                                        </span>
                                        <hr />
                                    </React.Fragment>}
                                    <span className="label text-center">{this.props.t('browse_fights')}</span>
                                    <div className="edit" onClick={() => window.location.href = this.state.logged ? `/event/${event.id}` : `/preview/${event.id}`}>
                                        {this.props.t('view_fights')}
                                    </div>
                                </div>
                            </div>)}
                        {this.state.events.map(event => new Date(event.event_date) < new Date() &&
                            <div className="card card_event card-fighter" key={event.id} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${event.poster}')`, backgroundSize: 'cover' }}>
                                <div className="info text-left h-auto">
                                    <h5>{event.event_name.toUpperCase()}</h5>
                                    {new Date(event.event_date) > new Date() ?
                                        <div className="event_status back-coming">OPEN</div>
                                        :
                                        <div className="event_status back-closed">CLOSED</div>
                                    }
                                    {new Date(event.event_date) > new Date() ?
                                        <span className="label mt-0">
                                            <small className="capitalize">{new Date(event.event_date).toLocaleDateString(undefined, OPTIONS)}, {new Date(event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
                                        </span>
                                        :
                                        <span className="label mt-0">
                                            <small className="capitalize">{new Date(event.event_date).toLocaleDateString(undefined, OPTIONS)}, {new Date(event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small>
                                        </span>
                                    }
                                    <hr />
                                    <span className="label text-center">{this.props.t('browse_fights')}</span>
                                    <div className="edit" onClick={() => window.location.href = this.state.logged ? `/event/${event.id}` : `/preview/${event.id}`}>
                                        {this.props.t('view_fights')}
                                    </div>
                                </div>
                            </div>)}
                    </div>}
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Events);