import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import MenuAdmin from '../../components/Menu/admin';
import admin_services from '../../services/admin_services';
import { ProgressBar } from "react-loader-spinner";
import { OPTIONS, QWRTX_MMA_GOAT_PLAYERS } from '../../constants';
import { FaUserInjured } from 'react-icons/fa';

class AdminUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            processing: true,
        }
    }

    componentDidMount = async () => {
        const users = await admin_services.getAllAccounts()
        this.setState({ processing: false, users })
    }

    render() {
        return (
            <React.Fragment>
                <MenuAdmin active={"users"} />
                <h1 className="huge starter">ALL USERS [{this.state.users.length}]</h1>
                <h4>Browse all users</h4>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="follower" style={{ marginBottom: "50px" }}>
                        {this.state.users.map(user =>
                            <div className="profile pointer" key={user.id} style={{ width: "900px" }} onClick={() => window.location.href = `/admin/profile/${user.username}`}>
                                {user.avatar ?
                                    <div className="big_avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${user.avatar}')` }}></div>
                                    :
                                    <div className="big_avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${user.username}')` }}></div>
                                }
                                <div className="info_profile" style={{ width: "500px" }}>
                                    <big>{user.username} | {user.goat_coins}GC</big> | {user.email}
                                    <br />
                                    <small><span className="capitalize">{new Date(user.createdAt).toLocaleDateString(undefined, OPTIONS)}</span>, {new Date(user.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</small> 
                                    {user.ref_k && <small> | referral: <strong>{user.ref_k}</strong></small>}
                                </div>
                                <div className="view ml-10">
                                    <big>{user.fighters}</big> <FaUserInjured/>
                                </div>
                                <div className="view ml-10">
                                    <big>#{user.ranking}</big>
                                </div>
                            </div>
                        )}
                    </div>}
            </React.Fragment>
        );
    }
}

export default AdminUsers;