import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import fighter_services from "../../services/fighter_services";
import token_services from "../../services/token_services";
import { ProgressBar } from "react-loader-spinner";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import MobileHeader from "../../components/Menu/mobile";
import { isMobile } from "react-device-detect";
import { NotificationContainer, NotificationManager } from 'react-notifications';

// Media
import GOATCOIN from "../../assets/img/goat_coin.png";
import account_services from "../../services/account_services";

class Store extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: false,
            fighters: [],
            processing: true,
            category: "male",
            weightclass: "FEATHERWEIGHT",
            switch: false,
            user_fighters: {},
        }
    }

    componentDidMount = async () => {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({ width, height, switch: width > 940 ? true : false })
        const user = await token_services.getUser()
        if (user){ 
            this.setState({ logged: true })
            const data = await account_services.getProfile()
            let user_fighters = {}
            for(let fghter of data.fighters){
                user_fighters[fghter.visual.id] = fghter
            }
            this.setState({user_fighters})
        }
        const fighters = await fighter_services.getPremiumFighters('male', "FEATHERWEIGHT")
        this.setState({ fighters, processing: false })
    }

    loadFighters = async (category, weightclass) => {
        this.setState({ fighters: [], processing: true })
        let fighters = []
        if ((category === "female") && ["WELTERWEIGHT", "MIDDLEWEIGHT", "LIGHTHEAVYWEIGHT", "HEAVYWEIGHT"].includes(weightclass)) {
            weightclass = "FEATHERWEIGHT"
            fighters = await fighter_services.getPremiumFighters(category, "FEATHERWEIGHT")
        } else if ((category === "male") && ["STRAWWEIGHT"].includes(weightclass)) {
            weightclass = "FEATHERWEIGHT"
            fighters = await fighter_services.getPremiumFighters(category, "FEATHERWEIGHT")
        } else {
            fighters = await fighter_services.getPremiumFighters(category, weightclass)
        }
        this.setState({ fighters, processing: false, weightclass, category })
    }

    selectFighter = async (visual_id) => {
        if (window.confirm(this.props.t('confirm_purchase'))) {
            try {
                this.setState({ processing: true })
                const resp = await account_services.purchaseFighter(visual_id)
                if (resp.status) {
                    window.location.href = `/store/create/${resp.distribution.id}`
                } else {
                    NotificationManager.error(`${this.props.t('could_not_purchase')}`)
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data) : this.props.t(e.message))
                this.setState({ processing: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"store"} />
                <h1 className="huge starter">MMA GOAT STORE</h1>
                <h4>Spend your GOAT coins to get new fighters</h4>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '10px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="max_holder">
                        <div className="text-center">
                            <div className="ranking_container">
                                <div className="tabs">
                                    <div className={this.state.category === "female" ? "tab_active" : "tab"} onClick={() => this.loadFighters("female", this.state.weightclass)}>
                                        {this.props.t('female')}
                                    </div>
                                    <div className={this.state.category === "male" ? "tab_active" : "tab"} onClick={() => this.loadFighters("male", this.state.weightclass)}>
                                        {this.props.t('male')}
                                    </div>
                                </div>
                                <select className="rank_select" onChange={(e) => this.loadFighters(this.state.category, e.target.value)}>
                                    {this.state.category === "female" &&
                                        <option value="STRAWWEIGHT" selected={this.state.weightclass === "STRAWWEIGHT"}>STRAWWEIGHT</option>}
                                    <option value="FLYWEIGHT" selected={this.state.weightclass === "FLYWEIGHT"}>FLYWEIGHT</option>
                                    <option value="BANTAMWEIGHT" selected={this.state.weightclass === "BANTAMWEIGHT"}>BANTAMWEIGHT</option>
                                    <option value="FEATHERWEIGHT" selected={this.state.weightclass === "FEATHERWEIGHT"}>FEATHERWEIGHT</option>
                                    <option value="LIGHTWEIGHT" selected={this.state.weightclass === "LIGHTWEIGHT"}>LIGHTWEIGHT</option>
                                    {this.state.category === "male" &&
                                        <option value="WELTERWEIGHT" selected={this.state.weightclass === "WELTERWEIGHT"}>WELTERWEIGHT</option>}
                                    {this.state.category === "male" &&
                                        <option value="MIDDLEWEIGHT" selected={this.state.weightclass === "MIDDLEWEIGHT"}>MIDDLEWEIGHT</option>}
                                    {this.state.category === "male" &&
                                        <option value="LIGHTHEAVYWEIGHT" selected={this.state.weightclass === "LIGHTHEAVYWEIGHT"}>LIGHTHEAVYWEIGHT</option>}
                                    {this.state.category === "male" &&
                                        <option value="HEAVYWEIGHT" selected={this.state.weightclass === "HEAVYWEIGHT"}>HEAVYWEIGHT</option>}
                                </select>
                            </div>
                        </div>
                        <div className="max_holder full text-center">
                            {this.state.fighters.length === 0 &&
                                <p><strong>{this.props.t('no_fighters')}</strong></p>
                            }
                            {this.state.fighters.map(fighter => !fighter.deactivated &&
                                <div className={isMobile ? "card full-border" : "card full-border d-inline-block mr-20"} key={fighter.id}>
                                    {(fighter.quantity - fighter.counter_select) > 0 ? <div className="abount_units">
                                        <strong>{fighter.quantity - fighter.counter_select}</strong><small>/{fighter.quantity}</small> <small>{this.props.t('available')}</small>
                                        {fighter.quantity === 10 ?
                                            <div className="aligned_rarity_tag back-premium">PREMIUM FIGHTER</div>
                                            : fighter.quantity === 100 ?
                                                <div className="aligned_rarity_tag back-coming">RARE FIGHTER</div>
                                                : fighter.quantity === 1 ?
                                                    <div className="aligned_rarity_tag back-danger">UNIQUE FIGHTER</div>
                                                    :
                                                    fighter.quantity === 1000 ?
                                                        <div className="aligned_rarity_tag back-common">COMMON FIGHTER</div>
                                                        :
                                                        <div className="aligned_rarity_tag">STARTER FIGHTER</div>}
                                        {!this.state.user_fighters[fighter.id] && <div className="purchase" onClick={() => this.state.logged ? this.selectFighter(fighter.id) : window.location.href = "/login"}> Get for {fighter.price} <img src={GOATCOIN} alt="GOAT coins" /></div>}
                                    </div>
                                        :
                                        <div className="abount_units danger">
                                            <strong>{fighter.quantity}</strong><small>/{fighter.quantity}</small> <small>{this.props.t('sold_out')}</small>
                                            {fighter.quantity === 10 ?
                                                <div className="aligned_rarity_tag back-premium">PREMIUM FIGHTER</div>
                                                : fighter.quantity === 100 ?
                                                    <div className="aligned_rarity_tag back-coming">RARE FIGHTER</div>
                                                    : fighter.quantity === 1 ?
                                                        <div className="aligned_rarity_tag back-danger">UNIQUE FIGHTER</div>
                                                        :
                                                        fighter.quantity === 1000 ?
                                                            <div className="aligned_rarity_tag back-common">COMMON FIGHTER</div>
                                                            :
                                                            <div className="aligned_rarity_tag">STARTER FIGHTER</div>}
                                        </div>
                                    }
                                    <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.section}/${fighter.fighter}/${fighter.visual}.png`} alt="Fighter visual" className="visual" />
                                </div>)}
                        </div>
                    </div>
                }
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Store);