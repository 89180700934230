import api from './api';
import TokenService from './token_services';

class AdminService {
  async signin(email, password, recaptcha) {
    return await api
      .post('/auth/admin/signin', { email, password, recaptcha })
      .then(async (response) => {
        if (response.data.access_token) {
          await TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  async logout() {
    await TokenService.removeUser();
  }

  async getCurrentUser() {
    return await TokenService.getUser();
  }

  async addEvent(event) {
    return await api
      .post('/admin/event/new', event)
      .then(async (response) => {
        return response.data;
      });
  }

  async updateEventPoster(id, poster) {
    return await api
      .post(`/admin/update/event/poster/${id}`, {poster})
      .then(async (response) => {
        return response.data.status;
      });
  }

  async updateEventBanner(id, banner) {
    return await api
      .post(`/admin/update/event/banner/${id}`, {banner})
      .then(async (response) => {
        return response.data.status;
      });
  }

  async getAllEvents() {
    return await api
      .get('/admin/events')
      .then(async (response) => {
        return response.data.events;
      });
  }

  async getEvent(id) {
    return await api
      .get(`/admin/event/${id}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async addFight(fight) {
    return await api
      .post('/admin/fight/new', fight)
      .then(async (response) => {
        return response.data;
      });
  }

  async activateEvent(id) {
    return await api
      .get(`/admin/activate/event/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async deactivateEvent(id) {
    return await api
      .get(`/admin/deactivate/event/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async cancelFight(id) {
    return await api
      .get(`/admin/cancel/fight/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async deleteFight(id) {
    return await api
      .get(`/admin/delete/fight/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async activateFight(id) {
    return await api
      .get(`/admin/activate/fight/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async setWinner(id, winner, victory) {
    return await api
      .post(`/admin/win/fight/${id}`, { winner, victory })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async notifyEvent(id) {
    return await api
      .get(`/admin/notify/event/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async getAllSubscribers() {
    return await api
      .get('/admin/subscribers')
      .then(async (response) => {
        return response.data.subscribers;
      });
  }

  async remindAllSubscribers() {
    return await api
      .get('/admin/subscribers/reminder')
      .then(async (response) => {
        return response.data;
      });
  }

  async getAllAccounts() {
    return await api
      .get('/admin/accounts')
      .then(async (response) => {
        return response.data.accounts;
      });
  }

  async getVisuals(section) {
    return await api
      .get(`/admin/visuals/${section}`)
      .then(async (response) => {
        return response.data.visuals;
      });
  }

  async distributeFighter(username, visual_id) {
    return await api
      .post(`/admin/distribute`, { username, visual_id })
      .then(async (response) => {
        return response.data;
      });
  }

  async rankUsers() {
    return await api
      .get('/admin/rank/users')
      .then(async (response) => {
        return response.data.status;
      });
  }

  async rankFighters() {
    return await api
      .get('/admin/rank/fighters')
      .then(async (response) => {
        return response.data.status;
      });
  }

  async setContest(event_id, visual_id) {
    return await api
      .post(`/admin/contest`, { event_id, visual_id })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async generateVisual() {
    return await api
      .get('/admin/make')
      .then(async (response) => {
        return response.data;
      });
  }

  async mint(visual_id) {
    return await api
      .post(`/admin/mint`, { visual_id })
      .then(async (response) => {
        return response.data;
      });
  }

  async getPredictions(id) {
    return await api
      .get(`/admin/predictions/${id}`)
      .then(async (response) => {
        return response.data.predictions;
      });
  }

  async searchFighters(fullname) {
    return await api
      .get(`/admin/search/fighters/${fullname}`)
      .then(async (response) => {
        return response.data.fighters;
      });
  }
}

// eslint-disable-next-line
export default new AdminService();
