import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { ProgressBar } from "react-loader-spinner";
import public_services from "../../services/public_services";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/Menu/mobile";
import Entry from "./entry";
import token_services from "../../services/token_services";

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            processing: true,
            profile: null,
        }
    }

    componentDidMount = async () => {
        const profile = await token_services.getUser()
        if (profile)
            this.setState({ profile: profile.account })
        this.loadNews()
    }

    loadNews = async () => {
        const news = await public_services.getNews()
        this.setState({ news, processing: false })
    }

    dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / _MS_PER_DAY) ?
            Math.floor((utc2 - utc1) / _MS_PER_DAY) === 1 ? 'Yesterday'
                :
                `${Math.floor((utc2 - utc1) / _MS_PER_DAY)} days ago`
            : 'Today';
    }

    render() {
        return (
            <React.Fragment>
                <Menu />
                <MobileHeader />
                <h1 className="huge starter">MMA News</h1>
                {!isMobile && <h4>{this.props.t('browse_news')}</h4>}
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '10px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="max_holder">
                        <div className="max_holder full text-left">
                            {this.state.news.length === 0 &&
                                <p><strong>{this.props.t('no_news')}</strong></p>
                            }
                            <div className="ranking_container">
                                {this.state.news.map((entry, index) => <Entry entry={entry} key={`t_${index}`} profile={this.state.profile} loadNews={this.loadNews}/>)}
                            </div>
                        </div>
                    </div>
                }
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(News);