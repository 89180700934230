import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { ProgressBar } from "react-loader-spinner";
import account_services from "../../services/account_services";
import { NotificationContainer, NotificationManager } from "react-notifications";
import validator from 'validator';


class InviteFriend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invits: [],
            processing: true,
        }
    }

    componentDidMount = async () => {
        await this.loadInvits()
    }

    loadInvits = async () => {
        let data = await account_services.getInvitations()
        this.setState({ invits: data.invits, processing: false })

    }

    inviteEmail = async () => {
        if (!this.state.email || !validator.isEmail(this.state.email))
            NotificationManager.warning(this.props.t('incorrect_email'))
        else {
            try {
                this.setState({ processing: true })
                const status = await account_services.inviteEmail(this.state.email)
                if (status)
                    this.loadInvits()
                else
                    NotificationManager.error(this.props.t('error_invitation'))
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data) : this.props.t(e.message))
                this.setState({ processing: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Menu active={"invite"} />
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <h1 className="huge starter">{this.props.t('invite_friends')}</h1>
                        <div className="search mt-20">
                            <span className="label ml-20">{this.props.t('set_email_invite')}</span>
                            <input type="search" className="search_editor" placeholder={this.props.t('placeholder_email')} onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />
                            <div className="edit" onClick={this.inviteEmail}>
                                {this.props.t('send_invit')}
                            </div>
                            {this.state.invits.map(invit =>
                                <div className="profile mt-20" key={invit.id}>
                                    <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/shapes/jpg?seed=${invit.email}')` }}></div>
                                    <div className="username">
                                        {invit.email} {invit.validated ? <span className="winner_tag">{this.props.t('validated').toLowerCase()}</span> : <span className="winner_tag back-primary">{this.props.t('awaiting').toLowerCase()}</span>}
                                        <br />
                                        <span className="fighters">Sent {new Date(invit.createdAt).toLocaleDateString()}</span>
                                    </div>
                                    <hr />
                                </div>
                            )}
                            {this.state.invits.length === 0 &&
                                <p><small><strong>{this.props.t('no_invitations...')}</strong></small></p>
                            }
                        </div>
                    </React.Fragment>}
                <NotificationContainer />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(InviteFriend);