import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import token_services from "../../services/token_services";
import { QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import predictions_services from "../../services/predictions_services";
import { ProgressBar } from "react-loader-spinner";
import account_services from "../../services/account_services";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/Menu/mobile";

class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            processing: true,
            predictions: [],
            profile: {},
            fighters: [],
            followers: [],
            followed: [],
            wins: 0,
            losses: 0,
            switch: "predictions",
        }
    }

    componentDidMount = async () => {
        const profile = await token_services.getUser()
        if (profile) this.setState({ profile: profile.account })
        const data = await account_services.getProfile()
        const predictions = await predictions_services.allPredictions()
        this.setState({ processing: false, predictions, fighters: data.fighters, wins: data.wins, losses: data.losses, followers: data.followers, followed: data.followed })
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"profile"} />
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <div className="big_profile">
                            {this.state.profile.avatar ?
                                <div className="avatar center_mobile" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.profile.avatar}')` }}></div>
                                :
                                <div className="avatar center_mobile" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.profile.username}')` }}></div>
                            }
                            <div className="d-inline-block">
                                <h2 className="mt-10 mb-0 center_mobile">
                                    {this.state.profile.ranking && <span className="rank_tag">#{this.state.profile.ranking}</span>} {this.state.profile.username}
                                    {/* <div className="profile_action ml-10 mt-3 d-inline-block"><FaShareAlt className="mt-3"/></div> */}
                                </h2>
                                <div className="mt-10 text-center">
                                    <div className="d-inline-block ml-10 mr-10">
                                        <span className="label mt-10 text-center">{this.props.t('managing')}</span>
                                        <span className="profile_action" onClick={() => window.location.href = "/fighters"}>{this.state.fighters.length} {this.props.t('fighters')}</span>
                                    </div>
                                    <div className="d-inline-block ml-10 mr-10">
                                        <span className="label mt-10 text-center">{this.props.t('score_card')}</span>
                                        <span className="profile_action">{this.state.wins || 0}W - {this.state.losses || 0}L</span>
                                    </div>
                                    <div className="d-inline-block ml-10 mr-10">
                                        <span className="label mt-10 text-center">{this.props.t('followers')}</span>
                                        <span className="profile_action" onClick={() => this.setState({ switch: "followers" })}>{this.state.followers.length} {this.state.followers.length > 1 ? this.props.t('followers') : this.props.t('follower')}</span>
                                    </div>
                                    <div className="d-inline-block ml-10 mr-10">
                                        <span className="label mt-10 text-center">{this.props.t('followed')}</span>
                                        <span className="profile_action" onClick={() => this.setState({ switch: "followed" })}>{this.state.followed.length} {this.props.t('followed')}</span>
                                    </div>
                                </div>
                            </div>
                            {isMobile && <hr />}
                            <div className="d-inline-block text-center mt-30 web_f-right center_mobile">
                                <div className="edit mt-20" onClick={() => window.location.href = "/profile/update"}>{this.props.t('update_account')}</div>
                                <span className="logout" onClick={() => token_services.removeUser()}>{this.props.t('logout')}</span>
                            </div>
                        </div>
                        {this.state.switch === "predictions" && <React.Fragment>
                            <h1 className="huge">{this.props.t('my_predictions')}</h1>
                            <h4>{this.props.t('passed_predictions')}</h4>
                            {this.state.predictions.length === 0 ?
                                <div className="prono_holder">
                                    <div className="d-block">
                                        <span className="next_lvl text-center">{this.props.t('time_to_start')}</span>
                                        <div className="edit" onClick={() => window.location.href = "/events"}>
                                            {this.props.t('predict')}
                                        </div>
                                    </div>
                                </div>
                                :
                                this.state.predictions.map(prediction =>
                                    <div className="prono_holder" key={prediction.id}>
                                        <div className="prono reduced-top text-center max-bottom">
                                            <span className={"label grey mt-30 mb-5 text-left ml-20"}><strong className="white">{prediction.event.event_name}</strong> - {prediction.fight.category} {prediction.fight.championship && "CHAMPIONSHIP"}</span>
                                            <div className={prediction.contestant === "one" ?
                                                prediction.active_status === "AWAITING" ? "left_part selected h-auto text-left" : prediction.active_status === "WIN" ? "left_part selected_win h-auto text-left" : prediction.active_status === "LOSS" ? "left_part selected_loss h-auto text-left" : "left_part selected_draw h-auto text-left"
                                                : "left_part h-auto text-left"}>
                                                {prediction.contestant === "one" && <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${prediction.fighter.visual.section}/${prediction.fighter.fighter}/${prediction.fighter.visual.visual}.png`} alt={prediction.fighter.nickname} className="fighter" />}
                                                <h3 className="mb-0 text-center">{prediction.fight.fighter_one_ranking && <small> #{prediction.fight.fighter_one_ranking}</small>} {prediction.fight.fighter_one_firstname} {prediction.fight.fighter_one_lastname} <div className={`inline-flag ${prediction.fight.fighter_one_nationality}`}></div></h3>
                                                <img
                                                    src={`https://flagcdn.com/w320/${prediction.fight.fighter_one_nationality}.png`}
                                                    style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                                    alt={prediction.fight.fighter_one_nationality} />
                                                {prediction.fight.fighter_one_avatar &&
                                                    <img className="align-left" src={prediction.fight.fighter_one_avatar.includes("https") ?
                                                        prediction.fight.fighter_one_avatar
                                                        : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${prediction.fight.fighter_one_avatar}`} alt="Fighter profile" />
                                                }
                                                <span className="data mt-20">
                                                    <div className="pill back-coming"><strong>{prediction.fight.fighter_one_wins}W</strong></div>
                                                    <div className="pill back-danger">{prediction.fight.fighter_one_losses}L</div>
                                                    {prediction.fight.fighter_one_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                                    {prediction.fight.winner === 'one' && <span className="winner_tag">W. <small>by {prediction.fight.victory}</small></span>} {prediction.fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}</span>
                                                <span className="data"><span className="capitalize">{this.props.t(prediction.fight.fighters)}</span>, {prediction.fight.fighter_one_age} {this.props.t('years_old')}</span>
                                                <span className="data responsive-text-left">{prediction.fight.fighter_one_martial_arts.toUpperCase()}</span>
                                                {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "AWAITING" && <div className="status back-primary">ACTIVE</div>}
                                                {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "WIN" && <div className="status back-coming">WIN</div>}
                                                {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "DRAW" && <div className="status back-black white">DRAW</div>}
                                                {prediction.contestant === "one" && prediction.fight.fighter_one_avatar && prediction.active_status === "LOSS" && <div className="status back-danger">LOSS</div>}
                                                {prediction.contestant === "one" &&
                                                    <div className={prediction.active_status === "AWAITING" ? "fighter_name back-primary" : prediction.active_status === "WIN" ? "fighter_name back-coming" : prediction.active_status === "LOSS" ? "fighter_name back-danger" : "fighter_name"}><span>{prediction.points}<small>PTS</small></span> | {prediction.fighter.nickname.toUpperCase()}
                                                        {(prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase())
                                                            && (prediction.fighter.nationality === prediction.fight.fighter_one_nationality)
                                                            && (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_one_martial_arts.toLowerCase()) ?
                                                            <small className={"ml-10"}>{prediction.booster ? prediction.booster === 1 ? "x3" : `x${2 + prediction.booster}` : "x2"}</small>
                                                            :
                                                            <small className={"ml-10"}>x{parseFloat(1 +
                                                                ((prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase() ? 4 : 0) +
                                                                    (prediction.fighter.nationality === prediction.fight.fighter_one_nationality ? 2 : 0) +
                                                                    (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_one_martial_arts.toLowerCase() ? 4 : 0)) / 10
                                                                + (prediction.booster ? prediction.booster : 0)).toFixed(1)
                                                            }</small>
                                                        }
                                                    </div>}
                                            </div>
                                            <div className={prediction.contestant === "one" && isMobile ? prediction.fight.fighter_one_avatar ? "vs mt-20" : "vs no_profile mt-20" : prediction.fight.fighter_one_avatar ? "vs" : "vs no_profile"}>vs</div>
                                            <div className={prediction.contestant === "two" ?
                                                prediction.active_status === "AWAITING" ? "right_part selected h-auto text-left" : prediction.active_status === "WIN" ? "right_part selected_win h-auto text-left" : prediction.active_status === "LOSS" ? "right_part selected_loss h-auto text-left" : "right_part selected_draw h-auto text-left"
                                                : "right_part h-auto text-left"}>
                                                {prediction.contestant === "two" && <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${prediction.fighter.visual.section}/${prediction.fighter.fighter}/${prediction.fighter.visual.visual}.png`} alt={prediction.fighter.nickname} className={isMobile ? "fighter" : "right_fighter"} />}
                                                <h3 className="mb-0 text-center">{prediction.fight.fighter_two_ranking && <small> #{prediction.fight.fighter_two_ranking}</small>} {prediction.fight.fighter_two_firstname} {prediction.fight.fighter_two_lastname} <div className={`inline-flag ${prediction.fight.fighter_two_nationality}`}></div></h3>
                                                <img
                                                    src={`https://flagcdn.com/w320/${prediction.fight.fighter_two_nationality}.png`}
                                                    style={{ width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px' }}
                                                    alt={prediction.fight.fighter_two_nationality} />
                                                {prediction.fight.fighter_two_avatar &&
                                                    <img className="align-right" src={prediction.fight.fighter_two_avatar.includes("https") ?
                                                        prediction.fight.fighter_two_avatar
                                                        : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${prediction.fight.fighter_two_avatar}`} alt="Fighter profile" />
                                                }
                                                <span className="data responsive-text-right mt-20">
                                                    {prediction.fight.fighter_two_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                                    <div className="pill back-coming"><strong>{prediction.fight.fighter_two_wins}W</strong></div>
                                                    <div className="pill back-danger">{prediction.fight.fighter_two_losses}L</div>
                                                    {prediction.fight.winner === 'two' && <span className="winner_tag">W. <small>by {prediction.fight.victory}</small></span>} {prediction.fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}
                                                </span>
                                                <span className="data responsive-text-right"><span className="capitalize">{this.props.t(prediction.fight.fighters)}</span>, {prediction.fight.fighter_two_age} {this.props.t('years_old')}</span>
                                                <span className="data responsive-text-right">{prediction.fight.fighter_two_martial_arts.toUpperCase()}</span>
                                                {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "AWAITING" && <div className="status back-primary">ACTIVE</div>}
                                                {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "WIN" && <div className="status back-coming">WIN</div>}
                                                {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "DRAW" && <div className="status back-black white">DRAW</div>}
                                                {prediction.contestant === "two" && prediction.fight.fighter_two_avatar && prediction.active_status === "LOSS" && <div className="status back-danger">LOSS</div>}
                                                {prediction.contestant === "two" &&
                                                    <div className={prediction.active_status === "AWAITING" ? "fighter_name back-primary" : prediction.active_status === "WIN" ? "fighter_name back-coming" : prediction.active_status === "LOSS" ? "fighter_name back-danger" : "fighter_name"}><span>{prediction.points}<small>PTS</small></span> | {prediction.fighter.nickname.toUpperCase()}
                                                        {(prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase())
                                                            && (prediction.fighter.nationality === prediction.fight.fighter_two_nationality)
                                                            && (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_two_martial_arts.toLowerCase()) ?
                                                            <small className={"ml-10"}>{prediction.booster ? prediction.booster === 1 ? "x3" : `x${2 + prediction.booster}` : "x2"}</small>
                                                            :
                                                            <small className={"ml-10"}>x{parseFloat(1 +
                                                                ((prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase() ? 4 : 0) +
                                                                    (prediction.fighter.nationality === prediction.fight.fighter_two_nationality ? 2 : 0) +
                                                                    (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_two_martial_arts.toLowerCase() ? 4 : 0)) / 10
                                                                + (prediction.booster ? prediction.booster : 0)).toFixed(1)
                                                            }</small>}
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </React.Fragment>}
                        {this.state.switch === "followers" &&
                            <React.Fragment>
                                <h1 className="huge">{this.props.t('followers').toUpperCase()}</h1>
                                {this.state.followers.length === 0 ?
                                    <h4>{this.props.t('no_follower')}</h4>
                                    :
                                    <h4>{this.props.t('browse_followers')}</h4>
                                }
                                <div className="follower">
                                    {this.state.followers.map(follower =>
                                        <div className="profile pointer" key={follower.follower.id} onClick={() => window.location.href = `/profile/${follower.follower.username}`}>
                                            {follower.follower.avatar ?
                                                <div className="avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${follower.follower.avatar}')` }}></div>
                                                :
                                                <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${follower.follower.username}')` }}></div>
                                            }
                                            <div className="info_profile">
                                                <big>{follower.follower.username}</big>
                                            </div>
                                            <div className="view">
                                                {this.props.t('view')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>}
                        {this.state.switch === "followed" &&
                            <React.Fragment>
                                <h1 className="huge">{this.props.t('followed').toUpperCase()}</h1>
                                {this.state.followed.length === 0 ?
                                    <h4>{this.props.t('no_followed')}</h4>
                                    :
                                    <h4>{this.props.t('browse_followed')}</h4>
                                }
                                <div className="follower">
                                    {this.state.followed.map(followed =>
                                        <div className="profile pointer" key={followed.followed.id} onClick={() => window.location.href = `/profile/${followed.followed.username}`}>
                                            {followed.followed.avatar ?
                                                <div className="avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${followed.followed.avatar}')` }}></div>
                                                :
                                                <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${followed.followed.username}')` }}></div>
                                            }
                                            <div className="info_profile">
                                                <big>{followed.followed.username}</big>
                                            </div>
                                            <div className="view">
                                                {this.props.t('view')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>}
                    </React.Fragment>}
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(UserProfile);