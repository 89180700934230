import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import MenuAdmin from '../../components/Menu/admin';
import admin_services from '../../services/admin_services';
import { ProgressBar } from "react-loader-spinner";
import { QWRTX_MMA_GOAT_FIGHTERS } from '../../constants';
import { NotificationContainer, NotificationManager } from 'react-notifications';

// Media
import WSHADOW from "../../assets/img/unselected_w.png";
import MSHADOW from "../../assets/img/unselected_m.png";
import MSSHADOW from "../../assets/img/selected_m.png";
import WSSHADOW from "../../assets/img/selected_w.png";

class AdminVisuals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visuals: [],
            category: "male",
            rarity: "starter",
            processing: true,
            events: [],
            event_id: null,
            imgData: null,
        }
    }

    componentDidMount = async () => {
        await this.loadVisuals("starter")
        const events = await admin_services.getAllEvents()
        this.setState({ events })
    }

    loadVisuals = async (rarity) => {
        this.setState({ rarity })
        const visuals = await admin_services.getVisuals(rarity)
        this.setState({ processing: false, visuals })
    }

    distribute = async (visual_id) => {
        try {
            this.setState({ processing: true })
            const resp = await admin_services.distributeFighter(this.state.username, visual_id)
            if (resp.status) {
                NotificationManager.success('Successfully distributed')
                this.loadVisuals(this.state.rarity)
            } else {
                NotificationManager.error('Could distribute')
            }
            this.setState({ processing: false })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `Missing ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
            this.setState({ processing: false })
        }
    }

    sendContest = async (visual_id) => {
        if (!this.state.event_id) {
            return NotificationManager.error('Please set an event')
        }
        try {
            this.setState({ processing: true })
            const resp = await admin_services.setContest(this.state.event_id, visual_id)
            if (resp) {
                NotificationManager.success('Successfully sent')
            } else {
                NotificationManager.error('Could sent')
            }
            this.setState({ processing: false, event_id: null })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `Missing ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
            this.setState({ processing: false })
        }
    }

    mintFighter = async (visual_id) => {
        try {
            this.setState({ processing: true })
            const resp = await admin_services.mint(visual_id)
            if (resp) {
                NotificationManager.success(`Successfully minted token ${resp.token_id}`)
            } else {
                NotificationManager.error('Could sent')
            }
            this.setState({ processing: false })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `Missing ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
            this.setState({ processing: false })
        }
    }

    makeVisual = async () => {
        const resp = await admin_services.generateVisual()
        if (resp.status) {
            this.setState({ imgData: resp.data })
        } else {
            alert('Could not generate visual')
        }
    }

    render() {
        return (
            <React.Fragment>
                <MenuAdmin active={"visuals"} />
                <h1 className="huge starter">VISUALS</h1>
                <h4>Browse visuals</h4>
                <div className="edit" onClick={this.makeVisual}>GENERATE</div>
                {this.state.imgData &&
                    <div className="max_holder">
                        <div className="card d-block mb-50"
                            style={{ borderRadius: "20px", backgroundImage: `url('data:image/jpg;base64,${this.state.imgData}')` }}>

                        </div>
                    </div>
                }
                <div className="text-center">
                    <div className="header">
                        <div className="d-inline-block">
                            <img src={this.state.category === 'male' ? MSSHADOW : MSHADOW} alt="Male filter" className="filter"
                                onMouseOut={e => (e.currentTarget.src = this.state.category === 'male' ? MSSHADOW : MSHADOW)}
                                onMouseOver={e => (e.currentTarget.src = MSSHADOW)}
                                onClick={() => this.setState({ category: "male" })} />
                            <div className="divider"></div>
                            <img src={this.state.category === 'female' ? WSSHADOW : WSHADOW} alt="Female filter" className="filter"
                                onMouseOut={e => (e.currentTarget.src = this.state.category === 'female' ? WSSHADOW : WSHADOW)}
                                onMouseOver={e => (e.currentTarget.src = WSSHADOW)}
                                onClick={() => this.setState({ category: "female" })} />
                        </div>
                        <div className="d-inline-block">
                            <h5 className="mb-0 mt-0 text-left ml-20">Rarity</h5>
                            <h4 className="mb-0 text-left">
                                Select rarity level
                                <hr />
                                <select onChange={(e) => this.loadVisuals(e.target.value)}>
                                    <option value="starter" selected={this.state.rarity === "starter"}>STARTER</option>
                                    <option value="premium" selected={this.state.rarity === "premium"}>PREMIUM</option>
                                </select>
                            </h4>
                        </div>
                    </div>
                </div>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="max_holder">
                        {this.state.visuals.map(visual => this.state.category === visual.fighter &&
                            <div key={visual.id} className="card d-block mb-50" style={{ borderRadius: "20px", backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${visual.section}/${visual.fighter}/${visual.visual}.jpg')` }}>
                                <div className="weightclass">
                                    {visual.weightclass}
                                </div>
                                <div className="info info_right text-center zone-prono">
                                    <div className="reset_line">
                                        <h5>{visual.id}</h5>
                                        <span className="label text-left">Rarity</span>
                                        <span className="value text-left">{this.state.rarity}</span>
                                        <span className="label text-left">Counter</span>
                                        <span className="value text-left">{visual.counter_select}</span>
                                        {visual.quantity && <React.Fragment>
                                            <span className="label text-left">Quantity</span>
                                            <span className="value text-left">{visual.quantity}</span>
                                        </React.Fragment>}
                                        {visual.price && <React.Fragment>
                                            <span className="label text-left">Price</span>
                                            <span className="value text-left">{visual.price}<small>EUR</small></span>
                                        </React.Fragment>}
                                        <hr />
                                        <input type="text" className="text_editor d-block" placeholder="Username" onChange={(e) => this.setState({ username: e.target.value })} style={{ width: "200px" }} />
                                        <div className="edit" onClick={() => this.distribute(visual.id)}>
                                            Distribute
                                        </div>
                                        <hr />
                                        <select className="text_editor" onChange={(e) => this.setState({ event_id: e.target.value })} style={{ width: "100%" }}>
                                            <option selected>Select an event</option>
                                            {this.state.events.map(event => (new Date(event.event_date) > new Date()) &&
                                                <option key={event.id} value={event.id}>{event.event_name}</option>
                                            )}
                                        </select>
                                        <div className="edit" onClick={() => this.sendContest(visual.id)}>
                                            Set for contest
                                        </div>
                                        {(!visual.quantity || (visual.quantity > visual.counter_select)) && <React.Fragment>
                                            <hr />
                                            <div className="edit" onClick={() => { if (window.confirm("mint the fighter?")) { this.mintFighter(visual.id) } }}>
                                                Mint
                                            </div>
                                        </React.Fragment>}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default AdminVisuals;