import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import Footer from "../../components/Footer";
import { EXPLORER, QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import account_services from "../../services/account_services";
import chain_services from "../../services/chain_services";
import MobileHeader from "../../components/Menu/mobile";

// Media
import POLYGON from "../../assets/img/polygon.png"
import { ProgressBar } from "react-loader-spinner";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class WithdrawFighter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fighter: null,
            fighter_id: null,
            eth_address: null,
            mint_op: null,
            checked: false,
            token_id: null,
        }
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const fighter = await account_services.getFighterDetails(id)
        this.setState({ fighter, fighter_id: id })
        if(fighter.owner)
            this.setState({eth_address: fighter.owner})
    }

    withdrawFighter = async () => {
        if (!this.state.eth_address || (this.state.eth_address.length !== 42))
            NotificationManager.warning(this.props.t('incorrect_wallet_address'))
        else if (!this.state.checked)
            NotificationManager.warning(this.props.t('check_validation'))
        else {
            try {
                this.setState({ processing: true })
                const resp = await chain_services.withdrawFighter(this.state.fighter.id, this.state.eth_address)
                console.log(resp)
                if (resp.status) {
                    this.setState({ mint_op: resp.mint_op, token_id: resp.token_id })
                } else {
                    NotificationManager.error(`${this.props.t('could_not_withdrawn')} ${this.state.eth_address}`)
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data) : this.props.t(e.message))
                this.setState({ processing: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"store"} />
                <h1 className="starter huge">{this.props.t('withdraw_fighter')}</h1>
                <h4>
                    {this.props.t('set_polygon')}
                </h4>
                {this.state.fighter && <div className="max_holder text-center">
                    <div className="editor text-left left_shadow">
                        <h3>{this.props.t('withdraw_polygon_net')}</h3>
                        {this.state.fighter.token_id && 
                            <h5>Token ID : {this.state.fighter.token_id}</h5>
                        }
                        <span className="label">
                            {this.props.t('get_fighter_polygon')}
                            <br />
                            <small>{this.props.t('learn_more_about')} <a href="https://polygon.technology/" target="_bank" rel="noreferrer">{this.props.t('polygon_blockchain')}</a>.</small>
                        </span>
                        <img src={POLYGON} className="mt-10" style={{ width: "130px", cursor: "pointer" }} onClick={() => window.open("https://polygon.technology/")} />
                        {!this.state.fighter.owner && <span className="tips ml-0">
                            {this.props.t('precautions')}
                            <br />
                            {this.props.t('hence')} <strong className="black">{this.props.t('sole_owner')}</strong>{this.props.t('and_')} <strong className="black">{this.props.t('not_able_transfer')}</strong>.
                        </span>}
                        <input type="text" className="text_editor" placeholder={this.props.t('placeholder_eth_address')}
                            onChange={(e) => this.setState({ eth_address: e.target.value })} value={this.state.eth_address} disabled={this.state.fighter.owner}/>
                        {!this.state.fighter.owner && <span className="tips ml-0">
                            {this.props.t('no_wallet')} <a href="https://metamask.io/download/" target="_bank" rel="noreferrer">Metamask</a>
                        </span>}
                        <span className="tips mt-10 ml-0">
                            {this.props.t('once_withdrawn')}
                            <br />
                            {this.props.t('import_section')}
                        </span>
                        <span className="label"> <input type="checkbox" onClick={() => this.setState({ checked: !this.state.checked })} /> <small>{this.props.t('understand')} <a href="https://www.forbes.com/advisor/investing/cryptocurrency/nft-non-fungible-token/" target="_bank" rel="noreferrer">{this.props.t('what_is_nft')}</a> {this.props.t('full_responsability')}</small></span>
                        {this.state.processing ?
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{ margin: '0 10px' }}
                                wrapperClass="progress-bar-wrapper"
                                borderColor='#000'
                                barColor='#000'
                            />
                            :
                            this.state.mint_op ?
                                <div>
                                    <hr/>
                                    <span className="label ml-15"><small>{this.props.t('op_success')}</small></span>
                                    <span className="winner_tag pointer" onClick={() => window.open(`${EXPLORER}/tx/${this.state.mint_op}`)}><small>Tx: {this.state.mint_op.substring(0, 10)}...{this.state.mint_op.substring((this.state.mint_op.length - 20), this.state.mint_op.length)} | Token ID : {this.state.token_id}</small></span>
                                </div>
                                :
                                <div className="submit" onClick={this.withdrawFighter}>
                                    {this.props.t('withdraw_fighter_wallet')}
                                </div>}
                    </div>
                    <div className="card-editor" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${this.state.fighter.visual.section}/${this.state.fighter.visual.fighter}/${this.state.fighter.visual.visual}.jpg')` }}>
                        {this.state.fighter.nickname && this.state.fighter.nickname.length > 3 && <div className="fighter_name" style={{ fontSize: `${680 / this.state.fighter.nickname.length + (this.state.fighter.nickname.length - 6) * .58}px`, color: this.state.fighter.visual.text_color }}>
                            {this.state.fighter.nickname}
                        </div>}
                        <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${this.state.fighter.visual.section}/${this.state.fighter.visual.fighter}/${this.state.fighter.visual.visual}.png`} alt={this.state.fighter.nickname} className="visual" />
                    </div>
                </div>}
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(WithdrawFighter));