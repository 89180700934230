import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import FighterItem from './nft';
import Footer from "../../components/Footer";
import { ProgressBar } from "react-loader-spinner";
import { ethers } from "ethers";
import detectEthereumProvider from '@metamask/detect-provider';

//Media
import METAMASK from "../../assets/img/metamask.png"
import chain_services from '../../services/chain_services';
import token_services from '../../services/token_services';
import { CHAIN_ID } from '../../constants';

class MetaMask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            processing: false,
            address: "",
            balance: null,
            fighters: [],
        }
    }
    
    btnhandler = async () => {
        const provider = await detectEthereumProvider()
        if(provider) {
            console.log(provider)
            console.log(provider.networkVersion)
            if (provider.networkVersion !== CHAIN_ID) {
                try {
                    await provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: CHAIN_ID }]
                    });
                } catch (err) {
                    if (err.code === 4902) {
                        await provider.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainName: 'Polygon Mainnet',
                                    chainId: "0x89",
                                    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                                    rpcUrls: ['https://polygon-rpc.com/']
                                }
                            ]
                        });
                    }
                }
            }
            provider
                .request({ method: "eth_requestAccounts" })
                .then((res) => this.accountChangeHandler(res[0]));
        } else {
            NotificationManager.error(this.props.t('metamask_not_found'))
        }
    }

    getbalance = async (address) => {
        window.ethereum
            .request({
                method: "eth_getBalance",
                params: [address, "latest"]
            })
            .then((balance) => {
                this.setState({
                    address,
                    balance: ethers.formatEther(balance),
                });
            });
        const fighters = await chain_services.listFighters(address)
        console.log(fighters)
        this.setState({ loading: false, fighters })
    }

    accountChangeHandler = (account) => {
        this.setState({
            address: account,
            loading: true,
        });
        this.getbalance(account);
    }

    handleSign = async (token_id, nickname) => {
        try {
            const user = await token_services.getUser()
            const provider = new ethers.BrowserProvider(await detectEthereumProvider())
            const timestamp = new Date()
            const message = `${token_id} ${timestamp.toISOString().substring(0, 10)} ${nickname} ${this.state.address.toLocaleLowerCase()} ${user.account.id}`;
            const signer = await provider.getSigner();
            const signature = await signer.signMessage(message);
            console.log("sig : " + signature);
            this.setState({ loading: true, processing: true })
            const resp = await chain_services.importFighter(this.state.address.toLocaleLowerCase(), token_id, signature)
            if (resp.status) {
                NotificationManager.success(this.props.t('import_successful'))
                setTimeout(() => {
                    window.location.href = "/fighters"
                }, 1500);
            } else {
                NotificationManager.error(this.props.t('could_not_import'))
                this.setState({ loading: false, processing: false })
            }
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data) : this.props.t(e.message))
            this.setState({ loading: false, processing: false })
        }
    }

    render() {
        return (
            <React.Fragment>
                <Menu active={"fighters"} />
                {!this.state.address ? <React.Fragment>
                    <h1 className="starter huge">{this.props.t('connect_metamask')}</h1>
                    <h4>{this.props.t('connect_desc')}</h4>
                    <div className="aura" onClick={this.btnhandler}>
                        <div className="launch">
                            <img className="sha" src={METAMASK} alt="METAMASK" /> {this.props.t('connect_metamask')}
                        </div>
                    </div>
                    <Footer />
                    <NotificationContainer />
                </React.Fragment>
                    :
                    <React.Fragment>
                        <h1 className="starter huge">{this.props.t('browse_available_fighters')}</h1>
                        <h4>
                            <small>{this.state.address} {this.props.t('connected')}</small>
                            <br />
                            {this.state.fighters.length > 0 ?
                                <strong>{this.props.t('select_fighter')}</strong>
                                :
                                !this.state.loading && <strong>No MMA GOAT NFT fighter found in the wallet</strong>}
                        </h4>
                        {this.state.processing && <h4>Please wait... Import in progress. It may take up to 1 minute.</h4>}
                        {this.state.loading ?
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{ display: 'block', margin: '20px auto' }}
                                wrapperClass="progress-bar-wrapper"
                                borderColor='#000'
                                barColor='#000'
                            />
                            :
                            <div className="max_holder">
                                {this.state.fighters.map(fighter =>
                                    <FighterItem fighter={fighter} key={fighter.id} handleSign={this.handleSign} />
                                )}
                            </div>}
                    </React.Fragment>
                }
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(MetaMask);