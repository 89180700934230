import api from './api';

class FighterService {
    async getFighters(category) {
        return await api
            .get(`/fighters/start/${category}`)
            .then(async (response) => {
                return response.data.fighters;
            });
    }

    async checkNickname(nickname) {
        return await api
            .post('/fighters/nickname', { nickname })
            .then(async (response) => {
                return response.data.status;
            });
    }

    async getPound4PoundFighters(category) {
        return await api
            .get(`/fighters/ranking/pound4pound/${category}`)
            .then(async (response) => {
                return response.data.fighters;
            });
    }

    async getTopFighters(category) {
        return await api
            .get(`/fighters/ranking/top/${category}`)
            .then(async (response) => {
                return response.data.fighters;
            });
    }

    async getRankedFighters(category, weightclass) {
        return await api
            .get(`/fighters/ranking/weightclass/${weightclass}/${category}`)
            .then(async (response) => {
                return response.data.fighters;
            });
    }

    async getPremiumFighters(category, weightclass) {
        return await api
            .get(`/fighters/premium/weightclass/${weightclass}/${category}`)
            .then(async (response) => {
                return response.data.fighters;
            });
    }
}

// eslint-disable-next-line
export default new FighterService();
