import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import validator from 'validator';
import admin_services from "../../services/admin_services";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ProgressBar } from 'react-loader-spinner';
import { SITE_KEY } from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";

class AdminAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            success: false,
            processing: false,
            captcha: null,
        }
    }

    onChange = (value) => {
        this.setState({ captcha: value })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.signIn()
        }
    }

    signIn = async () => {
        if (!this.state.password || this.state.password.length < 4 || this.state.password.length > 25 ||
            !validator.isEmail(this.state.email))
            NotificationManager.warning("Fill all required info")
        else {
            try {
                this.setState({ processing: true })
                const resp = await admin_services.signin(
                    this.state.email,
                    this.state.password,
                    this.state.captcha,
                )
                if (resp.status) {
                    window.location.href = "/admin/events"
                } else {
                    NotificationManager.error('Could not sign in')
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `Missing field ${e.response.data.errors[0].path}` : e.message)
                this.setState({ processing: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <h1 className="starter huge">ADMINISTRATION</h1>
                <h4>
                    Set administration credentials
                </h4>
                <div className="max_holder text-center">
                    <div className="editor fit-content h-auto mt-20 full-border-radius simple_border_mark">
                        <span className="label text-left">
                            Email
                        </span>
                        <input type="email" className="text_editor d-block" placeholder={"Set your email"} onChange={(e) => this.setState({ email: e.target.value })} />
                        <span className="label text-left mt-20">
                            Password
                        </span>
                        <input type="password" className="text_editor d-block" placeholder={"Set your password"} onKeyDown={this._handleKeyDown} onChange={(e) => this.setState({ password: e.target.value })} />
                        <ReCAPTCHA
                            sitekey={SITE_KEY}
                            onChange={this.onChange}
                        />
                        {!this.state.processing && !this.state.success && <div className="submit d-block" onClick={this.signIn}>
                            Sign in
                        </div>}
                        {this.state.processing &&
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{ margin: '0 20px' }}
                                wrapperClass="progress-bar-wrapper"
                                borderColor='#000'
                                barColor='#000'
                            />
                        }
                    </div>
                </div>
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default AdminAuth;