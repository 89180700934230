import "./menu.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FaCalendar, FaInstagram, FaSearch, FaShoppingBag, FaSortNumericUp, FaTimes, FaUserPlus, FaUsers } from "react-icons/fa";

// Media
import LOGO from "../../assets/img/mma_goat_logo_white.png"
import { QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import token_services from "../../services/token_services";
import account_services from "../../services/account_services";
import InstallPWA from "./InstallPWA ";
import InstalliOS from "./InstalliOS";
import InstallPWAMobile from "./InstallPWAMobile";
import InstalliOSMobile from "./InstalliOSMobile";
import { isMobile } from "react-device-detect";
import WHITEFIGHTER from "../../assets/img/min_shadow.png";
import GREENFIGHTER from "../../assets/img/min_shadow_active.png";
import WHITEGOAT from "../../assets/img/white_goat.png";
import GOATCOIN from "../../assets/img/goat_coin.png"

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            loading: true,
            profile: null,
            wins: "-",
            losses: "-",
            width: 0,
            height: 0,
            distributions: 0,
            isWebview: true,
        }
    }

    isWebview = () => {
        const navigator = window.navigator
        const userAgent = navigator.userAgent
        const normalizedUserAgent = userAgent.toLowerCase()
        const standalone = navigator.standalone

        const isIos =
            /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
        const isAndroid = /android/.test(normalizedUserAgent)
        const isSafari = /safari/.test(normalizedUserAgent)
        const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari)
        this.setState({ isWebview })
    }

    componentDidMount = async () => {
        this.isWebview()
        const { innerWidth: width, innerHeight: height } = window
        this.setState({ width, height })
        const profile = await token_services.getUser()
        if (profile) {
            this.setState({ profile: profile.account, loading: false })
            const data = await account_services.count()
            this.setState({ wins: data.wins, losses: data.losses })
            const distribs = await account_services.getPendingDistributions()
            this.setState({ distributions: distribs.length })
        } else this.setState({ loading: false })
    }

    render() {
        return (
            <div className="menu_holder">
                {isMobile ?
                    !this.state.isWebview &&
                    <div className="menu">
                        {this.state.profile && this.props.active === "profile" &&
                            <div className="invite_mobile" onClick={() => window.location.href = "/newfighter"}>
                                {this.props.t('get_new_fighter')}
                            </div>
                        }
                        {this.state.profile ?
                            <div className="mobile_entry" onClick={() => window.location.href = "/fighters"}>
                                <img src={["fighters", "start"].includes(this.props.active) ? GREENFIGHTER : WHITEFIGHTER} alt="MMA-GOAT logo" className="mobile_logo" />
                            </div>
                            :
                            <div className="super_mobile_entry">
                                <div className="main_action" onClick={() => window.location.href = "/start/1"}>
                                    <img className="sha" src={WHITEGOAT} alt="Black GOAT" /> Start now
                                </div>
                            </div>
                        }
                        <div className={this.props.active === "events" ? "mobile_entry active" : "mobile_entry"} onClick={() => window.location.href = "/events"}>
                            <FaCalendar />
                        </div>
                        <div className={this.props.active === "rankings" ? "mobile_entry active" : "mobile_entry"} onClick={() => window.location.href = "/rankings"}>
                            <FaSortNumericUp />
                        </div>
                        {this.state.profile ?
                            <div className={this.props.active === "leagues" ? "mobile_entry active" : "mobile_entry"} onClick={() => window.location.href = "/leagues"}>
                                <FaUsers />
                            </div>
                            :
                            <div className={this.props.active === "store" ? "mobile_entry active" : "mobile_entry"} onClick={() => window.location.href = "/store"}>
                                <FaShoppingBag />
                            </div>
                        }
                        <div className={this.props.active === "search" ? "mobile_entry active" : "mobile_entry"} onClick={() => window.location.href = `/search`}>
                            <FaSearch />
                        </div>
                    </div>
                    :
                    <div className="menu">
                        {this.state.profile &&
                            <div className="invite" onClick={() => window.location.href = "/newfighter"}>
                                {this.props.t('get_new_fighter')}
                            </div>
                        }
                        <img src={LOGO} alt="MMA legends logo" className="logo" onClick={() => window.location.href = !this.state.profile ? "/" : "/whatsnew"} />
                        <div className={this.props.active === "events" ? "entry active" : "entry"} onClick={() => window.location.href = "/events"}>
                            {this.props.t('events')}
                        </div>
                        <div className={this.props.active === "rankings" ? "entry active" : "entry"} onClick={() => window.location.href = "/rankings"}>
                            {this.props.t('rankings')}
                        </div>
                        <div className={this.props.active === "store" ? "ml-20 social coming" : "ml-20 social"} onClick={() => window.location.href = "/store"}>
                            <FaShoppingBag />
                        </div>
                        <div className={this.props.active === "search" ? "social coming" : "social"} onClick={() => window.location.href = "/search"}>
                            <FaSearch />
                        </div>
                        <div className="social" onClick={() => window.open("https://www.instagram.com/mmagoatgame/")}>
                            <FaInstagram />
                        </div>
                        <InstallPWA />
                        <InstalliOS />
                        {this.state.profile && <div className={this.props.active === "fighters" ? "entry active" : "entry"} onClick={() => window.location.href = "/fighters"}>
                            {this.props.t('fighters')} {this.state.distributions ? <div className="note">{this.state.distributions}</div> : ""}
                        </div>}
                        {this.state.profile && <div className={this.props.active === "leagues" ? "entry active" : "entry"} onClick={() => window.location.href = "/leagues"}>
                            {this.props.t('leagues')}
                        </div>}
                        {!this.state.profile && !this.state.loading && <div className={this.props.active === "start" ? "entry active" : "entry"} onClick={() => window.location.href = "/start/1"}>
                            {this.props.t('start_now')}
                        </div>}
                        {!this.state.profile && !this.state.loading && <div className={this.props.active === "login" ? "entry active" : "entry"} onClick={() => window.location.href = "/login"}>
                            {this.props.t('sign_in')}
                        </div>}
                        {this.state.profile &&
                            <div className="profile hide_mobile" onClick={() => window.location.href = `/profile/${this.state.profile.username}`}>
                                {this.state.width > 1000 && <div className="info_profile">
                                    <strong>{this.state.profile.username}</strong>
                                    <span className="goat_coins">{this.state.profile.goat_coins} <img src={GOATCOIN} alt="GOAT coins" /></span>
                                </div>}
                                {this.state.profile.avatar ?
                                    <div className="avatar" style={{ backgroundImage: `url(https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.profile.avatar})` }}></div>
                                    :
                                    <div className="avatar" style={{ backgroundImage: `url(https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.profile.username})` }}></div>
                                }
                            </div>
                        }
                        {!this.state.toggle ? <div className="mobile" onClick={() => this.setState({ toggle: !this.state.toggle })}>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                        </div>
                            : <div className="closing" onClick={() => this.setState({ toggle: !this.state.toggle })}>
                                <FaTimes />
                            </div>
                        }
                        {this.state.toggle && <div className="pop_mobile">
                            <div className={this.props.active === "predictions" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/events"}>
                                {this.props.t('events')}
                            </div>
                            <div className={this.props.active === "rankings" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/rankings"}>
                                {this.props.t('rankings')}
                            </div>
                            <div className={this.props.active === "store" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/store"}>
                                {this.props.t('store')}
                            </div>
                            <div className={this.props.active === "search" ? "pop_entry black" : "pop_entry"} onClick={() => window.location.href = "/search"}>
                                <FaSearch />
                            </div>
                            <div className="pop_entry" onClick={() => window.open("https://www.instagram.com/mmagoatgame/")}>
                                <FaInstagram />
                            </div>
                            <InstallPWAMobile />
                            <InstalliOSMobile />
                            {this.state.profile && <div className={this.props.active === "fighters" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/fighters"}>
                                {this.props.t('fighters')}
                            </div>}
                            {this.state.profile && <div className={this.props.active === "leagues" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/leagues"}>
                                {this.props.t('leagues')}
                            </div>}
                            {!this.state.profile && <div className={this.props.active === "start" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/start/1"}>
                                {this.props.t('start_now')}
                            </div>}
                            {!this.state.profile && <div className={this.props.active === "login" ? "pop_entry active" : "pop_entry"} onClick={() => window.location.href = "/login"}>
                                {this.props.t('sign_in')}
                            </div>}
                            {this.state.profile &&
                                <div className="profile d-block" onClick={() => window.location.href = `/profile/${this.state.profile.username}`}>
                                    <div className="info_profile">
                                        <strong>{this.state.profile.username}</strong>
                                        <br />
                                        <small>{this.state.profile.goat_coins}</small>
                                    </div>
                                    {this.state.profile.avatar ?
                                        <div className="avatar" style={{ backgroundImage: `url(https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.profile.avatar})` }}></div>
                                        :
                                        <div className="avatar" style={{ backgroundImage: `url(https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.username})` }}></div>
                                    }
                                </div>
                            }
                        </div>}
                    </div>}
            </div>
        );
    }
}

export default withTranslation()(Menu);