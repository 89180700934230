import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import MobileHeader from "../../components/Menu/mobile";

// Media
import Footer from "../../components/Footer";
import { countries } from "../../components/countries";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import fighter_services from "../../services/fighter_services";
import account_services from "../../services/account_services";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class StoreCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            distrib_id: null,
            distribution: {},
            category: "male",
            martialArt: null,
            firstname: "",
            lastname: "",
            nickname: "",
            nationality: null,
        }
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const distribution = await account_services.getPendingDistribution(id)
        this.setState({distribution, category: distribution.visual.fighter, distrib_id: id})
    }

    saveFighter = async () => {
        if (!this.state.nickname || this.state.nickname.length < 4 || this.state.nickname.length > 25 ||
            !this.state.firstname || this.state.firstname.length < 2 || this.state.firstname.length > 25 ||
            !this.state.lastname || this.state.lastname.length < 2 || this.state.lastname.length > 25 ||
            !this.state.nationality || !this.state.martialArt)
            NotificationManager.warning(this.props.t('request_fill_all'))
        else {
            const status = await fighter_services.checkNickname(this.state.nickname)
            if (status) {
                NotificationManager.warning(`${this.state.nickname} ${this.props.t('already_exists')}`)
            } else {
                let fighter = {
                    distribution: this.state.distrib_id,
                    martial_art: this.state.martialArt,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    nickname: this.state.nickname,
                    nationality: this.state.nationality
                }
                window.localStorage.setItem('mma_goat_purchase', JSON.stringify(fighter))
                window.location.href = "/store/confirm"
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"store"} />
                <h1 className="starter huge">
                    {this.props.t('edit')}
                    <br/>
                    {this.props.t('fighter')}
                </h1>
                <h4>
                    {this.props.t('set_profile_fighter')}
                    <br />
                    {this.state.category === "male" ? this.props.t('his_unique_profile') : this.props.t('her_unique_profile')}
                </h4>
                {this.state.distribution.visual && <div className="max_holder text-center">
                    <div className="editor text-left left_shadow">
                        <span className="label">
                            {this.props.t('martial_art')}
                        </span>
                        <select className="text_editor" onChange={(e) => this.setState({ martialArt: e.target.value })}>
                            {this.state.martialArt ?
                                <option value={this.state.martialArt} selected>{this.state.martialArt}</option>
                                :
                                <option selected disabled>{this.props.t('select_martial_art')}</option>}
                            <option value="Judo">Judo</option>
                            <option value="Karate">Karate</option>
                            <option value="Boxer">Boxer</option>
                            <option value="Kickboxer">Kickboxer</option>
                            <option value="Tae kwon do">Tae kwon do</option>
                            <option value="Jiu jitsu">Jiu jitsu</option>
                            <option value="Muay Thai">Muay Thai</option>
                            <option value="Wrestler">Wrestler</option>
                        </select>
                        <span className="tips"> x1.4 {this.props.t('bonus_matching_style')}</span>
                        <span className="label">
                            {this.props.t('firstname')}
                        </span>
                        <input type="text" className="text_editor" placeholder={this.props.t('placeholder_firstname')}
                            onChange={(e) => this.setState({ firstname: e.target.value })} value={this.state.firstname} />
                        <span className="label">
                            {this.props.t('lastname')}
                        </span>
                        <input type="text" className="text_editor" placeholder={this.props.t('placeholder_lastname')}
                            onChange={(e) => this.setState({ lastname: e.target.value })} value={this.state.lastname} />
                        <span className="label">
                            {this.props.t('nickname')}
                        </span>
                        <input type="text" className="text_editor" placeholder={this.props.t('placeholder_nickname')}
                            onChange={(e) => this.setState({ nickname: e.target.value })} value={this.state.nickname && this.state.nickname.toLowerCase()} />
                        <span className="label">
                            {this.props.t('nationality')}
                        </span>
                        <select className="text_editor" onChange={(e) => this.setState({ nationality: e.target.value })}>
                            {this.state.nationality ?
                                <option value={this.state.nationality} selected>{countries[this.state.nationality.toUpperCase()]}</option>
                                :
                                <option selected disabled>{this.props.t('select_nationality')}</option>}
                            {Object.keys(countries).map(key =>
                                <option key={key} value={key.toLocaleLowerCase()}>{countries[key]}</option>
                            )}
                        </select>
                        {this.state.nationality && <div className={`middle-flag ${this.state.nationality}`}></div>}
                        <span className="tips"> x1.2 {this.props.t('bonus_matching_nationality')}</span>
                        <div className="submit" onClick={this.saveFighter}>
                            {this.props.t('validate_profile')}
                        </div>
                    </div>
                    <div className="card-editor" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${this.state.distribution.visual.section}/${this.state.distribution.visual.fighter}/${this.state.distribution.visual.visual}.jpg')` }}>
                        {this.state.nickname && this.state.nickname.length > 3 && <div className="fighter_name" style={{ fontSize: `${680 / this.state.nickname.length + (this.state.nickname.length - 6) * .58}px`, color: this.state.distribution.visual.text_color }}>
                            {this.state.nickname}
                        </div>}
                        <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${this.state.distribution.visual.section}/${this.state.distribution.visual.fighter}/${this.state.distribution.visual.visual}.png`} alt={this.state.nickname} className="visual" />
                    </div>
                </div>}
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(StoreCreate));