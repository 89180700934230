import "../../components/flags.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { countries } from "../../components/countries"

const flippedCountries = Object.entries(countries)
    .reduce((obj, [key, value]) => ({ ...obj, [value]: key }), {});

class FighterItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            switch: "Import",
        }
    }

    render() {
        return (
            <div>
                <div className="card card-fighter" style={{ backgroundImage: `url('${this.props.fighter.image_original_url}')` }}>
                    {this.state.switch === "Profile" ?
                        <div className="info text-left">
                            <h5>Import fighter</h5>
                            <React.Fragment>
                                <span className="next_lvl text-center">
                                    {this.props.t('sign_metamask')}
                                </span>
                                <span className="next_lvl text-center">
                                    Token ID : <strong>{this.props.fighter.token_id}</strong>
                                </span>
                                <div className="edit">
                                    {this.props.t('sign_and_import')}
                                </div>
                            </React.Fragment>
                        </div>
                        :
                        <div className="info text-left">
                            {this.props.fighter.traits.map(trait => trait.trait_type === "nationality" &&
                                <div key={trait.trait_type}
                                    className={`flag ${flippedCountries[trait.value].toLowerCase()}`}
                                    title={trait.value}>
                                </div>
                            )}
                            {this.props.fighter.traits.map(trait => trait.trait_type === "gender" &&
                                <h5 key={trait.trait_type}>
                                    {this.props.t(`${trait.value}_fighter`)}
                                </h5>
                            )}
                            {this.props.fighter.traits.map(trait => ["style", "martial_art"].includes(trait.trait_type) &&
                                <React.Fragment>
                                    <span className="label">{this.props.t('martial_art')}</span>
                                    <span key={trait.trait_type} className="value">
                                        {trait.value.toUpperCase()}
                                    </span>
                                </React.Fragment>
                            )}
                            {!this.props.fighter.new &&
                                <React.Fragment>
                                    <span className="label">{this.props.t('fullname')}</span>
                                    <span className="value">{this.props.fighter.description.split(' "')[0]} {this.props.fighter.description.split('" ')[1].split(' |')[0]}</span>
                                </React.Fragment>
                            }
                            <span className="label">{this.props.t('nickname')}</span>
                            <span className="value">{this.props.fighter.name.toLowerCase()}</span>
                            {this.props.fighter.traits.map(trait => trait.trait_type === "level" &&
                                <h5 key={trait.trait_type} className="mb-0">
                                    {this.props.t('level')} {trait.value}
                                    <span className="ml-10 ft-14">
                                        {this.props.fighter.traits.map(trait => trait.trait_type === "win" &&
                                            <span key={trait.trait_type}>
                                                {trait.value}W
                                            </span>)}
                                        <span className="ml-2">-</span>
                                        {this.props.fighter.traits.map(trait => trait.trait_type === "loss" &&
                                            <span key={trait.trait_type}>
                                                {trait.value}L
                                            </span>)}
                                    </span>
                                </h5>
                            )}
                            <span className="label">{this.props.t('total_points')}</span>
                            {this.props.fighter.traits.map(trait => trait.trait_type === "points" &&
                                <span className="value" key={trait.trait_type}>{trait.value} points</span>)}
                            <span className="label">{this.props.t('token_id')}</span>
                            <span className="value">{this.props.fighter.token_id}</span>
                        </div>}
                    <div className="info info_right text-center zone-prono">
                        {this.props.fighter.new ?
                            <div className="reset_line">
                                <h5>Sign to import new fighter</h5>
                                <React.Fragment>
                                    <span className="next_lvl text-center">
                                        {this.props.t('sign_to_import')}
                                    </span>
                                    <div className="edit" onClick={() => this.props.handleSign(this.props.fighter.token_id, this.props.fighter.name.toLowerCase())}>
                                        {this.props.t('sign_and_import')}
                                    </div>
                                </React.Fragment>
                            </div>
                            :
                            <div className="reset_line">
                                <h5>{this.props.t('import_fighter')}</h5>
                                <React.Fragment>
                                    <span className="next_lvl text-center">
                                        {this.props.t('sign_to_import')}
                                    </span>
                                    <div className="edit" onClick={() => this.props.handleSign(this.props.fighter.token_id, this.props.fighter.name.toLowerCase())}>
                                        {this.props.t('sign_and_import')}
                                    </div>
                                </React.Fragment>
                            </div>}
                    </div>
                    {this.props.fighter.traits.map(trait => trait.trait_type === "weightclass" &&
                        <div key={trait.trait_type} className="weightclass">
                            {trait.value}
                        </div>)}
                    {this.state.switch === "Import" ?
                        <div className="switcher" onClick={() => this.setState({ switch: "Profile" })}>
                            {this.props.t('import_fighter')}
                        </div>
                        :
                        <div className="switcher" onClick={() => this.setState({ switch: "Import" })}>
                            {this.props.t('profile')}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(FighterItem);