import React from 'react';
import { isAndroid } from 'react-device-detect';
import { FaGooglePlay } from 'react-icons/fa';

function InstallPWAMobile() {

    return (
        isAndroid && (window.navigator.standalone !== true) &&
        <div className="install_icon" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.qwrtx.mmagoat")}>
            <FaGooglePlay/>
        </div>
    );
}

export default InstallPWAMobile;