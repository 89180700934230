import api from './api';

class PredictionsService {
  async addPrediction(prediction) {
    return await api
      .post('/predictions/new', prediction)
      .then(async (response) => {
        return response.data;
      });
  }

  async cancelPrediction(id) {
    return await api
      .get(`/predictions/cancel/${id}`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async allPredictions() {
    return await api
      .get(`/predictions/all`)
      .then(async (response) => {
        return response.data.predictions;
      });
  }
}

// eslint-disable-next-line
export default new PredictionsService();
