import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import { useParams } from 'react-router-dom';
import { ProgressBar } from "react-loader-spinner";
import public_services from "../../services/public_services";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { countries } from "../../components/countries";
import MobileHeader from "../../components/Menu/mobile";
import { isMobile } from "react-device-detect";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class LeagueFighters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            league: {},
            fighters: [],
            processing: true,
            copied: null,
        }
    }

    componentDidMount = async () => {
        const { innerWidth: width } = window
        this.setState({ width })
        const { id } = this.props.match.params
        let data = await public_services.leagueFighters(id)
        if (data) {
            this.setState({ league: data.league, fighters: data.fighters, processing: false })
        }
        else
            NotificationManager.error("Unreferenced league")
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"leagues"} />
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <h1 className="audiowide starter">{this.state.league.league_name} ranking</h1>
                        <div className="ranking_container">
                            {this.state.fighters.map((fighter, index) => fighter.owner && <div className="rank_list" key={`t_${index}`}>
                                <div className="nb_general mt-5 ml-5">#{index + 1}</div>
                                <div className="table_fighter" onClick={() => window.location.href=`/profile/${fighter.owner.username}`} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}></div>
                                <div className="info_rank">
                                    <h3 className="mb-0 mt-10">
                                        {fighter.nickname.toUpperCase()}
                                        <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div>
                                    </h3>
                                    <p className="min-ft-14 mt-0 mb-0 white">
                                        <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.owner.username}`} className="coming">{fighter.owner.username}</a>
                                    </p>
                                    {isMobile && <div className="mr-10 mb-10">
                                        <hr />
                                        <h3 className="mb-0 mt-5 text-left">
                                            <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                            {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                        </h3>
                                        <div className="lvl_holder mt-0">
                                            <div className="lvl back-coming" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                        </div>
                                        <span className="next_lvl min-ft-14"><strong>{fighter.wins || 0}W - {fighter.losses || 0}L</strong>
                                            <br />
                                            {this.props.t('next_level')} {fighter.next_level} points</span>
                                    </div>}
                                </div>
                                {!isMobile && <div className="info_rank ml-10 mr-10 web_f-right">
                                    <h3 className="mb-0 mt-10 text-right">
                                        {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                        <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                        <span className="min-ft-14 ml-10">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                    </h3>
                                    <div className="lvl_holder">
                                        <div className="lvl back-coming" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                    </div>
                                    <span className="next_lvl">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                </div>}
                            </div>)}
                        </div>
                    </React.Fragment>
                }
                <NotificationContainer />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(LeagueFighters));