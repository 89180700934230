import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import MobileHeader from "../../components/Menu/mobile";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu />
                <h1 className="huge starter">{this.props.t('cgu_title')}</h1>
                <div className="safe_holder">
                    <h1>{this.props.t('cgu_purpose')}</h1>
                    <p>{this.props.t('cgu_purpose01')}</p>
                    <p>{this.props.t('cgu_purpose02')}</p>
                    <p><strong>{this.props.t('cgu_purpose03')}</strong></p>
                    <p>{this.props.t('cgu_purpose04')}</p>
                    <h1>{this.props.t('cgu_legal')}</h1>
                    <p>
                        <strong>{this.props.t('cgu_legal01')}</strong>
                        <br />
                        {this.props.t('cgu_legal02')}
                    </p>
                    <p>
                        {this.props.t('cgu_legal03')}
                        <br />
                        {this.props.t('cgu_legal04')}
                        <br />
                        {this.props.t('cgu_legal05')}
                        <br />
                        {this.props.t('cgu_legal06')}
                    </p>
                    <h1>{this.props.t('cgu_definitions')}</h1>
                    <p>
                        <strong>{this.props.t('cgu_definition_site')}</strong>: {this.props.t('cgu_definition_site_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_user')}</strong>: {this.props.t('cgu_definition_user_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_user_account')}</strong>: {this.props.t('cgu_definition_user_account_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_identifier')}</strong>: {this.props.t('cgu_definition_identifier_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_password')}</strong>: {this.props.t('cgu_definition_password_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_league')}</strong>: {this.props.t('cgu_definition_league_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_odds')}</strong>: {this.props.t('cgu_definition_odds_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_boosters')}</strong>: {this.props.t('cgu_definition_boosters_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_blockchain')}</strong>: {this.props.t('cgu_definition_blockchain_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_nft')}</strong>: {this.props.t('cgu_definition_nft_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_wallet')}</strong>: {this.props.t('cgu_definition_wallet_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_digital_work')}</strong>: {this.props.t('cgu_definition_digital_work_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_personal_data')}</strong>: {this.props.t('cgu_definition_personal_data_desc')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_definition_editor')}</strong>: {this.props.t('cgu_definition_editor_desc')}
                    </p>
                    <h1>{this.props.t('cgu_service')}</h1>
                    <p>
                        {this.props.t('cgu_service01')}
                    </p>
                    <p>
                        {this.props.t('cgu_service02')}
                    </p>
                    <p>
                        {this.props.t('cgu_service03')}
                    </p>
                    <h1>{this.props.t('cgu_work')}</h1>
                    <h2>{this.props.t('cgu_work_account')}</h2>
                    <p>
                        {this.props.t('cgu_work_account01')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_account02')}
                        <br/>
                        {this.props.t('cgu_work_account03')}
                        <br/>
                        {this.props.t('cgu_work_account04')}
                        <br/>
                        {this.props.t('cgu_work_account05')}
                        <br/>
                        {this.props.t('cgu_work_account06')}
                        <br/>
                        {this.props.t('cgu_work_account07')}
                        <br/>
                        {this.props.t('cgu_work_account08')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_account09')}
                        <br/>
                        {this.props.t('cgu_work_account10')}
                        <br/>
                        {this.props.t('cgu_work_account11')}
                        <br/>
                        {this.props.t('cgu_work_account12')}
                        <br/>
                        {this.props.t('cgu_work_account13')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_account14')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_account15')}
                        <br/>
                        {this.props.t('cgu_work_account16')}
                        <br/>
                        {this.props.t('cgu_work_account17')}
                        <br/>
                        {this.props.t('cgu_work_account18')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_account19')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_account20')}
                    </p>
                    <h2>{this.props.t('cgu_work_responsability')}</h2>
                    <p>
                        {this.props.t('cgu_work_responsability01')}
                        <br/>
                        {this.props.t('cgu_work_responsability02')}
                        <br/>
                        {this.props.t('cgu_work_responsability03')}
                        <br/>
                        {this.props.t('cgu_work_responsability04')}
                        <br/>
                        {this.props.t('cgu_work_responsability05')}
                        <br/>
                        {this.props.t('cgu_work_responsability06')}
                        <br/>
                        {this.props.t('cgu_work_responsability07')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_responsability08')}
                    </p>
                    <h2>{this.props.t('cgu_work_predictions')}</h2>
                    <p>
                        {this.props.t('cgu_work_predictions01')}
                        <br/>
                        {this.props.t('cgu_work_predictions02')}
                        <br/>
                        {this.props.t('cgu_work_predictions03')}
                        <br/>
                        {this.props.t('cgu_work_predictions04')}
                        <br/>
                        {this.props.t('cgu_work_predictions05')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_predictions06')}
                    </p>
                    <h2>{this.props.t('cgu_work_booster')}</h2>
                    <p>
                        {this.props.t('cgu_work_booster01')}
                        <br/>
                        {this.props.t('cgu_work_booster02')}
                        <br/>
                        {this.props.t('cgu_work_booster03')}
                        <br/>
                        {this.props.t('cgu_work_booster04')}
                        <br/>
                        {this.props.t('cgu_work_booster05')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_booster06')}
                    </p>
                    <h2>{this.props.t('cgu_work_league')}</h2>
                    <p>
                        {this.props.t('cgu_work_league01')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_league02')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_league03')}
                        <br/>
                        {this.props.t('cgu_work_league04')}
                        <br/>
                        {this.props.t('cgu_work_league05')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_league06')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_league07')}
                    </p>
                    <h2>{this.props.t('cgu_work_winnings')}</h2>
                    <p>
                        {this.props.t('cgu_work_winnings01')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_winnings02')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_winnings03')}
                        <br/>
                        {this.props.t('cgu_work_winnings04')}
                        <br/>
                        {this.props.t('cgu_work_winnings05')}
                        <br/>
                        {this.props.t('cgu_work_winnings06')}
                        <br/>
                        {this.props.t('cgu_work_winnings07')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_winnings08')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_league07')}
                    </p>
                    <h2>{this.props.t('cgu_work_tracking')}</h2>
                    <p>
                        {this.props.t('cgu_work_tracking01')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_tracking02')}
                    </p>
                    <h2>{this.props.t('cgu_work_new_fighters')}</h2>
                    <p>
                        {this.props.t('cgu_work_new_fighters01')}
                        <br/>
                        {this.props.t('cgu_work_new_fighters02')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters03')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters04')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters05')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters06')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters07')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters08')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters09')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters10')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters11')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters12')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters13')}
                    </p>
                    <p>
                        {this.props.t('cgu_work_new_fighters14')}
                    </p>
                    <h1>{this.props.t('cgu_rates')}</h1>
                    <p>
                        {this.props.t('cgu_rates01')}
                    </p>
                    <p>
                        {this.props.t('cgu_rates02')}
                    </p>
                    <p>
                        {this.props.t('cgu_rates03')}
                    </p>
                    <p>
                        {this.props.t('cgu_rates04')}
                    </p>
                    <p>
                        {this.props.t('cgu_rates05')}
                    </p>
                    <h1>{this.props.t('cgu_nft')}</h1>
                    <p>
                        {this.props.t('cgu_nft01')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft02')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft03')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft04')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft05')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft05')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft07')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft08')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft09')}
                    </p>
                    <p>
                        {this.props.t('cgu_nft10')}
                    </p>
                    <h1>{this.props.t('cgu_ip')}</h1>
                    <p>
                        {this.props.t('cgu_ip01')}
                    </p>
                    <p>
                        {this.props.t('cgu_ip02')}
                    </p>
                    <p>
                        {this.props.t('cgu_ip03')}
                    </p>
                    <h1>{this.props.t('cgu_cookies')}</h1>
                    <p>
                        {this.props.t('cgu_cookies01')}
                    </p>
                    <p>
                        {this.props.t('cgu_cookies02')}
                    </p>
                    <p>
                        {this.props.t('cgu_cookies03')}
                    </p>
                    <h1>{this.props.t('cgu_data')}</h1>
                    <h2>{this.props.t('cgu_data_personal')}</h2>
                    <p>
                        {this.props.t('cgu_data_personal01')}
                        <br/>
                        {this.props.t('cgu_data_personal02')}
                        <br/>
                        {this.props.t('cgu_data_personal03')}
                    </p>
                    <p>
                        {this.props.t('cgu_data_personal04')}
                    </p>
                    <p>
                        {this.props.t('cgu_data_personal05')}
                        <br/>
                        {this.props.t('cgu_data_personal06')}
                        <br/>
                        {this.props.t('cgu_data_personal07')}
                    </p>
                    <p>
                        {this.props.t('cgu_data_personal08')}
                    </p>
                    <h2>{this.props.t('cgu_data_use')}</h2>
                    <p>
                        {this.props.t('cgu_data_use01')}
                    </p>
                    <p>
                        {this.props.t('cgu_data_use02')}
                        <br/>
                        {this.props.t('cgu_data_use03')}
                        <br/>
                        {this.props.t('cgu_data_use04')}
                        <br/>
                        {this.props.t('cgu_data_use05')}
                        <br/>
                        {this.props.t('cgu_data_use06')}
                        <br/>
                        {this.props.t('cgu_data_use07')}
                        <br/>
                        {this.props.t('cgu_data_use08')}
                        <br/>
                        {this.props.t('cgu_data_use09')}
                        <br/>
                        {this.props.t('cgu_data_use10')}
                    </p>
                    <h2>{this.props.t('cgu_data_share')}</h2>
                    <p>
                        {this.props.t('cgu_data_share01')}
                        <br/>
                        {this.props.t('cgu_data_share02')}
                        <br/>
                        {this.props.t('cgu_data_share03')}
                    </p>
                    <h2>{this.props.t('cgu_data_rights')}</h2>
                    <p>
                        {this.props.t('cgu_data_rights01')}
                        <br/>
                        {this.props.t('cgu_data_rights02')}
                        <br/>
                        {this.props.t('cgu_data_rights03')}
                    </p>
                    <p>
                        {this.props.t('cgu_data_rights04')}
                    </p>
                    <h2>{this.props.t('cgu_data_security')}</h2>
                    <p>
                        {this.props.t('cgu_data_security01')}
                    </p>
                    <p>
                        {this.props.t('cgu_data_security02')}
                    </p>
                    <h1>{this.props.t('cgu_limitations')}</h1>
                    <p>
                        {this.props.t('cgu_limitations01')}
                    </p>
                    <p>
                        {this.props.t('cgu_limitations02')}
                    </p>
                    <p>
                        {this.props.t('cgu_limitations03')}
                    </p>
                    <p>
                        {this.props.t('cgu_limitations04')}
                    </p>
                    <h1>{this.props.t('cgu_law')}</h1>
                    <p>
                        {this.props.t('cgu_law01')}
                    </p>
                    <p>
                        <strong>{this.props.t('cgu_law02')}</strong>
                    </p>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Terms);