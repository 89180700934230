import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import fighter_services from "../../services/fighter_services";
import { ProgressBar } from "react-loader-spinner";
import { QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import { countries } from "../../components/countries";
import public_services from "../../services/public_services";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/Menu/mobile";

// Media
import BLACKGOAT from "../../assets/img/black_goat.png";
import { FaVenus } from "react-icons/fa";

class Rankings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fighters: [],
            users: [],
            processing: true,
            display: "fighters",
            category: "male",
            weightclass: "POUND FOR POUND",
        }
    }

    componentDidMount = async () => {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({ width, height, switch: width > 940 ? true : false })
        const fighters = await fighter_services.getPound4PoundFighters('male')
        this.setState({ fighters, processing: false })
    }

    switchView = async (display) => {
        this.setState({ display })
        if (display === "fighters")
            this.loadFighters(this.state.category, this.state.weightclass)
        else
            this.loadUsers()
    }

    loadFighters = async (category, weightclass) => {
        this.setState({ fighters: [], processing: true, category })
        if (weightclass === "POUND FOR POUND") {
            const fighters = await fighter_services.getPound4PoundFighters(category)
            this.setState({ fighters, processing: false, category, weightclass })
        } else {
            let fighters = []
            if ((category === "female") && ["WELTERWEIGHT", "MIDDLEWEIGHT", "LIGHTHEAVYWEIGHT", "HEAVYWEIGHT"].includes(weightclass)) {
                weightclass = "POUND FOR POUND"
                fighters = await fighter_services.getPound4PoundFighters(category)
            } else if ((category === "male") && ["STRAWWEIGHT"].includes(weightclass)) {
                weightclass = "POUND FOR POUND"
                fighters = await fighter_services.getPound4PoundFighters(category)
            } else {
                fighters = await fighter_services.getRankedFighters(category, weightclass)
            }
            this.setState({ fighters, processing: false, weightclass, category })
        }
    }

    loadUsers = async () => {
        this.setState({ users: [], processing: true })
        const users = await public_services.getRankedUsers()
        this.setState({ users, processing: false })
    }

    render() {
        return (
            <React.Fragment>
                <Menu active={"rankings"} />
                <MobileHeader />
                <h1 className="huge starter">{this.props.t('global_rankings').toUpperCase()}</h1>
                {!isMobile && <h4>{this.props.t('browse_rankings')}</h4>}
                <div className="text-center">
                    <div className="ranking_container">
                        <div className="tabs">
                            <div className={this.state.display === "fighters" && this.state.category === "female" ? "tab_active_3" : "tab_3"} onClick={() => {this.switchView("fighters"); this.loadFighters("female", this.state.weightclass)}}>
                                {this.props.t('female')}
                            </div>
                            <div className={this.state.display === "fighters" && this.state.category === "male" ? "tab_active_3" : "tab_3"} onClick={() => {this.switchView("fighters"); this.loadFighters("male", this.state.weightclass)}}>
                                {this.props.t('male')}
                            </div>
                            <div className={this.state.display === "managers" ? "tab_active_3" : "tab_3"} onClick={() => this.switchView("managers")}>
                                {this.props.t('managers')}
                            </div>
                        </div>
                        {this.state.display === "fighters" &&
                            <select className="rank_select" onChange={(e) => this.loadFighters(this.state.category, e.target.value)}>
                                <option value="POUND FOR POUND" selected={this.state.weightclass === "POUND FOR POUND"}>POUND FOR POUND</option>
                                {this.state.category === "female" &&
                                    <option value="STRAWWEIGHT" selected={this.state.weightclass === "STRAWWEIGHT"}>STRAWWEIGHT</option>}
                                <option value="FLYWEIGHT" selected={this.state.weightclass === "FLYWEIGHT"}>FLYWEIGHT</option>
                                <option value="BANTAMWEIGHT" selected={this.state.weightclass === "BANTAMWEIGHT"}>BANTAMWEIGHT</option>
                                <option value="FEATHERWEIGHT" selected={this.state.weightclass === "FEATHERWEIGHT"}>FEATHERWEIGHT</option>
                                <option value="LIGHTWEIGHT" selected={this.state.weightclass === "LIGHTWEIGHT"}>LIGHTWEIGHT</option>
                                {this.state.category === "male" &&
                                    <option value="WELTERWEIGHT" selected={this.state.weightclass === "WELTERWEIGHT"}>WELTERWEIGHT</option>}
                                {this.state.category === "male" &&
                                    <option value="MIDDLEWEIGHT" selected={this.state.weightclass === "MIDDLEWEIGHT"}>MIDDLEWEIGHT</option>}
                                {this.state.category === "male" &&
                                    <option value="LIGHTHEAVYWEIGHT" selected={this.state.weightclass === "LIGHTHEAVYWEIGHT"}>LIGHTHEAVYWEIGHT</option>}
                                {this.state.category === "male" &&
                                    <option value="HEAVYWEIGHT" selected={this.state.weightclass === "HEAVYWEIGHT"}>HEAVYWEIGHT</option>}
                            </select>}
                    </div>
                </div>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '10px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    : this.state.display === "fighters" ?
                        <div className="max_holder">
                            <div className="max_holder full text-left">
                                {this.state.fighters.length === 0 &&
                                    <p><strong>{this.props.t('no_fighters')}</strong></p>
                                }
                                <div className="ranking_container text-center">
                                    {isMobile && this.state.fighters.slice(0, 1).map((fighter, index) => fighter.user &&
                                        <div className="first" key={`r_${index}`}>
                                            <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                            </div>
                                            <div className="profile_board">
                                                <div className="nb_general nb_one centered_rank"><img src={BLACKGOAT} alt="GOAT" /></div>
                                                <h3 className="text-center mb-0">{fighter.nickname.toUpperCase()}
                                                    <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div></h3>
                                                <p className="min-ft-14 mt-0 white text-center">
                                                    <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`}>{fighter.user.username}</a>
                                                </p>
                                                <div className="back_separator"></div>
                                                <h3 className="text-center">
                                                    <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                                    <br />
                                                    <span className="min-ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                                    {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                                </h3>
                                                <div className="lvl_holder">
                                                    <div className="lvl nb_one" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                                </div>
                                                <span className="next_lvl white">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.fighters.slice(1, 2).map((fighter, index) => fighter.user &&
                                        <div className="second" key={`r_${index}`}>
                                            <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                            </div>
                                            <div className="profile_board">
                                                <div className="nb_general nb_two centered_rank">#2</div>
                                                <h3 className="text-center mb-0">{fighter.nickname.toUpperCase()}
                                                    <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div></h3>
                                                <p className="min-ft-14 mt-0 white text-center">
                                                    <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`}>{fighter.user.username}</a>
                                                </p>
                                                <div className="back_separator"></div>
                                                <h3 className="text-center">
                                                    <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                                    <br />
                                                    <span className="min-ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                                    {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                                </h3>
                                                <div className="lvl_holder">
                                                    <div className="lvl nb_two" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                                </div>
                                                <span className="next_lvl white">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                            </div>
                                        </div>
                                    )}
                                    {!isMobile && this.state.fighters.slice(0, 1).map((fighter, index) => fighter.user &&
                                        <div className="first" key={`r_${index}`}>
                                            <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                            </div>
                                            <div className="profile_board">
                                                <div className="nb_general nb_one centered_rank"><img src={BLACKGOAT} alt="GOAT" /></div>
                                                <h3 className="text-center mb-0">{fighter.nickname.toUpperCase()}
                                                    <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div></h3>
                                                <p className="min-ft-14 mt-0 white text-center">
                                                    <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`}>{fighter.user.username}</a>
                                                </p>
                                                <div className="back_separator"></div>
                                                <h3 className="text-center">
                                                    <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                                    <br />
                                                    <span className="min-ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                                    {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                                </h3>
                                                <div className="lvl_holder">
                                                    <div className="lvl nb_one" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                                </div>
                                                <span className="next_lvl white">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.fighters.slice(2, 3).map((fighter, index) => fighter.user &&
                                        <div className="third" key={`r_${index}`}>
                                            <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                            </div>
                                            <div className="profile_board">
                                                <div className="nb_general nb_three centered_rank">#3</div>
                                                <h3 className="text-center mb-0">{fighter.nickname.toUpperCase()}
                                                    <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div></h3>
                                                <p className="min-ft-14 mt-0 white text-center">
                                                    <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`}>{fighter.user.username}</a>
                                                </p>
                                                <div className="back_separator"></div>
                                                <h3 className="text-center">
                                                    <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                                    <br />
                                                    <span className="min-ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                                    {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                                </h3>
                                                <div className="lvl_holder">
                                                    <div className="lvl nb_three" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                                </div>
                                                <span className="next_lvl white">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="ranking_container">
                                    {this.state.fighters.slice(3).map((fighter, index) => fighter.user && <div className="rank_list" key={`t_${index}`}>
                                        <div className="nb_general mt-5 ml-5">#{index + 4}</div>
                                        <div className="table_fighter" onClick={() => window.location.href=`/profile/${fighter.user.username}`} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}></div>
                                        <div className="info_rank">
                                            <h3 className="mb-0 mt-10">
                                                {fighter.nickname.toUpperCase()}
                                                <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div>
                                            </h3>
                                            <p className="min-ft-14 mt-0 mb-0 white">
                                                <small className="grey">{this.props.t('managed_by')}</small> <a href={`/profile/${fighter.user.username}`} className="coming">{fighter.user.username}</a>
                                            </p>
                                            {isMobile && <div className="mr-10 mb-10">
                                                <hr />
                                                <h3 className="mb-0 mt-5 text-left">
                                                    <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                                    {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                                </h3>
                                                <div className="lvl_holder mt-0">
                                                    <div className="lvl back-coming" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                                </div>
                                                <span className="next_lvl min-ft-14"><strong>{fighter.wins || 0}W - {fighter.losses || 0}L</strong>
                                                    <br />
                                                    {this.props.t('next_level')} {fighter.next_level} points</span>
                                            </div>}
                                        </div>
                                        {!isMobile && <div className="info_rank ml-10 mr-10 web_f-right">
                                            <h3 className="mb-0 mt-10 text-right">
                                                {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                                <small>{this.props.t('level')} {fighter.fighter_level}</small>
                                                <span className="min-ft-14 ml-10">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                            </h3>
                                            <div className="lvl_holder">
                                                <div className="lvl back-coming" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                            </div>
                                            <span className="next_lvl">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                        </div>}
                                    </div>)}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="max_holder">
                            <div className="max_holder full text-left">
                                <div className="ranking_container">
                                    {this.state.users.map((manager, index) => <div className="rank_list" key={`u_${index}`}>
                                        {index === 0 ?
                                            <div className="nb_general resized nb_one mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div> :
                                            index === 1 ?
                                                <div className="nb_general resized nb_two mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div>
                                                :
                                                index === 2 ? <div className="nb_general resized nb_three mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div>
                                                    : <div className="nb_general resized mt-5 ml-5">#{index + 1} <span className="minimized"><strong>{manager.points} </strong>points</span></div>}
                                        {manager.avatar ?
                                            <div className="table_user" onClick={() => window.location.href=`/profile/${manager.username}`} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${manager.avatar}')` }}></div>
                                            :
                                            <div className="table_user" onClick={() => window.location.href=`/profile/${manager.username}`} style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${manager.username}')` }}></div>
                                        }
                                        <div className="info_rank">
                                            <h3 className="mb-0 mt-10">
                                                {manager.username}
                                            </h3>
                                            <p className="min-ft-14 mt-0 mb-0 white">
                                                <small className="grey">{this.props.t('managing')} {manager.fighters} {this.props.t('fighters')}</small>
                                            </p>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                        </div>

                }
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Rankings);