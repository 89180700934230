import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { ProgressBar } from "react-loader-spinner";
import { useParams } from 'react-router-dom';
import league_services from "../../services/league_services";
import { NotificationContainer, NotificationManager } from "react-notifications";
import validator from 'validator';
import { FaTimes, FaTimesCircle } from "react-icons/fa";
import MobileHeader from "../../components/Menu/mobile";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}


class LeagueInvitations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            league: {},
            invits: [],
            email: "",
            processing: true,
        }
    }

    componentDidMount = async () => {
        await this.loadInvits()
    }

    loadInvits = async () => {
        const { id } = this.props.match.params
        let data = await league_services.getInvitations(id)
        if (data) {
            this.setState({ league: data.league, invits: data.invits, processing: false, email: "" })
        }
        else
            NotificationManager.error(this.props.t('unreferenced_league'))

    }

    inviteEmail = async () => {
        if (!this.state.email || !validator.isEmail(this.state.email))
            NotificationManager.warning(this.props.t('incorrect_email'))
        else {
            try {
                this.setState({ processing: true })
                const status = await league_services.sendInvitation(this.state.league.id, this.state.email)
                if (status)
                    this.loadInvits()
                else
                    NotificationManager.error(this.props.t('error_invitation'))
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
                this.setState({ processing: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"leagues"} />
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="search">
                        <span className="label ml-20">{this.props.t('set_email_invite')} <big><strong className="audiowide black">{this.state.league.league_name}</strong></big> {this.props.t('league')}</span>
                        <input type="search" className="search_editor" placeholder="Email to invite" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />
                        <div className="edit" onClick={this.inviteEmail}>
                        {this.props.t('send_invit')}
                        </div>
                        {this.state.invits.map(invit =>
                            <div className="profile mt-20" key={invit.id}>
                                <h3 className="d-inline-block mt-0" style={{float: "right"}}><FaTimesCircle/></h3>
                                <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/shapes/jpg?seed=${invit.email}')` }}></div>
                                <div className="username">
                                    {invit.email} {invit.joined ? <span className="winner_tag">joined</span> : <span className="winner_tag back-primary">{this.props.t('awaiting').toLowerCase()}</span>}
                                    <br />
                                    <span className="fighters">Sent {new Date(invit.createdAt).toLocaleDateString()}</span>
                                </div>
                                <hr/>
                            </div>
                        )}
                        {this.state.invits.length === 0 &&
                            <p><small><strong>{this.props.t('no_invitations...')}</strong></small></p>
                        }
                    </div>}
                <NotificationContainer />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(LeagueInvitations));