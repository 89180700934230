import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import MobileHeader from "../../components/Menu/mobile";

// Media
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import { countries } from "../../components/countries";
import { isMobile } from "react-device-detect";

class ConfirmFighters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            male: null,
            female: null,
            checked: false,
        }
    }

    componentDidMount = async () => {
        if (window.localStorage.getItem('mma_goat_fighters')) {
            const fighters = JSON.parse(window.localStorage.getItem('mma_goat_fighters'))
            if (!fighters.male || !fighters.female || !fighters.female.info)
                window.location.href = "/start/2"
            else {
                this.setState({ male: fighters.male, female: fighters.female })
            }
        } else
            window.location.href = "/start/1"
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"start"} />
                <h1 className="starter huge">{this.props.t('confirm_fighters')}</h1>
                <h4>{this.props.t('confirmation')}</h4>
                <div className="max_holder">
                    {this.state.male && <div className="card card-confirm responsive-m-right">
                        <div className="info text-left">
                            <h5>
                                <small>{this.state.male.visual.weightclass.toUpperCase()}</small>
                            </h5>
                            <span className="label">{this.props.t('martial_art')}</span>
                            <span className="value">{this.state.male.info.martial_art.toUpperCase()}</span>
                            <span className="label">{this.props.t('fullname')}</span>
                            <span className="value">
                                {this.state.male.info.firstname} {this.state.male.info.lastname}
                                <div className={`flag ${this.state.male.info.nationality}`} title={countries[this.state.male.info.nationality.toUpperCase()]}></div>
                            </span>
                            <span className="label">{this.props.t('nickname')}</span>
                            <span className="value">{this.state.male.info.nickname.toLowerCase()}</span>
                            <h5>{this.props.t('level')} 1</h5>
                            <div className="lvl_holder">
                                <div className="lvl back-coming"></div>
                            </div>
                            <span className="next_lvl">{this.props.t('next_level')} 25 points</span>
                            <hr />
                            <span className="next_lvl text-center">{this.props.t('edit_fighter_profile')}</span>
                            <div className="edit" onClick={() => window.location.href = "/start/2"}>
                                {this.props.t('edit')}
                            </div>
                        </div>
                        <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/male/${this.state.male.visual.visual}.png`} alt={this.state.male.info.nickname.toLowerCase()} className={isMobile? "visual" : "visual scale_max"} />
                    </div>}
                    {this.state.female && <div className="card card-confirm responsive-m-left">
                        <div className="info info_right responsive-text-right">
                            <h5><small>{this.state.female.visual.weightclass.toUpperCase()}</small></h5>
                            <span className="label">{this.props.t('martial_art')}</span>
                            <span className="value">{this.state.female.info.martial_art.toUpperCase()}</span>
                            <span className="label">{this.props.t('fullname')}</span>
                            <span className="value">
                                {this.state.female.info.firstname} {this.state.female.info.lastname}
                                <div className={`flag ${this.state.female.info.nationality}`} title={countries[this.state.female.info.nationality.toUpperCase()]}></div>
                            </span>
                            <span className="label">{this.props.t('nickname')}</span>
                            <span className="value">{this.state.female.info.nickname.toLowerCase()}</span>
                            <h5>{this.props.t('level')} 1</h5>
                            <div className="lvl_holder">
                                <div className="lvl back-coming"></div>
                            </div>
                            <span className="next_lvl">{this.props.t('next_level')} 25 points</span>
                            <hr />
                            <span className="next_lvl text-center">{this.props.t('edit_fighter_profile')}</span>
                            <div className="edit" onClick={() => window.location.href = "/start/4"}>
                                {this.props.t('edit')}
                            </div>
                        </div>
                        <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/female/${this.state.female.visual.visual}.png`} alt={this.state.female.info.nickname.toLowerCase()} className={isMobile? "visual" : "visual scale_max"} />
                    </div>}
                </div>
                <div className="confirm" onClick={() => window.location.href = "/register"}>
                    {this.props.t('confirm')}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(ConfirmFighters);