import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import league_services from "../../services/league_services";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import { ProgressBar } from "react-loader-spinner";
import MobileHeader from "../../components/Menu/mobile";

class CreateLeague extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visual: "01",
            league_name: null,
            league_type: null,
            league_limit: 0,
        }
    }

    newLeague = async () => {
        try {
            this.setState({ processing: true })
            const status = await league_services.createNewLeague({
                visual: this.state.visual,
                is_limit: false,
                league_name: this.state.league_name,
                league_type: this.state.league_type,
                league_limit: this.state.league_limit,
            })
            if (status) {
                NotificationManager.success(this.props.t('league_created'))
                window.location.href = "/leagues"
            } else {
                NotificationManager.error(this.props.t('error_league'))
            }
            this.setState({ processing: false })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
            this.setState({ processing: false })
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu />
                <h1 className="huge starter">{this.props.t('new_private_league')}</h1>
                <h4>{this.props.t('create_your_private_league')}</h4>
                <div className="editor d-block full-border-radius h-auto">
                    <h5>{this.props.t('reference_all_info')}</h5>
                    <span className="label">
                        {this.props.t('select_league_visual')}
                    </span>
                    <span className="label">
                        {this.props.t('league_name')}
                    </span>
                    <input type="text" className="text_editor" placeholder={this.props.t('placeholder_league_name')} onChange={(e) => this.setState({ league_name: e.target.value })} />
                    <span className="label">
                        {this.props.t('enrollment_type')}
                    </span>
                    <select className="text_editor" onChange={(e) => this.setState({ league_type: e.target.value })}>
                        <option selected disabled>{this.props.t('select_enrollment_type')}</option>
                        <option value="invitation">{this.props.t('invitation_mail')}</option>
                        <option value="code">{this.props.t('open_code')}</option>
                    </select>
                    <span className="label">
                        {this.props.t('participants_limit')}
                    </span>
                    <span className="label">
                        <input type="radio" name="limit" checked onClick={() => this.setState({ is_limit: false })} /> {this.props.t('unlimited')} <input className="ml-10" type="radio" name="limit" onClick={() => this.setState({ is_limit: true })} /> {this.props.t('limited')}
                    </span>
                    {this.state.is_limit && <React.Fragment>
                        <span className="label mt-20">
                            {this.props.t('nb_participants')}
                        </span>
                        <input type="number" className="text_editor" placeholder={this.props.t('placeholder_set_participants_limit')} onChange={(e) => this.setState({ league_limit: e.target.value })} />
                    </React.Fragment>}
                    {!this.state.processing ?
                        <div className="edit mt-40" onClick={this.newLeague}>{this.props.t('create_league')}</div>
                        :
                        <ProgressBar
                            height="80"
                            width="80"
                            ariaLabel="progress-bar-loading"
                            wrapperStyle={{ margin: '0 20px' }}
                            wrapperClass="progress-bar-wrapper"
                            borderColor='#000'
                            barColor='#000'
                        />
                    }
                </div>
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(CreateLeague);