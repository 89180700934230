import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import MobileHeader from "../../components/Menu/mobile";

// Media
import Footer from "../../components/Footer";
import fighter_services from "../../services/fighter_services";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";

class SelectWomanFighter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fighters: [],
            selectedFighter: null,
            width: 0,
            height: 0,
        }
    }

    componentDidMount = async () => {
        const fighters = await fighter_services.getFighters('female')
        this.setState({ fighters })
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({ width, height })
    }

    selectFighter = (selectedFighter) => {
        let fighters = {}
        if (window.localStorage.getItem('mma_goat_fighters')) {
            fighters = JSON.parse(window.localStorage.getItem('mma_goat_fighters'))
            if (fighters.female) fighters.female.visual = selectedFighter
            else fighters.female = { visual: selectedFighter }
        } else {
            fighters.female = { visual: selectedFighter }
        }
        window.localStorage.setItem('mma_goat_fighters', JSON.stringify(fighters))
        window.location.href = "/start/4"
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"start"} />
                <h1 className="starter huge">{this.props.t('choose_woman_fighter')}</h1>
                <h4>
                    {this.props.t('select_female_fighter')}
                    <br />
                    {this.props.t('tip_weight_class')} <strong>{this.props.t('boost_point')}</strong> {this.props.t('tip_predictive')}
                </h4>
                <div className="max_holder">
                    {this.state.fighters.map((fighter, index) =>
                        <React.Fragment key={fighter.id}>
                            <div className="card card-select full-border" key={fighter.id} style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/female/${fighter.visual}.jpg')` }} onClick={() => this.selectFighter(fighter)}>
                                <div className="weightclass">
                                    {fighter.weightclass.toUpperCase()}
                                </div>
                            </div>
                            {index === 1 && this.state.width < 1660 && this.state.width > 940 && <br />}
                        </React.Fragment>
                    )}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(SelectWomanFighter);