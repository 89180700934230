import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import validator from 'validator';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ProgressBar } from 'react-loader-spinner';
import account_services from "../../services/account_services";
import MobileHeader from "../../components/Menu/mobile";
import token_services from '../../services/token_services';

class ProfileUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: null,
            email: null,
            username: null,
            old_password: null,
            new_password: null,
            success: false,
            processing: false,
            loading: true,
            file_data: null,
            file_name: null,
            file_extension: null,
        }
        this.hiddenFileInput = React.createRef();
    }

    handleFileUpload = () => {
        this.hiddenFileInput.current.click()
    }

    onFileFileHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
            this.setState({ processing: false })
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
            this.setState({ processing: false })
        } else {
            this.setState({ file_extension: extension, file_name: event.target.files[0].name })
            reader.onloadend = () => this.processFile(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processFile = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        const avatar = { file_data: base64data, file_extension: this.state.file_extension, file_name: this.state.file_name }
        this.setState({ processing: false, file_data: base64data })
        await account_services.updateAvatar(avatar)
        NotificationManager.success(this.props.t('avatar_updated'))
    }

    componentDidMount = async () => {
        const data = await account_services.getProfile()
        this.setState({ email: data.account.email, username: data.account.username, avatar: data.account.avatar, loading: false })
    }

    updtEmail = async () => {
        if (!this.state.email || !validator.isEmail(this.state.email))
            NotificationManager.warning(this.props.t('incorrect_email'))
        else {
            try {
                this.setState({ processing: true })
                const resp = await account_services.updateEmail(this.state.email)
                if (resp) {
                    NotificationManager.success(this.props.t('email_updated'))
                } else {
                    NotificationManager.error(this.props.t('error_email'))
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
                this.setState({ processing: false })
            }
        }
    }

    updtUsername = async () => {
        if (!this.state.username || this.state.username.length < 3 || this.state.username.length > 25)
            NotificationManager.warning(this.props.t('incorrect_username'))
        else {
            try {
                this.setState({ processing: true })
                const resp = await account_services.updateUsername(this.state.username)
                if (resp) {
                    NotificationManager.success(this.props.t('username_updated'))
                } else {
                    NotificationManager.error(this.props.t('error_username'))
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
                this.setState({ processing: false })
            }
        }
    }

    updtPassword = async () => {
        if (!this.state.old_password || !this.state.new_password || this.state.new_password.length < 3 || this.state.new_password.length > 25)
            NotificationManager.warning(this.props.t('incorrect_password'))
        else {
            try {
                this.setState({ processing: true })
                const resp = await account_services.updatePassword(this.state.email, this.state.old_password, this.state.new_password)
                if (resp) {
                    NotificationManager.success(this.props.t('password_updated'))
                } else {
                    NotificationManager.error(this.props.t('error_password'))
                }
                this.setState({ processing: false })
            } catch (e) {
                console.log(e)
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? e.response.data : e.message)
                this.setState({ processing: false })
            }
        }
    }

    deleteAccount = async () => {
        if (window.confirm(this.props.t('rm_confirm'))) {
            this.setState({ loading: true })
            const status = await account_services.rmAccount()
            if (status) {
                NotificationManager.success(this.props.t('rm_success'))
                setTimeout(() => {
                    token_services.removeUser()
                }, 2500);
            } else {
                NotificationManager.error(this.props.t('rm_error'))
                this.setState({ loading: false })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu />
                <h1 className="starter huge">{this.props.t('update_account').toUpperCase()}</h1>
                <h4>
                    {this.props.t('new_info')}
                </h4>
                {this.state.loading ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '20px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <div className="max_holder text-center">
                        <div className="editor fit-content text-left full-border-radius d-block mt-20 h-auto back-white border_mark">
                            <span className="label">
                                {this.props.t('avatar')}
                            </span>
                            <input className="d-none" type="file" ref={this.hiddenFileInput}
                                accept="image/x-png,image/jpeg"
                                onChange={(e) => this.onFileFileHandler(e)} />
                            <div className="avatar" onClick={this.handleFileUpload}
                                style={{
                                    backgroundImage: this.state.file_data ? `url('data:image/${this.state.file_extension};base64,${this.state.file_data}')` :
                                        this.state.avatar ? `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.avatar}')` : `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.username}')`
                                }}></div>
                            <span className="tips">{this.props.t('press_to_change')}</span>
                            <hr />
                            <span className="label">
                                {this.props.t('username')}
                            </span>
                            <input type="text" className="text_editor back-white" placeholder={this.props.t('placeholder_username')} onChange={(e) => this.setState({ username: e.target.value })} value={this.state.username} />
                            <div className="submit ml-10 mt-10 mb-20" onClick={this.updtUsername}>
                                {this.props.t('update')}
                            </div>
                            <span className="label">
                                {this.props.t('email')}
                            </span>
                            <input type="email" className="text_editor back-white" placeholder={this.props.t('placeholder_email')} onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />
                            <div className="submit ml-10 mt-10 mb-20" onClick={this.updtEmail}>
                                {this.props.t('update')}
                            </div>
                            <span className="label">
                                {this.props.t('current_password')}
                            </span>
                            <input type="password" className="text_editor back-white" placeholder={this.props.t('placeholder_password')} onChange={(e) => this.setState({ old_password: e.target.value })} />
                            <span className="label">
                                {this.props.t('new_password')}
                            </span>
                            <input type="password" className="text_editor back-white" placeholder={this.props.t('placeholder_password')} onChange={(e) => this.setState({ new_password: e.target.value })} />
                            <div className="submit ml-10 mt-10 mb-50" onClick={this.updtPassword}>
                                {this.props.t('update')}
                            </div>
                            <hr />
                            <span className="label max_controller">
                                {this.props.t('delete_account')}
                                <br />
                                <small>
                                    {this.props.t('delete_account_desc')}
                                </small>
                            </span>
                            <div className="submit ml-10 mt-10 back-danger white" onClick={this.deleteAccount}>
                                {this.props.t('rm_button')}
                            </div>
                        </div>
                    </div>}
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(ProfileUpdate);