import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MenuAdmin from '../../components/Menu/admin';
import Footer from "../../components/Footer";
import { useParams } from 'react-router-dom';

// Media
import public_services from "../../services/public_services";
import token_services from "../../services/token_services";
import { OPTIONS, QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import { ProgressBar } from "react-loader-spinner";
import { countries } from "../../components/countries";
import account_services from "../../services/account_services";
import { isMobile } from "react-device-detect";
import admin_services from "../../services/admin_services";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class AdminPublicProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            processing: true,
            predictions: [],
            profile: null,
            switch: "fighters",
            fighters: [],
            wins: 0,
            losses: 0,
            logged: false,
            followers: [],
            isFollower: false,
            isFollowed: false
        }
    }

    componentDidMount = async () => {
        const { username } = this.props.match.params
        const profile = await token_services.getUser()
        if (profile && profile.account.username === username) window.location.href = '/profile'
        if (profile) this.setState({ logged: true })
        const data = await public_services.getProfile(username)
        this.setState({ profile: data.profile, fighters: data.fighters, wins: data.wins, losses: data.losses, followers: data.followers })
        if (this.state.logged) {
            const follow = await account_services.checkFollower(data.profile.id)
            this.setState({ isFollower: follow.follower, isFollowed: follow.followed })
        }
        const predictions = await admin_services.getPredictions(data.profile.id)
        this.setState({ processing: false, predictions })
    }

    followUser = async () => {
        this.setState({ processing: true })
        await account_services.follow(this.state.profile.id)
        window.location.reload()
    }

    unfollowUser = async () => {
        this.setState({ processing: true })
        await account_services.unfollow(this.state.profile.id)
        window.location.reload()
    }

    render() {
        return (
            <React.Fragment>
            <MenuAdmin active={"users"} />
                {this.state.processing &&
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto 10px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />}
                {!this.state.processing &&
                    <div className={isMobile? "big_profile mt-20" : "big_profile" }>
                        {this.state.profile.avatar ?
                            <div className="avatar center_mobile" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${this.state.profile.avatar}')` }}></div>
                            :
                            <div className="avatar center_mobile" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.profile.username}')` }}></div>
                        }
                        <div className="d-inline-block">
                            <h2 className="mt-10 mb-0 center_mobile">{this.state.profile.ranking && <span className="rank_tag">#{this.state.profile.ranking}</span>} <span>{this.state.profile.username}</span></h2>
                            <div className="d-inline-block ml-10 mr-10">
                                <span className="label mt-10 text-center">{this.props.t('managing')}</span>
                                <span className="profile_action" onClick={() => this.setState({ switch: "fighters" })}>{this.state.fighters.length} {this.props.t('fighters')}</span>
                            </div>
                            <div className="d-inline-block ml-10 mr-10">
                                <span className="label mt-10 text-center">{this.props.t('score_card')}</span>
                                <span className="profile_action" onClick={() => this.setState({ switch: "predictions" })}>{this.state.wins || 0}W - {this.state.losses || 0}L</span>
                            </div>
                            <div className="d-inline-block ml-10 mr-10">
                                <span className="label mt-10 text-center">{this.props.t('followers')}</span>
                                <span className="profile_action" onClick={() => this.setState({ switch: "followers" })}>{this.state.followers.length} {this.state.followers.length > 1 ? this.props.t('followers') : this.props.t('follower')}</span>
                            </div>
                        </div>
                        <div className="d-inline-block text-center mt-30 web_f-right">
                            {this.state.isFollower ?
                                <React.Fragment>
                                    <h5 className="mt-0">{this.props.t('followed')}</h5>
                                    <span className="logout" onClick={() => this.unfollowUser()}>{this.props.t('unfollow')}</span>
                                </React.Fragment>
                                :
                                <div className="edit mt-10" onClick={() => this.state.logged ? this.followUser() : window.location.href = "/login"}>{this.props.t('follow')}</div>
                            }
                        </div>
                    </div>}

                {!this.state.processing && this.state.switch === "predictions" &&
                    <React.Fragment>
                        <h1 className="huge">{this.props.t('last_predicitons')}</h1>
                        <h4>{this.props.t('browse_passed_predictions')}</h4>
                        {!this.state.processing && this.state.switch === "predictions" && this.state.predictions.length === 0 &&
                            <div className="d-block">
                                <span className="next_lvl text-center">{this.props.t('no_prediction_from')} <strong>{this.state.profile.username}</strong> {this.props.t('yet')}</span>
                            </div>}
                        {this.state.predictions.map(prediction => prediction.fight &&
                            <div key={prediction.id} className="prono border_mark mb-150">
                                <div className="event">{prediction.event.event_name}</div>
                                <div className={prediction.contestant === "one" ?
                                    prediction.active_status === "AWAITING" ? "left_pts back-primary" : prediction.active_status === "WIN" ? "left_pts back-coming" : "left_pts back-danger"
                                    : "left_pts"}>{prediction.fight.fighter_one_odd}</div>
                                <div className={prediction.contestant === "two" ?
                                    prediction.active_status === "AWAITING" ? "right_pts back-primary" : prediction.active_status === "WIN" ? "right_pts back-coming" : "right_pts back-danger"
                                    : "right_pts"}>{prediction.fight.fighter_two_odd}</div>
                                <div className="vs">vs</div>
                                <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${prediction.fighter.visual.section}/${prediction.fighter.fighter}/${prediction.fighter.visual.visual}.png`} alt={prediction.fighter.nickname} className="fighter" />
                                <div className={prediction.contestant === "one" ?
                                    prediction.active_status === "AWAITING" ? "left_part selected" : prediction.active_status === "WIN" ? "left_part selected_win" : "left_part selected_loss"
                                    : "left_part"}>
                                    <span className={prediction.contestant === "one" ? "label light-grey" : "label"}>{prediction.fight.main_event ? `${this.props.t('main_event')} - ` : prediction.fight.co_main_event && `${this.props.t('co_main_event')} - `}{prediction.fight.category} {prediction.fight.championship && "CHAMPIONSHIP"}</span>
                                    <span className="entry">{prediction.fight.fighter_one_ranking && <small>#{prediction.fight.fighter_one_ranking}</small>} {prediction.fight.fighter_one_firstname} {prediction.fight.fighter_one_lastname} <div className={`inline-flag ${prediction.fight.fighter_one_nationality}`}></div></span>
                                    <span className="data"><strong>{prediction.fight.fighter_one_wins}W</strong> | {prediction.fight.fighter_one_losses}L | {prediction.fight.fighter_one_draws}D, {prediction.fight.fighter_one_no_contests}NC {prediction.fight.winner === 'one' && <span className="winner_tag">winner by {prediction.fight.victory}</span>}</span>
                                    <span className="data"><span className="capitalize">{prediction.fight.fighters}</span>, {prediction.fight.fighter_one_age} {this.props.t('years_old')}</span>
                                    <span className="data responsive-text-left">{prediction.fight.fighter_one_martial_arts.toUpperCase()}</span>
                                </div>
                                <div className={prediction.contestant === "two" ?
                                    prediction.active_status === "AWAITING" ? "right_part selected" : prediction.active_status === "WIN" ? "right_part selected_win" : "right_part selected_loss"
                                    : "right_part"}>
                                    <span className={prediction.contestant === "two" ? "label responsive-text-right light-grey" : "label responsive-text-right"}>{prediction.fight.nb_rounds} rounds</span>
                                    <span className="entry responsive-text-right">{prediction.fight.fighter_two_ranking && <small>#{prediction.fight.fighter_two_ranking}</small>} {prediction.fight.fighter_two_firstname} {prediction.fight.fighter_two_lastname} <div className={`inline-flag ${prediction.fight.fighter_two_nationality}`}></div></span>
                                    <span className="data responsive-text-right"><strong>{prediction.fight.fighter_two_wins}W</strong> - {prediction.fight.fighter_two_losses}L - {prediction.fight.fighter_two_draws}D, {prediction.fight.fighter_two_no_contests}NC {prediction.fight.winner === 'two' && <span className="winner_tag">winner by {prediction.fight.victory}</span>}</span>
                                    <span className="data responsive-text-right"><span className="capitalize">{prediction.fight.fighters}</span>, {prediction.fight.fighter_two_age} {this.props.t('years_old')}</span>
                                    <span className="data responsive-text-right">{prediction.fight.fighter_two_martial_arts.toUpperCase()}</span>
                                    <div className="date capitalize">
                                        {new Date(prediction.event.event_date).toLocaleDateString(undefined, OPTIONS)}, {new Date(prediction.event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </div>
                                </div>
                                <div className="fighter_name">{prediction.fighter.nickname.toUpperCase()}
                                    {prediction.contestant === "one" ?
                                        <small className={prediction.active_status === "AWAITING" ? "primary ml-10" : prediction.active_status === "WIN" ? "green ml-10" : "danger ml-10"}>x{(1 +
                                            (prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase() ? 0.4 : 0) +
                                            (prediction.fighter.nationality === prediction.fight.fighter_one_nationality ? 0.2 : 0) +
                                            (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_one_martial_arts.toLowerCase() ? 0.4 : 0)).toFixed(1)
                                        } <span className="ft-11">+ {prediction.victory}</span></small>
                                        :
                                        <small className={prediction.active_status === "AWAITING" ? "primary ml-10" : prediction.active_status === "WIN" ? "green ml-10" : "danger ml-10"}>x{(1 +
                                            (prediction.fighter.visual.weightclass.toLowerCase() === prediction.fight.category.toLowerCase() ? 0.4 : 0) +
                                            (prediction.fighter.nationality === prediction.fight.fighter_two_nationality ? 0.2 : 0) +
                                            (prediction.fighter.martial_art.toLowerCase() === prediction.fight.fighter_two_martial_arts.toLowerCase() ? 0.4 : 0)).toFixed(1)
                                        } <span className="ft-11">+ {prediction.victory}</span></small>
                                    }
                                </div>
                                {prediction.active_status === "AWAITING" && <div className="status back-primary">ACTIVE</div>}
                                {prediction.active_status === "WIN" && <div className="status back-coming">WIN</div>}
                                {prediction.active_status === "DRAW" && <div className="status back-danger">DRAW</div>}
                                {prediction.active_status === "LOSS" && <div className="status back-danger">LOSS</div>}
                            </div>
                        )}
                    </React.Fragment>}
                {!this.state.processing && this.state.switch === "fighters" &&
                    <React.Fragment>
                        <h1 className="huge">{this.props.t('fighters').toUpperCase()}</h1>
                        <h4>{this.props.t('browse')} <strong>{this.state.profile.username}</strong> {this.props.t('owned_fighters').toLowerCase()}</h4>
                        {!this.state.processing && this.state.switch === "fighters" && this.state.fighters.length === 0 &&
                            <div className="d-block">
                                <span className="next_lvl text-center">{this.props.t('no_fighter_from')} <strong>{this.state.profile.username}</strong> :/</span>
                            </div>}
                        <div className="max_holder">
                            {this.state.fighters.map(fighter => fighter &&
                                <div key={fighter.id} className="card card-fighter" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                    <div className="info text-left">
                                        <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div>
                                        <h5>{this.props.t(`${fighter.fighter}_fighter`)}</h5>
                                        <span className="label">{this.props.t('martial_art')}</span>
                                        <span className="value">{fighter.martial_art.toUpperCase()}</span>
                                        <span className="label">{this.props.t('firstname')}</span>
                                        <span className="value">{fighter.firstname}</span>
                                        <span className="label">{this.props.t('lastname')}</span>
                                        <span className="value">{fighter.lastname}</span>
                                        <span className="label">{this.props.t('nickname')}</span>
                                        <span className="value">{fighter.nickname.toLowerCase()}</span>
                                        <h5>
                                            {this.props.t('level')} {fighter.fighter_level} <span className="ml-10 ft-14">{fighter.wins || 0}W - {fighter.losses || 0}L</span>
                                            {fighter.wins && (fighter.losses === 0) && (fighter.wins > 0) ? <span className="undefeated">{this.props.t('undefeated')}</span> : ""}
                                        </h5>
                                        <div className="lvl_holder">
                                            <div className="lvl" style={{ width: `${100 * (fighter.fighter_level * 10 + 120 - fighter.next_level) / (fighter.fighter_level * 10 + 120)}%` }}></div>
                                        </div>
                                        <span className="next_lvl">{this.props.t('next_level')}  {fighter.next_level} points</span>
                                    </div>
                                    <div className="fighter_name" style={{ fontSize: `${650 /fighter.nickname.length + (fighter.nickname.length - 6) * .38}px`, color: fighter.visual.text_color }}>
                                        {fighter.nickname.toLowerCase()}
                                    </div>
                                    <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.png`} alt={fighter.nickname.toLowerCase()} className="visual" />
                                    <div className="weightclass">
                                        {fighter.visual.weightclass}
                                    </div>
                                </div>)}
                        </div>
                    </React.Fragment>
                }
                {!this.state.processing && this.state.switch === "followers" &&
                    <React.Fragment>
                        <h1 className="huge">{this.props.t('followers').toUpperCase()}</h1>
                        {this.state.followers.length === 0 ?
                            <h4>{this.props.t('no_follower')}</h4>
                            :
                            <h4>{this.props.t('browse_followers')}</h4>
                        }
                        <div className="follower">
                            {this.state.followers.map(follower =>
                                <div className="profile pointer" key={follower.follower.id} onClick={() => window.location.href = `/profile/${follower.follower.username}`}>
                                    {follower.follower.avatar ?
                                        <div className="avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${follower.follower.avatar}')` }}></div>
                                        :
                                        <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${follower.follower.username}')` }}></div>
                                    }
                                    <div className="info_profile">
                                        <big>{follower.follower.username}</big>
                                    </div>
                                    <div className="view">
                                        {this.props.t('view')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>}
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(AdminPublicProfile));