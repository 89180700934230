import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { useParams } from 'react-router-dom';
import { OPTIONS, QWRTX_MMA_GOAT_EVENTS, QWRTX_MMA_GOAT_FIGHTERS, QWRTX_MMA_GOAT_PLAYERS } from "../../constants";
import events_services from "../../services/events_services";
import { ProgressBar } from "react-loader-spinner";
import token_services from "../../services/token_services";
import { isMobile } from "react-device-detect";
import MobileHeader from "../../components/Menu/mobile";
import public_services from "../../services/public_services";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

// Media
import GOATCOIN from "../../assets/img/goat_coin.png"
import WHITEGOAT from "../../assets/img/white_goat.png";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class PreviewEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fights: [],
            event: null,
            event_id: null,
            display: "fights",
            users: [],
        }
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const profile = await token_services.getUser()
        if (profile) window.location.href = `/event/${id}`
        const data = await events_services.getEvent(id)
        this.setState({ event: data.event, event_id: id, fights: data.fights })
        if (new Date(data.event.event_date) < new Date()) {
            const users = await public_services.getEventRankedUsers(id)
            this.setState({ users })
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active="events" />
                {!this.state.event ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ display: 'block', margin: '150px auto' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <img className={isMobile ? "event_visual mt-50" : "event_visual"} src={`https://${QWRTX_MMA_GOAT_EVENTS}.s3.eu-west-3.amazonaws.com/${this.state.event.banner}`} />
                        <h4>
                            {this.state.event.place}
                            <br />
                            <span className="capitalize">{new Date(this.state.event.event_date).toLocaleDateString(undefined, OPTIONS)}</span>, {new Date(this.state.event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </h4>
                        <p className="text-center"><strong>{this.props.t('no_account')}</strong></p>
                        <div className="aura mb-50">
                            <div className="launch" onClick={() => window.location.href = "/start/1"}>
                                <img className="sha" src={WHITEGOAT} alt="Shadow fighter" /> {this.props.t('start_now')}
                            </div>
                        </div>
                        {new Date(this.state.event.event_date) < new Date() && this.state.display === "fights" &&
                            <div className="max_holder">
                                <h4 className="mt-50 mb-0">{this.props.t('browse_best_players')} {this.state.event.event_name}</h4>
                                <div className="edit" onClick={() => this.setState({ display: "ranking" })}>
                                    {this.props.t('event_ranking')}
                                </div>
                            </div>
                        }
                        {this.state.display === "ranking" &&
                            <div className="max_holder">
                                <h4 className="mt-50 mb-0">{this.props.t('browse_fight_card')}  {this.state.event.event_name}</h4>
                                <div className="edit" onClick={() => this.setState({ display: "fights" })}>
                                    {this.props.t('event_fights')}
                                </div>
                            </div>
                        }
                        {this.state.display === "fights" && <div className="prono_holder">
                            {this.state.fights.map(fight =>
                                <div className="prono h-auto reduced-top text-center" key={fight.id}>
                                    <span className="label grey mt-30 mb-5 text-left ml-20">
                                        {fight.category} {fight.championship && "CHAMPIONSHIP"} - {fight.nb_rounds} rounds
                                    </span>
                                    <div className="left_part h-auto text-left">
                                        <h3 className="mb-0 text-center">{fight.fighter_one_ranking && <small> #{fight.fighter_one_ranking}</small>} {fight.fighter_one_firstname} {fight.fighter_one_lastname} <div className={`inline-flag ${fight.fighter_one_nationality}`}></div></h3>
                                        <img
                                            src={`https://flagcdn.com/w320/${fight.fighter_one_nationality}.png`}
                                            style={{width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px'}}
                                            alt={fight.fighter_one_nationality}/>
                                        {fight.fighter_one_avatar &&
                                            <img className="align-left" src={fight.fighter_one_avatar.includes("https") ?
                                                fight.fighter_one_avatar
                                                : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_one_avatar}`} />
                                        }
                                        <span className="data mt-20">
                                            <div className="pill back-coming"><strong>{fight.fighter_one_wins}W</strong></div>
                                            <div className="pill back-danger">{fight.fighter_one_losses}L</div>
                                            {fight.fighter_one_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                            {fight.winner === 'one' && <span className="winner_tag">W. <small>by {fight.victory}</small></span>} {fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}</span>
                                        <span className="data"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_one_age} {this.props.t('years_old')}</span>
                                        <span className="data responsive-text-left">{fight.fighter_one_martial_arts.toUpperCase()}</span>
                                        <div className="left_coins">+{fight.fighter_one_odd} <img src={GOATCOIN} alt="GOAT coin" /></div>
                                        <div className={(new Date(this.state.event.event_date) > new Date())? "left_pts" : "left_pts disabled"} onClick={() => window.location.href = "/login"}>
                                            <big>{fight.fighter_one_odd}</big> <small>PTS</small>
                                            <br />
                                            <span className="ft-11">PICK & GET</span>
                                        </div>
                                        {!fight.winner && (new Date(this.state.event.event_date) > new Date()) && <div className="click text-left pl-0">{this.props.t('click_predict')} <FaArrowRight /></div>}
                                    </div>
                                    <div className={fight.fighter_one_avatar ? "vs" : "vs no_profile"}>vs</div>
                                    <div className="right_part h-auto">
                                        <h3 className="mb-0 text-center">{fight.fighter_two_ranking && <small> #{fight.fighter_two_ranking}</small>} {fight.fighter_two_firstname} {fight.fighter_two_lastname} <div className={`inline-flag ${fight.fighter_two_nationality}`}></div></h3>
                                        <img
                                            src={`https://flagcdn.com/w320/${fight.fighter_two_nationality}.png`}
                                            style={{width: '90%', height: "210px", margin: '10px auto', display: 'block', borderRadius: '20px'}}
                                            alt={fight.fighter_two_nationality}/>
                                        {fight.fighter_two_avatar &&
                                            <img className="align-right" src={fight.fighter_two_avatar.includes("https") ?
                                                fight.fighter_two_avatar
                                                : `https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/IRL/${fight.fighter_two_avatar}`} />
                                        }
                                        <span className="data responsive-text-right mt-20">
                                            {fight.fighter_two_losses === 0 && <span className="undefeated mt-0">{this.props.t('undefeated')}</span>}
                                            <div className="pill back-coming"><strong>{fight.fighter_two_wins}W</strong></div>
                                            <div className="pill back-danger">{fight.fighter_two_losses}L</div>
                                            {fight.winner === 'two' && <span className="winner_tag">W. <small>by {fight.victory}</small></span>} {fight.winner === '-' && <span className="winner_tag white back-black">DRAW</span>}
                                        </span>
                                        <span className="data responsive-text-right"><span className="capitalize">{this.props.t(fight.fighters)}</span>, {fight.fighter_two_age} {this.props.t('years_old')}</span>
                                        <span className="data responsive-text-right">{fight.fighter_two_martial_arts.toUpperCase()}</span>
                                        <div className={isMobile ? "left_coins" : "right_coins"}>+{fight.fighter_two_odd} <img src={GOATCOIN} alt="GOAT coin" /></div>
                                        <div className={(new Date(this.state.event.event_date) > new Date())? "right_pts" : "right_pts disabled"} onClick={() => window.location.href = "/login"}>
                                            <big>{fight.fighter_two_odd}</big> <small>PTS</small>
                                            <br />
                                            <span className="ft-11">PICK & GET</span>
                                        </div>
                                        {!fight.winner && (new Date(this.state.event.event_date) > new Date()) &&
                                            <React.Fragment>
                                                {!isMobile ?
                                                    <div className="click_right pr-0"><FaArrowLeft /> {this.props.t('click_predict')}</div>
                                                    :
                                                    <div className="click text-left pl-0">{this.props.t('click_predict')} <FaArrowRight /></div>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                    {!this.state.selected && !fight.winner && (new Date(this.state.event.event_date) > new Date()) && <div className="click text-right"><strong className="pointer" onClick={() => window.location.href = "/login"}>{this.props.t('sign_in')}</strong> {this.props.t('or')} <strong className="pointer" onClick={() => window.location.href = "/start/1"}>{this.props.t('start_now')}</strong> {this.props.t('set_predictions')}</div>}
                                    {!fight.winner && (new Date(this.state.event.event_date) < new Date()) && <div className="click text-right">{this.props.t('event_in_progress')} <strong>{this.props.t('predictions_closed')}</strong></div>}
                                </div>
                            )}
                        </div>}
                        {this.state.display === "ranking" &&
                            <div className="max_holder">
                                <div className="participants">
                                    {this.state.users.map((manager, index) =>
                                        <div className="profile pointer simple_border_mark text-left" key={manager.id} onClick={() => window.location.href = `/profile/${manager.username}`}>
                                            <div className={index === 0 ? "nb_general nb_one resized" : index === 1 ? "nb_general nb_two resized" : index === 2 ? "nb_general nb_three resized" : "nb_general resized"}>
                                                #{index + 1}
                                                <span className="minimized"><strong>{manager.points} </strong>points</span>
                                            </div>
                                            {manager.avatar ?
                                                <div className="avatar" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_PLAYERS}.s3.eu-west-3.amazonaws.com/${manager.avatar}')` }}></div>
                                                :
                                                <div className="avatar" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${manager.username}')` }}></div>
                                            }
                                            <div className="info_profile">
                                                <big>{manager.username}</big>
                                                <br />
                                                <span className="grey min-ft-14">{this.props.t('managing')} {manager.fighters} {this.props.t('fighters')}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </React.Fragment>}
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(PreviewEvent));