import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import { useParams } from 'react-router-dom';
import MobileHeader from "../../components/Menu/mobile";

//Media
import { ProgressBar } from "react-loader-spinner";
import public_services from "../../services/public_services";
import { NotificationContainer, NotificationManager } from "react-notifications";
import league_services from "../../services/league_services";
import token_services from "../../services/token_services";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class JoinLeague extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
            league: {},
            processing: true,
            copied: null,
        }
    }

    componentDidMount = async () => {
        const { innerWidth: width } = window
        this.setState({ width })
        const { code } = this.props.match.params
        let league = await public_services.leagueCode(code)
        if (league) {
            const profile = await token_services.getUser()
            if (profile) {
                this.setState({ profile: profile.account })
                league.participation = await league_services.participation(league.id)
            }
            this.setState({ league, processing: false })
        }
        else
            NotificationManager.error(this.props.t('unreferenced_code'))
    }

    joinLeague = async () => {
        const status = await league_services.joinLeague(this.state.league.id)
        if (status)
            window.location.href = "/leagues"
        else
            NotificationManager.error(this.props.t('error_join'))
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader/>
                <Menu active={"leagues"} />
                <h1 className="huge starter">{this.props.t('join_league')}</h1>
                <h4>{this.props.t('join_private_league')}</h4>
                {this.state.processing ?
                    <ProgressBar
                        height="80"
                        width="80"
                        ariaLabel="progress-bar-loading"
                        wrapperStyle={{ margin: '150px auto', display: 'block' }}
                        wrapperClass="progress-bar-wrapper"
                        borderColor='#000'
                        barColor='#000'
                    />
                    :
                    <React.Fragment>
                        <div className="league" key={this.state.league.id}>
                            <div className="teaser" style={{ backgroundImage: `url('https://api.dicebear.com/7.x/rings/svg?seed=${this.state.league.league_name}` }}></div>
                            <h2 className="mt-0"><strong className="audiowide clickable">{this.state.league.league_name.toUpperCase()}</strong>
                                <br />
                                <small>{this.props.t('created_by')} {this.state.league.creator.username}</small></h2>
                            {this.state.width > 940 ?
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('active_since')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('participants')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('fighters')}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="value mt-10 mr-20">{new Date(this.state.league.createdAt).toLocaleDateString()}</span>
                                            </td>
                                            <td>
                                                <span className="profile_action">{this.state.league.participants.length} / <small>{this.state.league.league_limit > 0 ? this.state.league.league_limit : this.props.t('unlimited')}</small></span>
                                            </td>
                                            <td>
                                                <span className="profile_action">{this.state.league.fighters.length} {this.props.t('fighters')}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                :
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <span className="label mt-0 text-left mr-20">{this.props.t('active_since')}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <span className="value mt-10 mr-20">{new Date(this.state.league.createdAt).toLocaleDateString()}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="label mt-20 text-left mr-20">{this.props.t('participants')}</span>
                                            </td>
                                            <td>
                                                <span className="label mt-20 text-left mr-20">{this.props.t('fighters')}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span className="profile_action">{this.state.league.participants.length} / <small>{this.state.league.league_limit > 0 ? this.state.league.league_limit : this.props.t('unlimited')}</small></span>
                                            </td>
                                            <td>
                                                <span className="profile_action">{this.state.league.fighters.length} {this.props.t('fighters')}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            }
                        </div>
                        {this.state.profile ?
                            this.state.league.participation ?
                                <h5 className="text-center">{this.props.t('already_league')}</h5>
                                :
                                <div className="edit mt-40" onClick={this.joinLeague}>{this.props.t('join_the_league')}</div>
                            :
                            <h5 className="text-center"><strong className="pointer" onClick={() => window.location.href = "/login"}>{this.props.t('sign_in')}</strong> {this.props.t('or')} <strong className="pointer" onClick={() => window.location.href = "/start/1"}>{this.props.t('start_now')}</strong> {this.props.t('to_join_the_league')}</h5>
                        }
                    </React.Fragment>
                }
                <NotificationContainer />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(withTranslation()(JoinLeague));