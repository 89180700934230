import api from './api';

class CommentsService {
    async addComment(id, content) {
      return await api
        .post(`/comments/news/${id}`, {content})
        .then(async (response) => {
          console.log(response.data)
          return response.data;
        });
    }

    async deactivateComment(id) {
      return await api
        .get(`/comments/deactivate/${id}`)
        .then(async (response) => {
          return response.data;
        });
    }

    async reportComment(id) {
      return await api
        .get(`/comments/report/${id}`)
        .then(async (response) => {
          return response.data;
        });
    }
}

// eslint-disable-next-line
export default new CommentsService();
