import "../../components/flags.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import predictions_services from "../../services/predictions_services"
import { ProgressBar } from "react-loader-spinner"
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants"
import { countries } from "../../components/countries"
import { withTranslation } from "react-i18next"
import { isMobile } from "react-device-detect"

// Media
import GOATCOIN from "../../assets/img/goat_coin.png"
import public_services from "../../services/public_services"

class Fighters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            victory: null,
            processing: false,
            display: true,
            boosters: {},
            selectedBooster: 0,
        }
    }

    newPrediction = async (fighter_id) => {
        try {
            if (!this.state.victory)
                return NotificationManager.warning(this.props.t('info_victory'))
            this.setState({ processing: true })
            const data = {
                fight_id: this.props.fight_id,
                fighter_id,
                contestant: this.props.selected,
                victory: this.state.victory,
                booster: this.state.selectedBooster,
            }
            const resp = await predictions_services.addPrediction(data)
            if (resp.status) {
                window["ReactNativeWebView"] && window["ReactNativeWebView"].postMessage(JSON.stringify({"action": "place_prediction"}))// SEND message in canal 
                NotificationManager.success(this.props.t('prediction_referenced'))
                this.props.loadEvents()
            } else {
                NotificationManager.error(this.props.t('error_prediction'))
            }
            this.setState({ processing: false })
        } catch (e) {
            console.log(e)
            NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.message)
            this.setState({ processing: false })
        }
    }

    componentDidMount = async () => {
        let display = false
        for (let fighter of this.props.fighters) {
            if (this.props.fighter_type === fighter.fighter && !fighter.prediction) display = true
        }
        this.setState({ display })
        let boosters = await public_services.getBoosters()
        this.setState({ boosters })
    }

    render() {
        return (
            this.state.display ?
                <React.Fragment>
                    <div className="win_holder">
                        <h2 className="mt-10 mb-0">✊🏿 {this.props.t('set_victory')}</h2>
                        <span className="win_info">{this.props.t('get_extra')} <strong>+20 points</strong> {this.props.t('if_correct')}</span>
                        <div className="win_selecter">
                            <div className={this.state.victory === "KO/TKO" ? "win_type back-coming black" : "win_type"} onClick={() => this.setState({ victory: "KO/TKO" })}>
                                {this.props.t('ko/tko')}
                            </div>
                            <div className={this.state.victory === "submission" ? "win_type back-coming black" : "win_type"} onClick={() => this.setState({ victory: "submission" })}>
                                {this.props.t('submission')}
                            </div>
                            <div className={this.state.victory === "decision" ? "win_type back-coming black" : "win_type"} onClick={() => this.setState({ victory: "decision" })}>
                                {this.props.t('decision')}
                            </div>
                        </div>
                    </div>
                    <div className="booster_holder">
                        <h2 className="mt-10 mb-0">🚀 {this.props.t('set_booster')}</h2>
                        <span className="win_info">{this.props.t('option_booster')}</span>
                        <div className="win_selecter">
                            {Object.keys(this.state.boosters).map((key, index) =>
                                <div key={key}
                                    className={this.state.selectedBooster === key ?
                                        `booster_type ${index === 0 && "left_b"} ${index === (Object.keys(this.state.boosters).length - 1) && "right_b"} back-coming black`
                                        :
                                        `booster_type ${index === 0 && "left_b"} ${index === (Object.keys(this.state.boosters).length - 1) && "right_b"}`}
                                    onClick={() => this.setState({ selectedBooster: key })}>
                                    {key}% {'>'} <small>{this.state.boosters[key]}</small>
                                    <img src={GOATCOIN} className="gtcoins" alt="GOAT coin" />
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.victory && <span className="win_about mt-40">{this.props.t('select_fighter_pick')}</span>}
                    {this.state.victory && this.props.fighters.map(fighter => this.props.fighter_type === fighter.fighter && !fighter.prediction &&
                        <div className="fighter_card" key={fighter.id}>
                            <div className="fighter_visual" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.jpg')` }}>
                                <div className="nickname" style={{ fontSize: `${650 / fighter.nickname.length + (fighter.nickname.length - 6) * .38}px`, color: fighter.visual.text_color }}>
                                    {fighter.nickname}
                                </div>
                                <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/${fighter.visual.section}/${fighter.fighter}/${fighter.visual.visual}.png`} alt={fighter.nickname} className="mask" />
                            </div>
                            <div className={isMobile ? "fighter_info white" : "fighter_info"}>
                                <span className={isMobile ? "label white" : "label"}>{this.props.t('martial_art')}</span>
                                <span className={isMobile ? "value white" : "value"}>{fighter.martial_art.toUpperCase()}</span>
                                <span className={isMobile ? "label white" : "label"}>{this.props.t('weight_class')}</span>
                                <span className={isMobile ? "value white" : "value"}>{fighter.visual.weightclass.toUpperCase()}</span>
                                <span className={isMobile ? "label white" : "label"}>{this.props.t('nickname')}</span>
                                <span className={isMobile ? "value white" : "value"}>
                                    {fighter.nickname}
                                    <div className={`flag ${fighter.nationality}`} title={countries[fighter.nationality.toUpperCase()]}></div>
                                </span>
                                <h5>{this.props.t('level')} {fighter.fighter_level}</h5>
                                <div className="lvl_holder">
                                    <div className="lvl back-coming"></div>
                                </div>
                                <span className={isMobile ? "next_lvl white" : "next_lvl"}>{this.props.t('next_level')} {fighter.next_level} points</span>
                                <hr />
                                {fighter.visual.weightclass.toLowerCase() === this.props.weightclass.toLowerCase() && <small className={isMobile ? "label white" : "label"}>{this.props.t('booster')} <strong>40%</strong> {this.props.t('weight_class')}</small>}
                                {fighter.nationality === this.props.nationality && <small className={isMobile ? "label white" : "label"}>{this.props.t('booster')} <strong>20%</strong> {this.props.t('nationality').toLowerCase()}</small>}
                                {fighter.martial_art.toLowerCase() === this.props.martial_art.toLowerCase() && <small className={isMobile ? "label white" : "label"}>{this.props.t('booster')} <strong>40%</strong> {this.props.t('martial_class').toLowerCase()}</small>}
                                {!this.state.processing && <div className="edit" onClick={() => this.newPrediction(fighter.id)}>
                                    {this.props.t('select_fighter_to_get')} <strong className="green">{parseInt(this.props.odds +
                                        (fighter.visual.weightclass.toLowerCase() === this.props.weightclass.toLowerCase() ? 0.4 : 0) * this.props.odds +
                                        (fighter.nationality === this.props.nationality ? 0.2 : 0) * this.props.odds +
                                        (fighter.martial_art.toLowerCase() === this.props.martial_art.toLowerCase() ? 0.4 : 0) * this.props.odds
                                    ) + parseInt((this.props.odds +
                                        (fighter.visual.weightclass.toLowerCase() === this.props.weightclass.toLowerCase() ? 0.4 : 0) * this.props.odds +
                                        (fighter.nationality === this.props.nationality ? 0.2 : 0) * this.props.odds +
                                        (fighter.martial_art.toLowerCase() === this.props.martial_art.toLowerCase() ? 0.4 : 0) * this.props.odds
                                    ) * (this.state.selectedBooster / 100))}<small>* + 20**</small></strong>
                                </div>}
                                {this.state.processing &&
                                    <ProgressBar
                                        height="80"
                                        width="80"
                                        ariaLabel="progress-bar-loading"
                                        wrapperStyle={{ margin: '0 20px' }}
                                        wrapperClass="progress-bar-wrapper"
                                        borderColor='#FFF'
                                        barColor='#FFF'
                                    />
                                }
                                <small className={isMobile ? "next_lvl white" : "next_lvl"}>*{this.props.t('earning_if')} {this.props.firstname.substring(0, 1)}. {this.props.lastname} {this.props.t('wins')}</small>
                                {this.state.victory && <small className={isMobile ? "next_lvl white" : "next_lvl"}>**{this.props.t('points_if')} {this.props.firstname.substring(0, 1)}. {this.props.lastname} {this.props.t('wins_by')} {this.state.victory}</small>}
                                {this.state.victory && <small className={isMobile ? "next_lvl white" : "next_lvl"}>{this.props.t('pick_right')} <strong>{this.props.t('same_amount')}</strong> <img src={GOATCOIN} alt="GOAT coin" width={"10px"} /> {this.props.t('fighters_and_boosters')}</small>}
                            </div>
                        </div>
                    )}
                    <NotificationContainer />
                </React.Fragment>
                :
                <p className="text-center mt-0 mb-50"><strong>{this.props.t('get_from_store')} <a href="/store">store</a>.</strong></p>
        );
    }
}

export default withTranslation()(Fighters);