import "../../components/flags.css"
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";

class Legal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <Menu />
                <h1 className="huge starter">{this.props.t('cgu_legal')}</h1>
                <div className="safe_holder">
                    <h4>
                        <strong>{this.props.t('cgu_legal01')}</strong>
                        <br />
                        {this.props.t('cgu_legal02')}
                    </h4>
                    <p>
                        {this.props.t('cgu_legal03')}
                        <br />
                        {this.props.t('cgu_legal04')}
                        <br />
                        {this.props.t('cgu_legal05')}
                        <br />
                        {this.props.t('cgu_legal06')}
                    </p>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Legal);