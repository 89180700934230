import React, { Component } from 'react';
import {
    isIOS
} from "react-device-detect";
import { FaApple, FaTimesCircle } from 'react-icons/fa';

//Media
import LOGO from "../../assets/img/mma_goat_logo_white.png"
import SHARE from "../../assets/img/share.png"
import { withTranslation } from 'react-i18next';

class InstalliOSMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayNotice: false
        }
    }

    setDisplayNotice = (display) => {
        this.setState({ displayNotice: display })
    }

    render() {
        return (
            isIOS && (window.navigator.standalone !== true) &&
            <React.Fragment>
                <div className="install_icon" onClick={() => window.open('https://apps.apple.com/us/app/mma-goat/id6477226190?platform=iphone')}>
                    <FaApple />
                </div>
                {this.state.displayNotice && <div className="intall_ios">
                    <img src={LOGO} className="logo" alt="Logo MMA GOAT" />
                    <div className="title">
                        {this.props.t('ios_01')}
                    </div>
                    <hr />
                    <p className="ft-11 padding">
                        {this.props.t('ios_02')}
                        <br />
                        {this.props.t('ios_03')}
                    </p>
                    <p className="ft-11 padding-safe">
                        <strong>1.</strong> {this.props.t('ios_04')} <img src={SHARE} alt="share" className="share" />
                    </p>
                    <p className="ft-11 padding-safe">
                        <strong>2.</strong> {this.props.t('select')} <strong>{this.props.t('ios_05')}</strong>
                    </p>
                    <div className="close" onClick={() => this.setDisplayNotice(false)}><FaTimesCircle /></div>
                </div>}
            </React.Fragment>
        )
    }
}

export default withTranslation()(InstalliOSMobile);