import api from './api';
import TokenService from './token_services';

class AuthService {
  async signin(email, password, recaptcha) {
    return await api
      .post('/auth/signin', { email, password, recaptcha })
      .then(async (response) => {
        if (response.data.access_token) {
          await TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  async subscribe(email, recaptcha) {
    return await api
      .post('/auth/subscribe', { email, recaptcha })
      .then(async (response) => {
        return response.data;
      });
  }

  async logout() {
    await TokenService.removeUser();
  }

  async getCurrentUser() {
    return await TokenService.getUser();
  }

  async register(account) {
    return await api
      .post('/auth/create', account)
      .then(async (response) => {
        if (response.data.access_token) {
          await TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  async get_reset_token(email, recaptcha) {
    return await api
      .post('/auth/send/reset', {email, recaptcha})
      .then(async (response) => {
        return response.data;
      });
  }

  async reset_password(email, reset_token, password) {
    return await api
      .post('/auth/reset/password', {email, reset_token, password})
      .then(async (response) => {
        return response.data;
      });
  }
}

// eslint-disable-next-line
export default new AuthService();
