import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from '../../components/Menu';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { uniqueNamesGenerator, names, adjectives, animals } from 'unique-names-generator';
import MobileHeader from "../../components/Menu/mobile";

// Media
import Footer from "../../components/Footer";
import { countries } from "../../components/countries";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import fighter_services from "../../services/fighter_services";
import { FaSync } from "react-icons/fa";
import axios from "axios";

const Filter = require('bad-words');

class EditManFighter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visual: null,
            martialArt: null,
            firstname: "",
            lastname: "",
            nickname: "",
            nationality: null,
        }
    }

    componentDidMount = async () => {
        if (window.localStorage.getItem('mma_goat_fighters')) {
            const fighters = JSON.parse(window.localStorage.getItem('mma_goat_fighters'))
            this.setState({ visual: fighters.male.visual })
            if (fighters.male.info) {
                this.setState({
                    martialArt: fighters.male.info.martial_art,
                    firstname: fighters.male.info.firstname,
                    lastname: fighters.male.info.lastname,
                    nickname: fighters.male.info.nickname,
                    nationality: fighters.male.info.nationality
                })
            }
        } else
            window.location.href = "/start/1"
    }

    generateFirstname = async () => {
        try {
            const resp = await axios.get("https://randomuser.me/api/?gender=male&nat=au,br,ca,ch,de,dk,es,fi,fr,gb,ie,in,mx,nl,no,nz,rs,ua,us")
            this.setState({ firstname: resp.data.results[0].name.first })
        } catch (e) {
            console.log(e)
            this.setState({
                firstname: uniqueNamesGenerator({ dictionaries: [names] }),
            })
        }
    }

    generateLastname = async() => {
        try {
            const resp = await axios.get("https://randomuser.me/api/?gender=male&nat=au,br,ca,ch,de,dk,es,fi,fr,gb,ie,in,mx,nl,no,nz,rs,ua,us")
            this.setState({ lastname: resp.data.results[0].name.last })
        } catch (e) {
            console.log(e)
            this.setState({
                lastname: uniqueNamesGenerator({ dictionaries: [names] }),
            })
        }
    }

    generateNickname = () => {
        this.setState({
            nickname: uniqueNamesGenerator({ dictionaries: [adjectives, animals] }).split("_").join(" "),
        })
    }

    saveFighter = async () => {
        if (!this.state.nickname || this.state.nickname.length < 4 || this.state.nickname.length > 25 ||
            !this.state.firstname || this.state.firstname.length < 2 || this.state.firstname.length > 25 ||
            !this.state.lastname || this.state.lastname.length < 2 || this.state.lastname.length > 25 ||
            !this.state.nationality || !this.state.martialArt)
            NotificationManager.warning(this.props.t('request_fill_all'))
        else {
            const status = await fighter_services.checkNickname(this.state.nickname)
            if (status) {
                NotificationManager.warning(`${this.state.nickname} ${this.props.t('already_exists')}`)
            } else {
                let fighters = JSON.parse(window.localStorage.getItem('mma_goat_fighters'))
                const filter = new Filter({ placeHolder: '~~' });
                fighters.male.info = {
                    martial_art: this.state.martialArt,
                    firstname: filter.clean(this.state.firstname).replace(/~~/g, ""),
                    lastname: filter.clean(this.state.lastname).replace(/~~/g, ""),
                    nickname: filter.clean(this.state.nickname).replace(/~~/g, ""),
                    nationality: this.state.nationality
                }
                window.localStorage.setItem('mma_goat_fighters', JSON.stringify(fighters))
                fighters.female ? window.location.href = "/start/5" : window.location.href = "/start/3"
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <MobileHeader />
                <Menu active={"start"} />
                <h1 className="starter huge">{this.props.t('edit_male_fighter')}</h1>
                <h4>
                    {this.props.t('set_fighter_profile')}
                    <br />
                    {this.props.t('his_unique_profile')}
                </h4>
                <div className="max_holder text-center">
                    <div className="editor text-left left_shadow">
                        <span className="label">
                            {this.props.t('martial_art')}
                        </span>
                        <select className="text_editor" onChange={(e) => this.setState({ martialArt: e.target.value })}>
                            {this.state.martialArt ?
                                <option value={this.state.martialArt} selected>{this.state.martialArt}</option>
                                :
                                <option selected disabled>{this.props.t('select_martial_art')}</option>}
                            <option value="Judo">Judo</option>
                            <option value="Karate">Karate</option>
                            <option value="Boxer">Boxer</option>
                            <option value="Kickboxer">Kickboxer</option>
                            <option value="Tae kwon do">Tae kwon do</option>
                            <option value="Jiu jitsu">Jiu jitsu</option>
                            <option value="Muay Thai">Muay Thai</option>
                            <option value="Wrestler">Wrestler</option>
                        </select>
                        <span className="tips"> x1.4 {this.props.t('bonus_matching_style')}</span>
                        <span className="label">
                            {this.props.t('firstname')}
                        </span>
                        <input type="text" className="text_editor" placeholder={this.props.t('placeholder_firstname')}
                            onChange={(e) => this.setState({ firstname: e.target.value })} value={this.state.firstname} />
                        <div className="generator" onClick={this.generateFirstname}><FaSync /></div>
                        <div className="sub_tips">{this.props.t('random_firstname')}</div>
                        <span className="label">
                            {this.props.t('lastname')}
                        </span>
                        <input type="text" className="text_editor" placeholder={this.props.t('placeholder_lastname')}
                            onChange={(e) => this.setState({ lastname: e.target.value })} value={this.state.lastname} />
                        <div className="generator" onClick={this.generateLastname}><FaSync /></div>
                        <div className="sub_tips">{this.props.t('random_lastname')}</div>
                        <span className="label">
                            {this.props.t('nickname')}
                        </span>
                        <input type="text" className="text_editor" placeholder={this.props.t('placeholder_nickname')}
                            onChange={(e) => e.target.value.length < 39 ? this.setState({ nickname: e.target.value }) : NotificationManager.warning('Characters limit')} value={this.state.nickname.toLowerCase()} />
                        <div className="generator" onClick={this.generateNickname}><FaSync /></div>
                        <div className="sub_tips">{this.props.t('random_nickname')}</div>
                        <span className="label">
                            {this.props.t('nationality')}
                        </span>
                        <select className="text_editor" onChange={(e) => this.setState({ nationality: e.target.value })}>
                            {this.state.nationality ?
                                <option value={this.state.nationality} selected>{countries[this.state.nationality.toUpperCase()]}</option>
                                :
                                <option selected disabled>{this.props.t('select_nationality')}</option>}
                            {Object.keys(countries).map(key =>
                                <option key={key} value={key.toLocaleLowerCase()}>{countries[key]}</option>
                            )}
                        </select>
                        {this.state.nationality && <div className={`middle-flag ${this.state.nationality}`}></div>}
                        <span className="tips"> x1.2 {this.props.t('bonus_matching_nationality')}</span>
                        <div className="edit" onClick={this.saveFighter}>
                            {this.props.t('validate_profile')}
                        </div>
                    </div>
                    {this.state.visual && <div className="card-editor" style={{ backgroundImage: `url('https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/male/${this.state.visual.visual}.jpg')` }}>
                        {this.state.nickname && this.state.nickname.length > 3 && <div className="fighter_name" style={{ fontSize: `${680 / this.state.nickname.length + (this.state.nickname.length - 6) * .58}px`, color: this.state.visual.text_color }}>
                            {this.state.nickname}
                        </div>}
                        <img src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/male/${this.state.visual.visual}.png`} alt={this.state.nickname} className="visual" />
                    </div>}
                </div>
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(EditManFighter);