import "../../components/flags.css";
import 'react-notifications/lib/notifications.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from "i18next";
import { Buffer } from 'buffer';
import validator from 'validator';
import Menu from '../../components/Menu';
import Footer from "../../components/Footer";
import { QWRTX_MMA_GOAT_FIGHTERS } from "../../constants";
import auth_services from "../../services/auth_services";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ProgressBar } from 'react-loader-spinner';
import MobileHeader from "../../components/Menu/mobile";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            male: null,
            female: null,
            checked: false,
            avatar: null,
            email: null,
            username: null,
            password: null,
            success: false,
            processing: false,
            file_data: null,
            file_name: null,
            file_extension: null,
            activation: null,
            lg: i18next.language,
        }
        this.hiddenFileInput = React.createRef();
    }

    handleFileUpload = () => {
        this.hiddenFileInput.current.click()
    }

    onFileFileHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            NotificationManager.error(this.props.t('invalid_file'), 'Error')
            this.setState({ processing: false })
        } else if (event.target.files[0].size > 12000000) {
            NotificationManager.error(this.props.t('file_exceeds'), 'Error')
            this.setState({ processing: false })
        } else {
            this.setState({ file_extension: extension, file_name: event.target.files[0].name })
            reader.onloadend = () => this.processFile(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processFile = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await this.setState({ processing: false, file_data: base64data })
    }

    componentDidMount = async () => {
        if (window.localStorage.getItem('mma_goat_fighters')) {
            const fighters = JSON.parse(window.localStorage.getItem('mma_goat_fighters'))
            this.setState({ male: fighters.male, female: fighters.female })
        } else
            window.location.href = "/start/1"
    }

    register = async () => {
        if (!this.state.password || this.state.password.length < 4 || this.state.password.length > 25 ||
            !validator.isEmail(this.state.email) ||
            !this.state.username || this.state.username.length < 3 || this.state.username.length > 25)
            NotificationManager.warning(this.props.t('request_fill_all'))
        else if (!this.state.checked)
            NotificationManager.warning(this.props.t('agree_terms'))
        else {
            try {
                this.setState({ processing: true })
                const avatar = this.state.file_data ? { file_data: this.state.file_data, file_extension: this.state.file_extension, file_name: this.state.file_name } : null
                const fighters = JSON.parse(window.localStorage.getItem('mma_goat_fighters'))
                const resp = await auth_services.register({
                    username: this.state.username,
                    email: this.state.email,
                    password: this.state.password,
                    activation: this.state.activation,
                    avatar,
                    male_visual: fighters.male.visual.id,
                    male_martial_art: fighters.male.info.martial_art,
                    male_firstname: fighters.male.info.firstname,
                    male_lastname: fighters.male.info.lastname,
                    male_nickname: fighters.male.info.nickname,
                    male_nationality: fighters.male.info.nationality,
                    female_visual: fighters.female.visual.id,
                    female_martial_art: fighters.female.info.martial_art,
                    female_firstname: fighters.female.info.firstname,
                    female_lastname: fighters.female.info.lastname,
                    female_nickname: fighters.female.info.nickname,
                    female_nationality: fighters.female.info.nationality,
                    lg: this.state.lg,
                    ref_k: window.localStorage.getItem('ref_k')
                })
                if (resp.status) {
                    window.localStorage.removeItem('ref_k')
                    window.localStorage.removeItem('mma_goat_fighters')
                    window.location.href = "/fighters"
                } else {
                    NotificationManager.error(this.props.t('request_fill_all'))
                }
                this.setState({ processing: false })
            } catch (e) {
                NotificationManager.error(e.response && e.response.data && e.response.data.errors ? `${this.props.t("missing_field")} ${e.response.data.errors[0].path}` : e.response.data ? this.props.t(e.response.data) : this.props.t(e.message))
                this.setState({ processing: false })
            }
        }
    }

    scrollTo = (anchor) => {
        let element = document.getElementById(anchor);
        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    render() {
        return (
            <React.Fragment>
                <Menu active={"start"} />
                <MobileHeader/>
                <h1 className="starter huge">{this.props.t('create_your_account')}</h1>
                <h4>
                    {this.props.t('create_and_validate')}
                </h4>
                <div className="max_holder text-center">
                    {this.state.male && <div className="editor fit-content h-auto text-left full-border-radius mt-20">
                        <span className="label">
                            {this.props.t('avatar')}
                        </span>
                        <input className="d-none" type="file" ref={this.hiddenFileInput}
                            accept="image/x-png,image/jpeg"
                            onChange={(e) => this.onFileFileHandler(e)} />
                        <div className={this.state.file_data ? "avatar" : "avatar border_mark"} onClick={this.handleFileUpload}
                            style={{
                                backgroundImage: this.state.file_data ? `url('data:image/${this.state.file_extension};base64,${this.state.file_data}')` :
                                    `url('https://api.dicebear.com/7.x/lorelei-neutral/svg?seed=${this.state.username}')`
                            }}></div>
                        <span className="tips">{this.props.t('press_to_change')}</span>
                        <span className="label">
                            {this.props.t('username')}
                        </span>
                        <input type="text" className="text_editor back-white" placeholder={this.props.t('placeholder_username')} onChange={(e) => this.setState({ username: e.target.value })} />
                        <span className="label">
                            {this.props.t('email')}
                        </span>
                        <input type="email" className="text_editor back-white" placeholder={this.props.t('placeholder_email')} onChange={(e) => this.setState({ email: e.target.value })} />
                        <span className="label">
                            {this.props.t('password')}
                        </span>
                        <input type="password" className="text_editor back-white" placeholder={this.props.t('placeholder_password')} onChange={(e) => this.setState({ password: e.target.value })} />
                        <span className="label"> <input type="checkbox" onClick={() => this.setState({ checked: !this.state.checked })} /> {this.props.t('agree')} <strong className="pointer" onClick={() => window.open("/terms")}>{this.props.t('cgu')}</strong></span>

                        {!this.state.processing && !this.state.success && <div className="edit" onClick={this.register}>
                            {this.props.t('create_account')}
                        </div>}
                        {this.state.success && <div className="edit" onClick={() => window.location.href = "/fighters"}>
                            {this.props.t('goto_my_fighters')}
                        </div>}
                        {this.state.processing &&
                            <ProgressBar
                                height="80"
                                width="80"
                                ariaLabel="progress-bar-loading"
                                wrapperStyle={{ margin: '0 20px' }}
                                wrapperClass="progress-bar-wrapper"
                                borderColor='#000'
                                barColor='#000'
                            />
                        }
                        <img className="left-card" alt="male fighter" src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/male/${this.state.male.visual.visual}.jpg`} />
                        <img className="right-card" alt="female fighter" src={`https://${QWRTX_MMA_GOAT_FIGHTERS}.s3.eu-west-3.amazonaws.com/starter/female/${this.state.female.visual.visual}.jpg`} />
                    </div>}
                </div>
                <Footer />
                <NotificationContainer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Register);